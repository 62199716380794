import { useEffect, useState } from 'react';
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  EditButton,
  DeleteButton,
  BulkDeleteWithUndoButton,
  TextInput,
  SimpleForm,
  Edit,
  useNotify,
  Create,
} from 'react-admin';
import { dataProvider } from '../../dataProvider';
import { getIsDisabledByRole } from '../../utils/get-disabled-by-role';

const MAX_CONTACTS = 1;

export const PartnershipContactsList = (props) => {
  const [hasOne, setHasOne] = useState(false);

  useEffect(() => {
    // Убираем кнопку создать, если есть запись в бд
    const fetchData = async () => {
      const { total } = await dataProvider.getList('company-contacts', {
        pagination: { page: 1, perPage: 1 },
        sort: { field: 'id', order: 'ASC' },
        filter: {},
      });

      if (total && total >= MAX_CONTACTS) {
        setHasOne(true);
      }
    };

    fetchData();
  }, []);

  return (
    <List {...props} hasCreate={!hasOne}>
      <Datagrid bulkActionButtons={<BulkDeleteWithUndoButton {...props} disabled={getIsDisabledByRole()} />}>
        <TextField source="telegram" label="Telegram" />
        <EmailField source="email" label="E-mail" />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

export const PartnershipContactCreate = (props) => {
  const notify = useNotify();

  const onSuccess = () => {
    notify('Contact Created');
  };

  return (
    <Create {...props} mutationMode="pessimistic" onSuccess={onSuccess} redirect={(basePath) => basePath}>
      <SimpleForm>
        <TextInput source="telegram" label="Telegram" placeholder="Without @" />
        <TextInput source="email" label="E-mail" type="email" />
      </SimpleForm>
    </Create>
  );
};

export const PartnershipContactEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="telegram" label="Telegram" />
      <TextInput source="email" label="E-mail" type="email" />
    </SimpleForm>
  </Edit>
);
