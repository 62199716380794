import {
  AddAlert,
  BusinessCenter,
  VerifiedUser,
  FormatListBulleted,
  Assistant,
  Email,
  Cloud,
} from '@mui/icons-material';
import styled from 'styled-components';
import { Periods, PeriodTypes, ProjectStats } from './types';

const colors = ['#ED495A', '#494CED', '#49EDB4', '#EDEA49', '#ED9849', '#ED5F49', '#9649ED'];

export const titles = {
  [Periods.H24]: '24 hours',
  [Periods.D7]: '7 days',
  [Periods.D30]: '30 days',
  [Periods.M3]: '3 months',
  [Periods.M6]: '6 months',
  [Periods.TOTAL]: 'Total',
};

const CardsContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  max-width: 1130px;
`;

const InfoCard = styled.li`
  display: flex;
  gap: 20px;
  padding: 15px;
  min-width: 220px;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: rgb(241 242 250) 0px 5px 10px;
`;

const IconContainer = styled.div`
  display: flex;
  position: relative;
  width: 48px;
  height: 48px;
  color: ${(p) => p.color};

  > svg {
    width: 70%;
    height: auto;
    margin: 0 auto;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background-color: currentColor;
    opacity: 0.15;
  }
`;

const Title = styled.h3`
  font-size: 12px;
  font-weight: normal;
  color: #777888;
  text-transform: uppercase;
  margin: 0 0 10px;
`;

const TotalValue = styled.p`
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  margin: 0;
`;

const SecondaryText = styled.p`
  font-size: 11px;
  margin: 10px 0 0;
  color: #778877;
`;

const CardIcon = ({ title }: { title: string }) => {
  const verified = /verified/gi;
  const watchlist = /watchlist/gi;
  const alerts = /alerts/gi;
  const portfolio = /portfolio/gi;
  const api = /api/gi;
  const email = /email/gi;

  if (verified.test(title)) {
    return <VerifiedUser />;
  }

  if (watchlist.test(title)) {
    return <FormatListBulleted />;
  }

  if (api.test(title)) {
    return <Cloud />;
  }

  if (alerts.test(title)) {
    if (email.test(title)) {
      return <Email />;
    }

    return <AddAlert />;
  }

  if (portfolio.test(title)) {
    return <BusinessCenter />;
  }

  return <Assistant />;
};

const getChangeMessage = (changeValue: number, period: Periods) => {
  let changeMessage = `${changeValue} in ${titles[period]}`;

  if (!changeValue) {
    return null;
  }

  if (changeValue > 0) {
    changeMessage = `+ ${changeMessage}`;
  }

  return changeMessage;
};

const getChangeInfo = (periods: PeriodTypes) => {
  return Object.entries(periods).map(([periodType, changeValue], index) => {
    if (periodType === Periods.TOTAL) {
      return null;
    }

    const message = getChangeMessage(changeValue, periodType as Periods);

    if (!message) {
      return null;
    }

    return <SecondaryText key={periodType + index}>{message}</SecondaryText>;
  });
};

export const DashboardCards = ({ data }: { data: ProjectStats }) => (
  <CardsContainer>
    {Object.entries(data).map(([title, periods], index) => (
      <InfoCard key={title + index}>
        <IconContainer color={colors[index % colors.length]}>
          <CardIcon title={title} />
        </IconContainer>

        <div>
          <Title>{title}</Title>
          <TotalValue>{periods.TOTAL}</TotalValue>
          {getChangeInfo(periods)}
        </div>
      </InfoCard>
    ))}
  </CardsContainer>
);
