import { ArrayInputIterator } from '../../inputs/array-input-iterator';
import { AutocompleteInput, BooleanInput, DateInput, ReferenceInput, SelectInput, TextInput } from 'ra-ui-materialui';
import { Button, Typography } from '@mui/material';
import { CrowdsalesArrayInput } from './styled';
import { FC } from 'react';
import { FormDataConsumer, required } from 'react-admin';
import { NumberInput } from '../../inputs/number-input';
import { SortingDirection } from '../../variables';
import { useFormContext } from 'react-hook-form';
import { CrowdsaleType } from '../../variables/crowdsale-types';

const CalculateButton = (props) => {
  const { getValues, setValue } = useFormContext();
  const { raise, price, tokensForSale } = getValues(props.source);
  const calculateIsDisabled = [raise, price, tokensForSale].filter(Boolean).length !== 2;

  const onCalculate = () => {
    if (calculateIsDisabled) {
      return;
    }

    let field = '';
    let value = 0;

    if (!raise) {
      field = 'raise';
      value = tokensForSale * price;
    }

    if (!price) {
      field = 'price';
      value = raise / tokensForSale;
    }

    if (!tokensForSale) {
      field = 'tokensForSale';
      value = Math.round(raise / price);
    }

    setValue(`${props.source}.${field}`, value, { shouldDirty: true });
  };

  return (
    <div style={{ marginTop: 8 }}>
      <Button variant="outlined" onClick={() => onCalculate()} disabled={calculateIsDisabled}>
        Calculate
      </Button>
      <Typography variant="caption" marginLeft={1}>
        This button calculates third value based on two available values above
      </Typography>
    </div>
  );
};

export const Crowdsales: FC = () => {
  return (
    <FormDataConsumer className="crowdsales-input">
      {({ formData, ...rest }) => {
        return (
          <>
            <Typography variant="h4">Rounds</Typography>
            <CrowdsalesArrayInput label={false} source="crowdsales" {...rest}>
              <ArrayInputIterator>
                <SelectInput
                  label="Crowdsale type"
                  source="type"
                  choices={Object.values(CrowdsaleType).map((name) => ({ id: name, name }))}
                  validate={[required()]}
                />
                <FormDataConsumer>
                  {({ scopedFormData, getSource }) => {
                    return (
                      <ReferenceInput
                        label="Launchpads"
                        source={getSource ? getSource('idoPlatformId') : ''}
                        reference="ido-platforms"
                        resource="ido-platforms"
                        sort={{ field: 'id', order: SortingDirection.ASC }}
                        filter={
                          ['ICO', 'IEO', 'IDO'].includes(scopedFormData?.type) ? { type: scopedFormData?.type } : {}
                        }
                      >
                        <AutocompleteInput optionText="name" />
                      </ReferenceInput>
                    );
                  }}
                </FormDataConsumer>

                <SelectInput
                  label="Priority"
                  source="priorityRating"
                  choices={new Array(10).fill(0).map((_, i) => ({ id: (i + 1).toString(), name: (i + 1).toString() }))}
                />

                <DateInput label="Start Date" source="startDate" />

                <DateInput label="End Date" source="endDate" />
                <BooleanInput label="Show only month" source="showOnlyMonth" />
                <BooleanInput label="Round closed" source="isClosed" />
                <div>If both dates not set - TBA</div>

                <TextInput source="lockupPeriod" label="Lockup Period" />

                <TextInput source="minMaxPersonalCap" label="Min/Max Personal Cap" />

                <BooleanInput label="Calculate ROI Table" source="isCalculateRoiTable" />

                <NumberInput source="tokensForSale" label="Tokens For Sale" />
                <NumberInput source="price" />
                <NumberInput source="raise" />
                <CalculateButton />
              </ArrayInputIterator>
            </CrowdsalesArrayInput>
          </>
        );
      }}
    </FormDataConsumer>
  );
};
