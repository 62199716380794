export function capitalize(name: string): string {
  return name.charAt(0).toUpperCase() + name.slice(1);
}

export function startCase(bannerType: string): string {
  return bannerType
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}
