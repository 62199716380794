import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  TextInput,
  Create,
  TopToolbar,
  CreateButton,
  ListButton,
  RefreshButton,
  SimpleForm,
  Filter,
  BulkDeleteWithUndoButton,
} from 'react-admin';
import { SortingDirection } from '../../variables';
import { DefaultToolBar } from '../../toolbars/default-toolbar';
import { getIsDisabledByRole } from '../../utils/get-disabled-by-role';
import { AdminDeleteButton } from '../../buttons/delete-btn';

const Form = (
  <SimpleForm toolbar={<DefaultToolBar />}>
    <TextInput source="name" required helperText="Name" />
  </SimpleForm>
);

export const PersonJobsCreate = (props) => {
  return <Create {...props}>{Form}</Create>;
};

const EditActions = (props) => {
  return (
    <TopToolbar>
      <CreateButton />
      <ListButton />
      <RefreshButton />
    </TopToolbar>
  );
};

export const PersonJobsEdit = (props) => (
  <Edit actions={<EditActions />} {...props}>
    {Form}
  </Edit>
);

const FilterComponent = (props) => (
  <Filter {...props}>
    <TextInput label="Name" source="q" alwaysOn />
  </Filter>
);

export const PersonJobsList = (props) => (
  <List {...props} sort={{ field: 'id', order: SortingDirection.ASC }} perPage={50} filters={<FilterComponent />}>
    <Datagrid bulkActionButtons={<BulkDeleteWithUndoButton {...props} disabled={getIsDisabledByRole()} />}>
      <TextField source="name" label="Name" />
      <EditButton />
      <AdminDeleteButton />
    </Datagrid>
  </List>
);
