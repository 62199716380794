import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  TabbedForm,
  FormTab,
  TextInput,
  Create,
  FunctionField,
  ArrayInput,
  SelectInput,
  BulkDeleteWithUndoButton,
  RecordContext,
} from 'react-admin';
import { locales, SortingDirection } from '../variables';
import { DefaultToolBar } from '../toolbars/default-toolbar';
import { getIsDisabledByRole } from '../utils/get-disabled-by-role';
import { useContext } from 'react';
import { ArrayInputIterator } from '../inputs/array-input-iterator';

export const SeoTemplateList = (props) => (
  <List sort={{ field: 'id', order: SortingDirection.ASC }} perPage={50}>
    <Datagrid bulkActionButtons={<BulkDeleteWithUndoButton {...props} disabled={getIsDisabledByRole()} />}>
      <TextField source="id" />
      <TextField source="name" />
      <EditButton />
    </Datagrid>
  </List>
);

const Form = (
  <TabbedForm toolbar={<DefaultToolBar />}>
    <FormTab label="summary">
      <TextInput source="name" />
      <FunctionField
        label="Fields"
        render={(record) => (
          <div>
            {Object.entries(record.variables).map(([variable, value]) => (
              <div key={variable}>
                %{variable}% - {value as string}
              </div>
            ))}
          </div>
        )}
      />
      <TextInput multiline={true} fullWidth={true} source="h1" />
      <TextInput multiline={true} fullWidth={true} source="title" />
      <TextInput multiline={true} fullWidth={true} source="description" />
      <TextInput multiline={true} fullWidth={true} source="subtitleH2" />
      <TextInput multiline={true} fullWidth={true} source="subtitleText" />
    </FormTab>
    <FormTab label="localization">
      <ArrayInput label="SEO Localization" source="localization">
        <ArrayInputIterator fullWidth={true}>
          <SelectInput label="Locale" source="locale" choices={locales} />
          <TextInput multiline={true} fullWidth={true} label="H1" source="h1" />
          <TextInput multiline={true} fullWidth={true} label="Title" source="title" />
          <TextInput multiline={true} fullWidth={true} label="Description" source="description" />
          <TextInput multiline={true} fullWidth={true} label="H2" source="subtitleH2" />
          <TextInput multiline={true} fullWidth={true} label="Subtitle" source="subtitleText" />
        </ArrayInputIterator>
      </ArrayInput>
    </FormTab>
  </TabbedForm>
);

const SeoTemplateTitle = () => {
  const context = useContext(RecordContext);
  return <span>SEO Template '{context?.name}'</span>;
};

export const SeoTemplateEdit = () => {
  return <Edit title={<SeoTemplateTitle />}>{Form}</Edit>;
};

export const SeoTemplateCreate = () => <Create>{Form}</Create>;
