import { TextInput } from 'ra-ui-materialui';
import {
  AutocompleteArrayInput,
  BooleanInput,
  maxLength,
  ReferenceArrayInput,
  required,
  SelectInput,
} from 'react-admin';
import { ImageWithPreview } from './components';
import { SortingDirection } from '../../variables';
import { adTypes } from './variables';

export const TagsBanner = (props) => {
  return (
    <>
      <ReferenceArrayInput
        source="coinTagsIds"
        reference="coin-tag"
        perPage={20}
        sort={{ field: 'id', order: SortingDirection.ASC }}
      >
        <AutocompleteArrayInput optionText="name" label="Tags" fullWidth={true} validate={required()} />
      </ReferenceArrayInput>
      <TextInput source="title" fullWidth={true} validate={[required(), maxLength(30)]} />
      <TextInput
        source="url"
        label="Link url"
        helperText="URL with https://"
        fullWidth={true}
        validate={[required()]}
      />

      <BooleanInput source="showButton" />

      <ImageWithPreview validate={props.formType === 'edit' ? [] : required()} />
      <SelectInput source="adType" choices={adTypes} />
    </>
  );
};
