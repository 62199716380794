import { CircularProgress } from '@mui/material';
import styled from 'styled-components';

const Container = styled.div`
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoaderWrapper = ({ children }) => {
  if (!children) {
    return (
      <Container>
        <CircularProgress size={100} />
      </Container>
    );
  }

  return children;
};
