import { AvailableEnvMode } from '../env-lib';

export const checkNodeEnv = () => {
  const processEnv =
    window && (window as any)._env_ ? (window as any)._env_.REACT_APP_ENV : (process.env.REACT_APP_ENV as any);

  if (!processEnv) {
    throw new Error('REACT_APP_ENV not set');
  }

  const possibleEnvs = Object.values(AvailableEnvMode);

  if (!possibleEnvs.includes(processEnv)) {
    throw new Error('Incorrect REACT_APP_ENV value');
  }
};
