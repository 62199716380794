import { FC } from 'react';
import { TabbedForm } from 'ra-ui-materialui';
import { TabContent } from './vesting-components/tab-content';
import { useRecordContext } from 'react-admin';

export const VestingTab: FC<any> = (props) => {
  const record = useRecordContext();

  const label = 'vesting';

  if (props.hidden) {
    return <TabbedForm.Tab label={label} {...props} />;
  }

  return (
    <TabbedForm.Tab label={label} {...props}>
      <TabContent coinId={record.id} maxSupply={record.maxSupply} totalSupply={record.totalSupply} />
    </TabbedForm.Tab>
  );
};
