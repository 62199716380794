import { useState } from 'react';
import { BooleanInput, SelectInput, useRecordContext } from 'react-admin';
import { getRewardFormByType } from './reward-form-by-type';
import { RewardsType, defaultRewardTypes } from './variables';
import { LimitedOptions } from './write-offs';

export const limitedOptions = [
  { id: 'Limited', name: 'Limited' },
  { id: 'Unlimited', name: 'Unlimited' },
];

export const FcfsForm = (props) => {
  const record = useRecordContext();
  const [limitedType, setLimitedType] = useState(limitedOptions[0].id);
  const defaultRewardType = record?.rewardType || defaultRewardTypes[0].id;
  const [rewardType, setRewardType] = useState(defaultRewardType);
  const questType = record && Object.values(record).length ? record.questType : props.questType;

  return (
    <>
      <SelectInput
        source="questData.type"
        label="Limit type"
        choices={limitedOptions}
        onChange={(event) => setLimitedType(event.target.value)}
      />
      {limitedType !== LimitedOptions.UNLIMITED && (
        <BooleanInput
          source="isReusable"
          label="Reusable"
          disabled={limitedType === LimitedOptions.UNLIMITED || props.type === LimitedOptions.UNLIMITED ? true : false}
        />
      )}
      <div>
        <SelectInput
          source="rewardType"
          choices={defaultRewardTypes}
          defaultValue={defaultRewardType}
          onChange={(event) => setRewardType(event.target.value)}
        />
      </div>
      <hr />
      <div>{getRewardFormByType(rewardType as RewardsType, questType)}</div>
      <hr />
    </>
  );
};
