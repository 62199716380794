import { maxLength } from 'react-admin';
import { TextInput } from 'ra-ui-materialui';
import { ImageWithPreview } from './components';

export const SearchFieldBanner = (props) => {
  return (
    <>
      <TextInput source="callToAction" label="Button text" validate={maxLength(50)} />
      <TextInput source="url" label="Link URL" helperText="URL with https://" fullWidth={true} />
      <ImageWithPreview />
    </>
  );
};
