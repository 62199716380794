export enum TaskType {
  DISCORD_JOIN_SERVER = 'discord_join_server',
  PROFILE_ADD_WALLET = 'profile_add_wallet',
  TELEGRAM_JOIN_CHANNEL = 'telegram_join_channel',
  TWITTER_FOLLOW_USER = 'twitter_follow_user',
  CONNCET_METAMASK_WALLET = 'connect_metamask_wallet',
  TWITTER_LIKE_TWEET = 'twitter_like_tweet',
  TWITTER_RETWEET_TWEET = 'twitter_retweet_tweet',
  LIKE_TWITTER_POST = 'like_twitter_post',
  RETWEET_TWITTER_POST = 'retweet_twitter_post',
}

export const TaskTypesList = [
  { id: TaskType.PROFILE_ADD_WALLET, name: 'Profile add wallet' },
  { id: TaskType.TELEGRAM_JOIN_CHANNEL, name: 'Telegram join channel' },
  { id: TaskType.TWITTER_FOLLOW_USER, name: 'Twitter follow user' },
  { id: TaskType.DISCORD_JOIN_SERVER, name: 'Discord join server' },
  { id: TaskType.CONNCET_METAMASK_WALLET, name: 'Connect Metamask wallet' },
  { id: TaskType.TWITTER_LIKE_TWEET, name: 'Twitter like tweet' },
  { id: TaskType.TWITTER_RETWEET_TWEET, name: 'Twitter retweet tweet' },
  { id: TaskType.LIKE_TWITTER_POST, name: 'Like Twitter Post' },
  { id: TaskType.RETWEET_TWITTER_POST, name: 'Retweet Twitter Post' },
];

export enum QuestType {
  LOTTERY = 'lottery',
  FIRST_COME_FIRST_SERVED = 'firstComeFirstServed',
}

export enum RewardsType {
  TOKEN = 'token',
  NFT = 'nft',
  CUSTOM = 'custom',
}

export const defaultRewardTypes = [
  { id: 'token', name: 'Token' },
  { id: 'nft', name: 'NFT' },
  { id: 'custom', name: 'Custom' },
];

export const defaultDisplayTypes = [
  { id: 'wallet', name: 'Display wallets' },
  { id: 'email', name: 'Display emails' },
];
