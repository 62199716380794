import {
  AutocompleteArrayInput,
  Button,
  CheckboxGroupInput,
  Create,
  CreateButton,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditButton,
  FormDataConsumer,
  FormTab,
  List,
  ListButton,
  ReferenceArrayInput,
  RefreshButton,
  required,
  TabbedForm,
  TextField,
  TextInput,
  TopToolbar,
  TranslatableInputs,
  useNotify,
  useRecordContext,
  useTheme,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import { SortingDirection } from '../../variables';
import { SyntheticEvent, useState } from 'react';
import { FolderOpen, GetAppRounded } from '@mui/icons-material';
import { getSmartContractMethods } from '../../api-service';
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress } from '@mui/material';
import { getRole } from '../../utils/get-role';
import { UserRole } from '../../variables/user-role';
import { NumberInput } from '../../inputs/number-input';
import { AdminDeleteButton } from '../../buttons/delete-btn';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import { useWatch } from 'react-hook-form';
import { FieldWrapper, PlayButton, ResponseTextWrapper, ScriptResultWrapper } from './styled';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import { JsonViewer } from '@textea/json-viewer';

type Props = {
  platformIds: number[];
};

enum FormTabs {
  ContractMethodsTab = 'ContractMethodsTab',
  EndpointsDataTab = 'EndpointsDataTab',
}

const save = (data) => {
  if (data.verification_condition.verification_script && data.verification_condition.endpoint) {
    return data;
  }
  return { ...data, verification_condition: null };
};

const isValidScript = (script) => {
  // Проверяем скрипт на нежелательные конструкции
  const forbiddenPatterns = [
    /eval\(/,
    /new Function/,
    /window\./,
    /document\./,
    /localStorage\./,
    /sessionStorage\./,
    /XMLHttpRequest/,
    /fetch\(/,
  ];
  return !forbiddenPatterns.some((pattern) => pattern.test(script));
};

const functionSupportedResponseArray = [0, 1];

const OnChainForm = ({ platformIds }: Props) => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const [smartContractAddress, setSmartContractAddress] = useState<string>('');
  const [methods, setMethods] = useState<{ id: string; name: string }[]>([]);

  const handleGetContractsMethod = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    smartContractAddress: string
  ): Promise<void> => {
    e.preventDefault();

    if (!smartContractAddress) {
      notify('Please Fill Out Smart Contract Address');
      return;
    }

    setLoading(true);
    setMethods([]);

    try {
      const data = await getSmartContractMethods({ address: smartContractAddress, platformIds: platformIds.join(',') });
      const results = data.map((value, index) => ({ id: value, name: `${index + 1}. ${value}` }));
      setMethods(results);
    } catch (error) {
      console.error('Error:', error);
      notify('Error get methods');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <hr />
      <h3>Получение методов контракта</h3>
      <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '40%' }}>
        <FormDataConsumer>
          {({ formData }) => {
            setSmartContractAddress(formData.smart_contract_address);
            return <TextInput source="smart_contract_address" label="Smart Contract Address" />;
          }}
        </FormDataConsumer>
        <Button
          label="Получить методы"
          onClick={(e) => handleGetContractsMethod(e, smartContractAddress)}
          type="submit"
          color="primary"
          startIcon={<GetAppRounded />}
          variant="contained"
          style={{ margin: '25px 0', maxWidth: '70%' }}
          disabled={loading}
        />
      </div>
      {methods.length > 0 && (
        <>
          <h4>Выберите методы смарт-контракта:</h4>
          <Accordion style={{ marginBottom: '20px', maxWidth: '55%' }}>
            <AccordionSummary expandIcon={<FolderOpen style={{ fill: '#3F51B5' }} />}>Скрыть/показать</AccordionSummary>
            <AccordionDetails>
              <CheckboxGroupInput source="event_names" label="" row={false} choices={methods} />
            </AccordionDetails>
          </Accordion>
        </>
      )}
      <hr />
    </>
  );
};

const ContractMethodsTab = () => {
  const [platformIds, setPlatformIds] = useState<number[]>([]);

  return (
    <>
      <OnChainForm platformIds={platformIds} />
      <FormDataConsumer>
        {({ formData }) => {
          setPlatformIds(formData.platformIds);
          return (
            <ReferenceArrayInput
              source="platformIds"
              reference="token-platforms"
              sort={{ field: 'name', order: SortingDirection.ASC }}
              isRequired={true}
            >
              <AutocompleteArrayInput fullWidth optionText="name" label="Token Platforms" />
            </ReferenceArrayInput>
          );
        }}
      </FormDataConsumer>
      <DateInput
        label="Start Date"
        source="start_date"
        helperText="Самая ранняя дата первой транзакции"
        validate={required()}
      />
      <DateInput label="End Date" source="end_date" helperText="Самая поздняя дата последней транзакции" />
      <NumberInput
        label="Transaction amount"
        source="transaction_amount"
        helperText="Минимальная стоимость одной транзакции в долларах"
      />
      <NumberInput
        label="Total amount"
        source="total_amount"
        helperText="Минимальная суммарная стоимость всех транзакций"
      />
      <NumberInput
        label="Number of transactions"
        source="number_of_transactions"
        helperText="Минимальное необходимое количество всех транзакций"
      />
    </>
  );
};

const EndpointsDataTab = () => {
  const [theme] = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState<JSON>();
  const [verificationScriptResult, setVerificationScriptResult] = useState<number>();

  const testRequestWallet = useWatch({ name: 'verification_condition.test_request_wallet' });
  const verificationScript = useWatch({ name: 'verification_condition.verification_script' });
  const endpoint = useWatch({ name: 'verification_condition.endpoint' });

  const notify = useNotify();
  const record = useRecordContext();

  const currentTestWallet = testRequestWallet || record?.verification_condition?.test_request_wallet;
  const currentEndpoint = endpoint || record?.verification_condition?.endpoint;
  const currentScript = verificationScript || record?.verification_condition?.verification_script;

  const onTestResponseClick = async (e) => {
    e.preventDefault();

    if (isLoading) {
      return;
    }

    try {
      setIsLoading(true);
      const updatedEndpoint = currentEndpoint.replace(/\$address/g, currentTestWallet);

      const data = await fetch(updatedEndpoint).then((res) => res.json());
      console.log(data);

      setResponse(data);
    } catch (error) {
      console.error('Failed to fetch:', error);
      notify(`Failed to fetch: ${JSON.stringify(error)}`);
    } finally {
      setIsLoading(false);
    }
  };

  const runVerificationScript = (e) => {
    e.preventDefault();
    try {
      let functionBody = currentScript.substring(currentScript.indexOf('{') + 1, currentScript.lastIndexOf('}'));
      functionBody = `"use strict"; return (function(response) { ${functionBody} });`;

      if (!isValidScript(functionBody)) {
        throw new Error('Script contains forbidden patterns');
      }

      /* eslint-disable no-new-func */
      const scriptFunction = new Function('response', functionBody);
      const result = scriptFunction()(response);

      if (typeof result !== 'number' || !functionSupportedResponseArray.includes(result)) {
        notify('Функция должна вернуть число 0 или 1', { type: 'error' });
        return;
      }

      setVerificationScriptResult(result);
    } catch (error) {
      console.error('Failed to execute verification script:', error);
    }
  };

  return (
    <>
      <h3>Получение данных с Endpoints от Блоксканов</h3>
      <span>
        Endpoint <span style={{ color: 'red' }}>*</span>
      </span>
      <div>Для обозначения кошелька используйте $address, вместо этой переменной будет подставлен номер кошелька</div>
      <TextInput sx={{ width: '752px' }} label="Endpoint" source="verification_condition.endpoint" />
      <Box sx={{ display: 'flex', flexDirection: 'column' }} gap={2}>
        <FieldWrapper>
          <span>Test response</span>
          <div style={{ position: 'relative', width: '100%' }}>
            <TextInput fullWidth source="verification_condition.test_request_wallet" label="Wallet" helperText="" />
            <PlayButton onClick={onTestResponseClick} disabled={isLoading}>
              <PlayCircleFilledWhiteIcon />
            </PlayButton>
          </div>

          <ResponseTextWrapper>
            {isLoading && (
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress size={100} />
              </Box>
            )}
            {!isLoading && response && (
              <JsonViewer
                value={response}
                theme={theme === 'dark' ? 'dark' : 'light'}
                displayDataTypes={false}
                enableClipboard={false}
                displaySize={false}
              />
            )}
          </ResponseTextWrapper>
        </FieldWrapper>

        <FieldWrapper>
          <div>
            Expression <span style={{ color: 'red' }}>*</span>
          </div>
          <div>Функция внутри себя должна работать с переменной response и возвращать число 0 или 1</div>
          <div style={{ width: '100%', display: 'flex' }}>
            <PlayButton style={{ top: '45%' }} onClick={runVerificationScript}>
              <PlayCircleFilledWhiteIcon />
            </PlayButton>
            <TextInput
              sx={{ width: '50%' }}
              defaultValue="function(response) {}"
              source="verification_condition.verification_script"
              label="Expression"
              helperText=""
              multiline
              rows={10}
            />
            <ScriptResultWrapper>{verificationScriptResult}</ScriptResultWrapper>
          </div>
        </FieldWrapper>
      </Box>
    </>
  );
};

const Form = (props) => {
  const [value, setValue] = useState(FormTabs.ContractMethodsTab);

  const handleChange = (event: SyntheticEvent, _value: FormTabs) => {
    setValue(_value);
  };

  return (
    <TabbedForm {...props}>
      <FormTab label="summary">
        <TranslatableInputs locales={['en', 'ru']} defaultLocale="en">
          <TextInput source="name_translations" validate={required()} />
          <RichTextInput source="description_translations" fullWidth={true} />
        </TranslatableInputs>
        <TextInput source="social_network_url" label="Social Network URL" validate={required()} />
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Получение методов контракта" value={FormTabs.ContractMethodsTab} />
            <Tab label="Данные с Endpoints" value={FormTabs.EndpointsDataTab} />
          </Tabs>
        </Box>

        {value === FormTabs.ContractMethodsTab && <ContractMethodsTab />}
        {value === FormTabs.EndpointsDataTab && <EndpointsDataTab />}
      </FormTab>
    </TabbedForm>
  );
};

export const OnChainTasksCreate = (props) => (
  <Create {...props} transform={save}>
    <Form {...props} />
  </Create>
);

const EditActions = () => {
  return (
    <TopToolbar>
      <CreateButton />
      <ListButton />
      <RefreshButton />
    </TopToolbar>
  );
};

export const OnChainTasksEdit = (props) => (
  <Edit actions={<EditActions />} transform={save} title={<span>Tasks</span>} {...props} mutationMode="pessimistic">
    <Form {...props} />
  </Edit>
);

export const OnChainTasksList = (props) => {
  const isDisabled = getRole() === UserRole.ACCOUNT_MANAGER;

  return (
    <List {...props} sort={{ field: 'end_date', order: SortingDirection.DESC }} perPage={50} exporter={false}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="name_translations.en" label="Name" />
        <TextField source="number_of_transactions" />
        <TextField source="total_amount" />
        <TextField source="transaction_amount" />
        <TextField source="event_names.length" label="Methods Qty" />
        <DateField source="start_date" showTime locales="ru" />
        <DateField source="end_date" showTime locales="ru" />
        <EditButton disabled={isDisabled} />
        <AdminDeleteButton disabled={isDisabled} />
      </Datagrid>
    </List>
  );
};
