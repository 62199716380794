import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  TextInput,
  Create,
  TopToolbar,
  CreateButton,
  ListButton,
  RefreshButton,
  SimpleForm,
  DateField,
  BooleanInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  Filter,
  BulkDeleteWithUndoButton,
} from 'react-admin';
import { SortingDirection } from '../../variables';
import { DefaultToolBar } from '../../toolbars/default-toolbar';
import { getIsDisabledByRole } from '../../utils/get-disabled-by-role';

const NewsSourceForm = (
  <SimpleForm toolbar={<DefaultToolBar />}>
    <BooleanInput source="enabled" />
    <BooleanInput source="hideTagNews" label="Hide Tag News" />
    <TextInput source="name" required helperText="Название тега" />
    <ReferenceArrayInput
      source="categoriesIds"
      reference="news-category"
      perPage={20}
      sort={{ field: 'id', order: SortingDirection.ASC }}
    >
      <AutocompleteArrayInput optionText="name" />
    </ReferenceArrayInput>
  </SimpleForm>
);

export const NewsCategoryCreate = (props) => {
  return <Create {...props}>{NewsSourceForm}</Create>;
};

const NewsCategoryEditActions = (props) => {
  return (
    <TopToolbar>
      <CreateButton />
      <ListButton />
      <RefreshButton />
    </TopToolbar>
  );
};

export const NewsCategoryEdit = (props) => (
  <Edit actions={<NewsCategoryEditActions />} {...props}>
    {NewsSourceForm}
  </Edit>
);

const TagsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Tag" source="q" alwaysOn />
  </Filter>
);

export const NewsCategoryList = (props) => (
  <List {...props} sort={{ field: 'createdAt', order: SortingDirection.ASC }} perPage={50} filters={<TagsFilter />}>
    <Datagrid bulkActionButtons={<BulkDeleteWithUndoButton {...props} disabled={getIsDisabledByRole()} />}>
      <TextField source="id" label="ID" />
      <TextField source="name" label="Source Name" />
      <TextField source="enabled" label="Enabled" />
      <TextField source="hideTagNews" label="Hide Tag News" />
      <TextField source="newsCount" label="News Count" />
      <DateField source="createdAt" label="Date Added" />
      <EditButton />
    </Datagrid>
  </List>
);
