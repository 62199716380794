import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  TextInput,
  Create,
  SimpleForm,
  BulkDeleteWithUndoButton,
  AutocompleteInput,
  ReferenceInput,
} from 'react-admin';
import { DefaultToolBar } from '../../toolbars/default-toolbar';
import { getIsDisabledByRole } from '../../utils/get-disabled-by-role';
import { NumberInput } from '../../inputs/number-input';

const Form = (
  <SimpleForm toolbar={<DefaultToolBar />}>
    <TextInput source="locale.en.name" required label="En Name" />
    <TextInput source="locale.en.title" required label="En H1 Title" />

    <TextInput source="locale.ru.name" label="Ru Name" />
    <TextInput source="locale.ru.title" label="Ru H1 Title" />

    <TextInput source="slug" required label="Url slug" />
    <NumberInput source="position" label="Position" />

    <ReferenceInput label="Category" source="category_id" reference="articles-categories" required>
      <AutocompleteInput optionText="slug" />
    </ReferenceInput>
  </SimpleForm>
);

export const SubcategoriesCreate = (props) => {
  return <Create {...props}>{Form}</Create>;
};

export const SubcategoriesEdit = (props) => <Edit {...props}>{Form}</Edit>;

export const SubcategoriesList = (props) => (
  <List {...props} perPage={50}>
    <Datagrid bulkActionButtons={<BulkDeleteWithUndoButton {...props} disabled={getIsDisabledByRole()} />}>
      <TextField source="slug" label="Slug" />
      <TextField source="position" label="Position" />
      <EditButton />
    </Datagrid>
  </List>
);
