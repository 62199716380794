import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  TextInput,
  Create,
  CreateButton,
  ListButton,
  RefreshButton,
  Filter,
  ImageField,
  ImageInput,
  FunctionField,
  ReferenceInput,
  BulkDeleteWithUndoButton,
  Toolbar,
  TranslatableInputs,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  maxLength,
  TopToolbar,
  useRecordContext,
  TabbedForm,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import { getEnvs } from '../../env-lib';
import { NumberInput } from '../../inputs/number-input';
import { imageInputAccept, SortingDirection } from '../../variables';
import { S3ImageDir } from '../../variables';
import { AutocompleteInput } from '../../inputs/autocomplete-input';
import { AdminDeleteButton } from '../../buttons/delete-btn';
import { getIsDisabledByRole } from '../../utils/get-disabled-by-role';
import { AdminSaveButton } from '../../buttons/save-btn';
import { RichTextInputCustomToolbar } from '../../toolbars/rich-text-input-toolbar';
import { helperTexts } from '../../variables/helper-texts';
import { TrimOnBlurInput } from '../../inputs/trim-on-blur-input';

const imagesBasePath = getEnvs().REACT_APP_IMAGES_BASE_URL + S3ImageDir.PERSONS;
const shortDescriptionMaxLength = 150;

const Form = (
  <TabbedForm
    toolbar={
      <Toolbar style={{ justifyContent: 'space-between' }}>
        <AdminSaveButton disabled={false} />
        <AdminDeleteButton disabled={false} />
      </Toolbar>
    }
  >
    <TabbedForm.Tab label="Summary">
      <TrimOnBlurInput source="name" />
      <TextInput source="slug" helperText={helperTexts.keyInputValidation} />
      <ReferenceArrayInput
        label="Category"
        source="categoryIds"
        resource="categoryIds"
        reference="persons-categories"
        perPage={20}
        sort={{ field: 'id', order: SortingDirection.ASC }}
      >
        <AutocompleteArrayInput optionText="name" />
      </ReferenceArrayInput>
      <NumberInput source="tier" />

      <ReferenceInput
        label="Jurisdiction"
        source="country_id"
        reference="countries"
        perPage={10}
        allowEmpty={true}
        sort={{ field: 'id', order: SortingDirection.ASC }}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <TranslatableInputs locales={['en', 'ru']} defaultLocale="en">
        <TextInput multiline source="short_description" validate={maxLength(shortDescriptionMaxLength)} />
        <RichTextInput source="description" toolbar={<RichTextInputCustomToolbar />} fullWidth={true} />
      </TranslatableInputs>

      <FunctionField
        label="Image"
        render={(record) => {
          return record.logo ? (
            <img src={imagesBasePath + record.logo} alt="image" style={{ maxHeight: '170px' }} />
          ) : null;
        }}
      />
      <ImageInput source="image" label="Image" accept={imageInputAccept}>
        <ImageField source="src" title="Image" />
      </ImageInput>
      <p>Maximum size is 1MB</p>
    </TabbedForm.Tab>

    <TabbedForm.Tab label="links">
      <ReferenceArrayInput source="link_ids" reference="links" sort={{ field: 'id', order: SortingDirection.DESC }}>
        <AutocompleteArrayInput filterToQuery={(q) => ({ q_value: q })} optionText="value" />
      </ReferenceArrayInput>
    </TabbedForm.Tab>
  </TabbedForm>
);

export const PersonsCreate = (props) => <Create {...props}>{Form}</Create>;

const EditActions = () => {
  return (
    <TopToolbar>
      <CreateButton />
      <ListButton />
      <RefreshButton />
    </TopToolbar>
  );
};

const Title = () => {
  const record = useRecordContext();
  return <span>Person {record?.name}'</span>;
};

export const PersonsEdit = (props) => (
  <Edit actions={<EditActions />} title={<Title />} {...props}>
    {Form}
  </Edit>
);

const FilterComponent = (props) => (
  <Filter {...props}>
    <TextInput label="Name" source="q" alwaysOn />
  </Filter>
);

export const PersonsList = (props) => (
  <List {...props} sort={{ field: 'id', order: SortingDirection.ASC }} perPage={50} filters={<FilterComponent />}>
    <Datagrid bulkActionButtons={<BulkDeleteWithUndoButton {...props} disabled={getIsDisabledByRole()} />}>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="categories[0].name" label="Categories" sortable={false} />
      <TextField source="slug" />
      <EditButton />
      <AdminDeleteButton />
    </Datagrid>
  </List>
);
