import { BooleanInput, ReferenceInput, required, ArrayInput, SelectInput, DateInput } from 'react-admin';
import { AutocompleteInput } from '../../inputs/autocomplete-input';
import { SortingDirection } from '../../variables';
import { AppResourceNames } from '../../variables/app-resource-names';
import { ArrayInputIterator } from '../../inputs/array-input-iterator';

const CoinInput = (props) => {
  const { categoryId, tagId, ...inputProps } = props;

  const getFilter = () => {
    const initFilter = {
      activeLifeCycle: true,
    };

    if (categoryId) {
      return {
        ...initFilter,
        categoryId,
      };
    }

    if (tagId) {
      return {
        ...initFilter,
        tagId,
      };
    }

    return;
  };

  return (
    <ReferenceInput
      reference={AppResourceNames.COINS}
      filter={getFilter()}
      sort={{ field: 'id', order: SortingDirection.ASC }}
      {...inputProps}
    >
      <AutocompleteInput label="Coin" optionText="fullName" validate={required()} />
    </ReferenceInput>
  );
};

export const CategoryCoins = (props) => {
  const { scopedFormData, ...arrayInputProps } = props;

  const { coin_category_id, coin_tag_id, category_coins } = scopedFormData;

  if (!coin_category_id && !coin_tag_id) {
    return null;
  }

  const maxPriority = 3;
  const maxSponsoredCoins = 3;

  return (
    <ArrayInput label="Sponsored Projects" {...arrayInputProps}>
      <ArrayInputIterator
        disableReordering={true}
        disableClear={true}
        disableAdd={category_coins && category_coins.length >= maxSponsoredCoins}
      >
        <CoinInput source="coin_id" categoryId={coin_category_id} tagId={coin_tag_id} />

        <SelectInput
          source="priority"
          choices={new Array(maxPriority).fill(0).map((_, i) => ({ id: i + 1, name: (i + 1).toString() }))}
        />
        <DateInput label="Start Date" source="priority_start_date" />
        <DateInput label="End Date" source="priority_end_date" />
        <BooleanInput source="show_ad_tag" defaultValue={false} />
      </ArrayInputIterator>
    </ArrayInput>
  );
};
