import { FC } from 'react';
import { DeleteButton, DeleteButtonProps } from 'ra-ui-materialui';
import { getIsDisabledByRole } from '../utils/get-disabled-by-role';

export const AdminDeleteButton: FC<DeleteButtonProps> = ({ disabled, ...rest }) => {
  const isDisabled = getIsDisabledByRole();

  return (
    <DeleteButton
      mutationMode="pessimistic"
      confirmContent="Are you sure you want to delete this page?"
      disabled={isDisabled || disabled}
      {...rest}
    />
  );
};
