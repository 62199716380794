import { ReferenceInput, TabbedForm, ArrayInput, FormDataConsumer } from 'react-admin';
import { AutocompleteInput } from '../../inputs/autocomplete-input';
import { SortingDirection } from '../../variables';
import { AppResourceNames } from '../../variables/app-resource-names';
import { ArrayInputIterator } from '../../inputs/array-input-iterator';
import { CategoryCoins } from './category-coins';
import { Divider } from '../coins/styled';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import IconButton from '@mui/material/IconButton';
import styled from 'styled-components';

export const CustomAddButton = styled(IconButton)`
  position: relative;
  margin-top: 20px;

  &::after {
    content: 'Add Category or Tag';
    position: absolute;
    bottom: 33px;
    left: 7px;
    white-space: nowrap;
    pointer-events: none;
    font-size: 14px;
    font-weight: 500;
  }
`;

export const ProjectsTab = (props) => {
  return (
    <TabbedForm.Tab label="Projects" {...props}>
      <ArrayInput
        source="categories"
        label={false}
        defaultValue={[]}
      >
        <ArrayInputIterator
          disableReordering={true}
          disableClear={true}
          addButton={
            <CustomAddButton size="small">
              <AddCircleOutlineIcon fontSize="small" color='primary' />
            </CustomAddButton>
          }
        >
          <FormDataConsumer>
            {({ getSource, scopedFormData = {} }) => {
              const hasTag = typeof scopedFormData.coin_tag_id === 'number';
              const hasCategory = typeof scopedFormData.coin_category_id === 'number';

              return (
                <>
                  {!hasTag && (
                    <ReferenceInput
                      source={getSource('coin_category_id')}
                      reference={AppResourceNames.COIN_CATEGORY}
                      perPage={100}
                      sort={{ field: 'name', order: SortingDirection.ASC }}
                    >
                      <AutocompleteInput label="Category" optionText="name" />
                    </ReferenceInput>
                  )}

                  {!hasCategory && (
                    <ReferenceInput
                      source={getSource('coin_tag_id')}
                      reference={AppResourceNames.COIN_TAG}
                      perPage={100}
                      sort={{ field: 'name', order: SortingDirection.ASC }}
                    >
                      <AutocompleteInput label="Tag" optionText="name" />
                    </ReferenceInput>
                  )}

                  <CategoryCoins source={getSource('category_coins')} scopedFormData={scopedFormData} />

                  {(hasTag || hasCategory) && <Divider />}
                </>
              );
            }}
          </FormDataConsumer>
        </ArrayInputIterator>
      </ArrayInput>
    </TabbedForm.Tab>
  );
};
