import * as React from 'react';
import { Done, Close } from '@mui/icons-material';
import { useRecordContext } from 'ra-core';

export const EnabledField: React.ComponentType<any> = ({ className, source }) => {
  const record = useRecordContext();

  const isEnabled = record[source];

  if (isEnabled === true) {
    return <Done className={className} titleAccess="Enabled" color="primary" />;
  }

  if (isEnabled === false) {
    return <Close className={className} titleAccess="Disabled" color="error" />;
  }

  return null;
};
