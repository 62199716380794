import { LocalStorageKeys } from '../variables';
import { UserRole } from '../variables/user-role';

export const getRole = () => {
  const role = localStorage.getItem(LocalStorageKeys.ROLE);

  if (!role || !Object.values(UserRole).includes(role as UserRole)) {
    return null;
  }

  return role as UserRole;
};
