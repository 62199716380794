import {
  AddItemButton,
  FormDataConsumer,
  SimpleFormIterator as RaSimpleFormIterator,
  SimpleFormIteratorProps,
  useArrayInput,
} from 'react-admin';
import { Children, isValidElement } from 'react';

export const ArrayInputIterator = (props: SimpleFormIteratorProps) => {
  const { append } = useArrayInput(props);

  const AddButton = (buttonProps) => {
    const onClick = () => {
      const defaultValue: Record<string, string | null> = {};

      Children.forEach(props.children, (input) => {
        if (isValidElement(input) && input.type !== FormDataConsumer && input.props.source) {
          defaultValue[input.props.source] = input.props.defaultValue ?? null;
        }
      });

      append(defaultValue);
    };

    return <AddItemButton {...buttonProps} onClick={onClick} />;
  };

  return <RaSimpleFormIterator addButton={<AddButton />} {...props} />;
};
