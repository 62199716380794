import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  TextInput,
  Create,
  SimpleForm,
  BulkDeleteWithUndoButton,
} from 'react-admin';
import { DefaultToolBar } from '../../toolbars/default-toolbar';
import { getIsDisabledByRole } from '../../utils/get-disabled-by-role';

const Form = (
  <SimpleForm toolbar={<DefaultToolBar />}>
    <TextInput source="name" required label="Name" />
    <TextInput source="slug" required label="Slug" />
  </SimpleForm>
);

export const ArticleTagsCreate = (props) => {
  return <Create {...props}>{Form}</Create>;
};

export const ArticleTagsEdit = (props) => <Edit {...props}>{Form}</Edit>;

export const ArticleTagsList = (props) => (
  <List {...props} perPage={50}>
    <Datagrid bulkActionButtons={<BulkDeleteWithUndoButton {...props} disabled={getIsDisabledByRole()} />}>
      <TextField source="id" label="ID" />
      <TextField source="name" label="Name" />
      <EditButton />
    </Datagrid>
  </List>
);
