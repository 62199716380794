import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  Create,
  TopToolbar,
  CreateButton,
  ListButton,
  RefreshButton,
  SimpleForm,
  DateField,
  ReferenceInput,
  AutocompleteInput,
  DateInput,
  ReferenceField,
  NumberField,
  BulkDeleteWithUndoButton,
  required,
} from 'react-admin';
import { SortingDirection } from '../../variables';
import { getIsDisabledByRole } from '../../utils/get-disabled-by-role';
import { NumberInput } from '../../inputs/number-input';
import { AdminDeleteButton } from '../../buttons/delete-btn';

const Form = (
  <SimpleForm>
    <ReferenceInput
      label="Project"
      source="fund_id"
      perPage={10}
      reference="funds"
      sort={{ field: 'tier', order: SortingDirection.ASC }}
    >
      <AutocompleteInput optionText="name" validate={required()} />
    </ReferenceInput>
    <NumberInput label="Priority" source="priority" validate={required()} />
    <DateInput label="Start date" source="start_date" validate={required()} />
    <DateInput label="End date" source="end_date" validate={required()} />
  </SimpleForm>
);

const EditActions = (props) => {
  return (
    <TopToolbar>
      <CreateButton />
      <ListButton />
      <RefreshButton />
    </TopToolbar>
  );
};

export const SponsoredFundEdit = (props) => (
  <Edit actions={<EditActions />} title={<span>Link</span>} {...props}>
    {Form}
  </Edit>
);

export const SponsoredFundCreate = (props) => {
  return <Create {...props}>{Form}</Create>;
};

export const SponsoredFundList = (props) => (
  <List {...props} sort={{ field: 'priority', order: SortingDirection.ASC }} perPage={50}>
    <Datagrid bulkActionButtons={<BulkDeleteWithUndoButton {...props} disabled={getIsDisabledByRole()} />}>
      <ReferenceField label="Project" source="fund_id" reference="funds">
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="priority" label="Priority" />
      <DateField source="start_date" label="Start Date" />
      <DateField source="end_date" label="End Date" />
      <EditButton />
      <AdminDeleteButton />
    </Datagrid>
  </List>
);
