import { AvailableEnvMode, getEnvs } from '../env-lib';
import { getRole } from './get-role';
import { UserRole } from '../variables/user-role';

export const getIsDisabledByRole = () => {
  const { REACT_APP_ENV } = getEnvs();
  const userRole = getRole();
  return (
    (REACT_APP_ENV === AvailableEnvMode.PRODUCTION && userRole === UserRole.DEVELOPER) || userRole === UserRole.OBSERVER
  );
};
