import { BooleanInput, maxLength, required } from 'react-admin';
import { TextInput } from 'ra-ui-materialui';
import { IconWithPreview } from './components';
import { SelectInput } from 'react-admin';
import { adTypes } from './variables';

export const MarketsBanner = () => {
  return (
    <>
      <TextInput
        source="title"
        label="Title (отвечает за название биржи)"
        fullWidth={true}
        validate={[required(), maxLength(50)]}
      />
      <TextInput source="url" label="URL for link" fullWidth={true} validate={required()} />
      <BooleanInput label="Show Button" source="showButton" />
      <IconWithPreview />
      <BooleanInput label="Shown in Spot" source="showInSpot" />
      <BooleanInput label="Shown in Futures" source="showInFutures" />
      <SelectInput source="adType" choices={adTypes} />
    </>
  );
};
