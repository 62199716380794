import { UserRole } from '../variables/user-role';

export const checkUserRoleAvailability = (userRole: UserRole, allowTo: UserRole[] = []) => {
  // ADMIN и DEVELOPER имеет доступ к любой части
  // НО DEVELOPER только на тестовом окружении(ограничено на API)
  if (userRole === UserRole.ADMIN || userRole === UserRole.DEVELOPER) {
    return true;
  }

  // SUPER_MANAGER имеет доступ ко всему, к чему имеет доступ MANAGER
  if (userRole === UserRole.SUPER_MANAGER && allowTo.includes(UserRole.MANAGER)) {
    return true;
  }

  return allowTo.includes(userRole);
};
