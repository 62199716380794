import { BooleanInput, TextInput } from 'ra-ui-materialui';
import { FormDataConsumer, maxLength } from 'react-admin';
import { IconWithPreview, ImageWithPreview } from './components';

export const ButtonBanner = (props) => {
  return (
    <>
      <BooleanInput source="onlyButton" />
      <TextInput source="buttonText" validate={maxLength(20)} />
      <IconWithPreview />
      <FormDataConsumer>
        {({ formData }) =>
          formData.onlyButton === false && (
            <>
              <ImageWithPreview />
              <TextInput source="title" fullWidth={true}  validate={maxLength(30)} />
              <TextInput source="subtitle" fullWidth={true} validate={maxLength(35)} />
              <TextInput source="description" fullWidth={true} validate={maxLength(60)} />
              <TextInput source="url" label="Link URL" helperText="URL with https://" fullWidth={true} />
            </>
          )
        }
      </FormDataConsumer>
    </>
  );
};
