import simpleRestClient from 'ra-data-simple-rest';
import { addUploadCapabilities } from './addUploadCapabillites';
import { fetchUtils } from 'react-admin';
import { getEnvs } from './env-lib';
import { LocalStorageKeys } from './variables';

export const httpClient = (url, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({
      Accept: 'application/json',
      'Cache-Control': 'no-cache, no-store, must-revalidate',
    });
  }
  const auth = localStorage.getItem(LocalStorageKeys.AUTH);
  options.headers.set('Authorization', `Bearer ${auth}`);
  return fetchUtils.fetchJson(url, options);
};
const client = simpleRestClient(getEnvs().REACT_APP_API_SERVER + '/v0/admin', httpClient);
export const dataProvider = {
  ...client,
  update: addUploadCapabilities(client, 'update'),
  create: addUploadCapabilities(client, 'create'),
};
