import { useRecordContext, useResourceContext, useUpdate } from 'react-admin';
import { Switch } from '@mui/material';

export const ToggleButton = ({ source }: { source: string }) => {
  const record = useRecordContext();
  const resource = useResourceContext();

  const [update, { isLoading }] = useUpdate();

  const handleChange = async (event) => {
    const checked = event.target.checked;
    update(resource, {
      id: record.id,
      previousData: record,
      data: { [source]: checked },
    });
  };

  return (
    <Switch
      checked={record[source]}
      onChange={handleChange}
      title={record[source] ? 'Click to Disable' : 'Click to Enable'}
      disabled={isLoading}
    />
  );
};
