import { FormDataConsumer, useNotify } from 'react-admin';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { Button, Switch, TextField, Tooltip } from '@mui/material';
import { Add, Send, GetAppOutlined } from '@mui/icons-material';
import {
  postCoinVesting,
  getCoinVesting,
  getCoinAllocationsList,
  deleteCoinAllocation,
  parseAllocationList,
} from '../../../api-service';
import { AllocationForm } from './alocation-form';
import { Container, ColumnContainer, Divider } from '../styled';
import { CoinVestingFormData, initialFormData, ICoinAllocation, initialAllocationFormData, formatDate } from './utils';

export const TabContent = ({ coinId, maxSupply, totalSupply }) => {
  const [formData, setFormData] = useState<CoinVestingFormData>(initialFormData);
  const [isLoading, setIsLoading] = useState(false);
  const [allocationList, setAllocationList] = useState<ICoinAllocation[]>([initialAllocationFormData]);
  const notify = useNotify();
  const [dropsSlug, setDropsSlug] = useState('');
  const [isDataParsed, setIsDataParsed] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const allocationsResponse = await getCoinAllocationsList({ coinId });
      const vestingResponse = await getCoinVesting({ coinId });
      const emptyResponse = Object.values(vestingResponse).length === 0;
      const { total_start_date, tge_start_date, links, is_hidden } = vestingResponse;

      setFormData({
        total_start_date: emptyResponse ? '' : formatDate(total_start_date),
        tge_start_date: emptyResponse ? '' : formatDate(tge_start_date),
        links: links && Object.values(links).length > 0 ? links : [],
        is_hidden: is_hidden || false,
      });

      setAllocationList([...allocationsResponse]);
      setIsLoading(false);
    };

    fetchData();
  }, [coinId]);

  useEffect(() => {
    if (isDataParsed) {
      window.location.reload();
    }
  }, [isDataParsed]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleLinksChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    const index = parseInt(name.slice(5), 10);
    const newLinks = [...(formData.links || [])];
    newLinks[index] = value;
    setFormData({ ...formData, links: newLinks });
  };

  const updateTotalInfo = (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      postCoinVesting({ coinId, formData });
      notify('Success update');
    } catch (error: any) {
      notify(error.status + ' ' + error.message, { type: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddAllocation = () => {
    setAllocationList((prevState) => [...prevState, initialAllocationFormData]);
  };

  const handleDeleteAllocation = async (allocationId: number, index: number) => {
    setIsLoading(true);
    try {
      setAllocationList((prevState) => prevState.filter((_, i) => i !== index));
      if (allocationId) {
        await deleteCoinAllocation({ coinId, allocationId });
        notify('Allocation has been delete');
      }
    } catch (error: any) {
      console.error('Error', error);
      notify(error.status + ' ' + error.message, { type: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleParseAllocation = async () => {
    setIsLoading(true);
    try {
      await parseAllocationList({ coinId, dropsSlug });
      notify('Data has been successfully parsed');
      setIsDataParsed(true);
    } catch (error) {
      if (error instanceof Error) {
        notify(error.message, { type: 'error' });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div>
        <h2>Parse Data</h2>
        <div>
          <TextField
            type="text"
            value={dropsSlug}
            onChange={(e) => setDropsSlug(e.target.value)}
            label="Enter Slug"
            style={{ margin: '0 15px 0 0' }}
          />
          <Button
            variant="contained"
            color="primary"
            startIcon={<GetAppOutlined />}
            onClick={handleParseAllocation}
            disabled={isLoading}
          >
            Parse Data
          </Button>
        </div>
      </div>
      <Divider />

      <h2>Total information</h2>
      <div>
        <form>
          <Container>
            <div>
              <ColumnContainer style={{ maxWidth: '100%' }}>
                <Tooltip title="Дата начала вестинга у проекта">
                  <label htmlFor="total_start_date">Total Start Date:</label>
                </Tooltip>
                <TextField
                  hiddenLabel={true}
                  id="total_start_date"
                  name="total_start_date"
                  type="date"
                  value={formatDate(formData?.total_start_date, true)}
                  onChange={handleChange}
                />
              </ColumnContainer>
              <ColumnContainer style={{ margin: '15px 0', maxWidth: '100%' }}>
                <label htmlFor="tge_start_date">TGE Date:</label>
                <TextField
                  hiddenLabel={true}
                  id="tge_start_date"
                  name="tge_start_date"
                  type="date"
                  value={formatDate(formData?.tge_start_date, true)}
                  onChange={handleChange}
                />
              </ColumnContainer>
            </div>
            <div>
              <label>Publish Vesting</label>
              <Switch
                checked={!formData?.is_hidden}
                onChange={(e) => setFormData({ ...formData, is_hidden: !e.target.checked })}
                name="is_hidden"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </div>
            <div>
              <h5 style={{ margin: '5px 0 10px 0' }}>Vesting info (ссылки на оф. документы):</h5>
              {formData?.links &&
                formData?.links.length > 0 &&
                formData?.links.map((link, index) => (
                  <div key={`link-${index}`}>
                    <TextField
                      hiddenLabel={true}
                      type="text"
                      name={`link-${index}`}
                      value={link}
                      onChange={handleLinksChange}
                      style={{ margin: '5px 0px' }}
                    />
                    <Button
                      color="secondary"
                      onClick={() => {
                        const newLinks = [...(formData.links || [])];
                        newLinks.splice(index, 1);
                        setFormData({ ...formData, links: newLinks });
                      }}
                    >
                      Delete
                    </Button>
                  </div>
                ))}
              <Button
                color="primary"
                startIcon={<Add />}
                onClick={() =>
                  setFormData({
                    ...formData,
                    links: [...(Array.isArray(formData.links) ? formData.links : []), ''],
                  })
                }
              />
            </div>
          </Container>
          <div>
            <Button
              type="submit"
              color="primary"
              onClick={updateTotalInfo}
              startIcon={<Send />}
              style={{ margin: '5px 0' }}
              disabled={isLoading}
            >
              <span>Update total info</span>
            </Button>
          </div>
        </form>
      </div>

      <Divider />
      <FormDataConsumer>
        {({ formData }) => {
          const totalTokens =
            allocationList.length > 0
              ? allocationList.map((item) => item.tokens).reduce((curr, acc) => Number(curr) + Number(acc))
              : 0;

          const totalEndDate = allocationList
            .flatMap((allocation) => allocation.batches)
            .reduce((acc, curr) => {
              const currDate = new Date(curr.date);
              return currDate > acc ? currDate : acc;
            }, new Date());

          const supplyQty = Number(formData.maxSupply ?? formData.totalSupply);
          const formattedSupplyQty = supplyQty.toLocaleString('ru-RU');
          const formattedTotalTokens = Number(totalTokens).toLocaleString('ru-RU');
          const difference = (supplyQty - Number(totalTokens)).toLocaleString('ru-RU');

          return (
            <Container>
              <div>
                <Tooltip title="Дата последнего разлока">
                  <h5>Total End Date:</h5>
                </Tooltip>
                {totalEndDate ? totalEndDate.toISOString().slice(0, 10) : ''}
              </div>
              <div>
                <Tooltip title="Данные с Summary">
                  <h5>Supply:</h5>
                </Tooltip>
                {formattedSupplyQty}
              </div>
              <div>
                <Tooltip title="Cумма всех токенов с алокаций">
                  <h5>Total Tokens:</h5>
                </Tooltip>
                {formattedTotalTokens}
              </div>
              <div>
                <Tooltip title="Supply - Total Tokens">
                  <h5>Difference:</h5>
                </Tooltip>
                {difference}
              </div>
            </Container>
          );
        }}
      </FormDataConsumer>
      <Divider />

      <h2>Allocations list:</h2>
      {!isLoading &&
        allocationList.map((allocation, index) => (
          <div key={index}>
            <AllocationForm
              allocation={allocation}
              coinId={coinId}
              index={index + 1}
              handleDeleteAllocation={handleDeleteAllocation}
              totalSupply={Number(maxSupply) || Number(totalSupply)}
              tgeData={formData.tge_start_date.split('T')[0]}
              totalStartDate={formData.total_start_date.split('T')[0]}
            />
          </div>
        ))}
      <Button onClick={handleAddAllocation} startIcon={<Add />} style={{ margin: '40px 0' }}>
        Add New Allocation
      </Button>
    </>
  );
};
