import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  SelectInput,
  Create,
  ReferenceInput,
  ReferenceField,
  TopToolbar,
  ListButton,
  RefreshButton,
  NumberField,
  DateField,
  DateInput,
  BulkDeleteWithUndoButton,
  required,
} from 'react-admin';
import { AutocompleteInput } from '../../inputs/autocomplete-input';
import { SortingDirection } from '../../variables';
import { AdminDeleteButton } from '../../buttons/delete-btn';
import { DefaultToolBar } from '../../toolbars/default-toolbar';
import { AdminCreateBtn } from '../../buttons/create-btn';
import { getIsDisabledByRole } from '../../utils/get-disabled-by-role';

export const CoinTrendingSettingsList = (props) => (
  <List {...props} sort={{ field: 'id', order: SortingDirection.DESC }} perPage={50}>
    <Datagrid bulkActionButtons={<BulkDeleteWithUndoButton {...props} disabled={getIsDisabledByRole()} />}>
      <ReferenceField label="Project" source="coin_id" reference="coins">
        <TextField source="fullName" />
      </ReferenceField>
      <NumberField source="priority" textAlign="left" />
      <DateField source="start_date" />
      <DateField source="end_date" />
      <EditButton />
      <AdminDeleteButton />
    </Datagrid>
  </List>
);

const Form = (
  <SimpleForm toolbar={<DefaultToolBar />}>
    <ReferenceInput
      label="Project"
      source="coin_id"
      reference="coins"
      filter={{ enabled: true }}
      perPage={10}
      sort={{ field: 'id', order: SortingDirection.ASC }}
    >
      <AutocompleteInput optionText="fullName" validate={required()} />
    </ReferenceInput>
    <SelectInput
      source="priority"
      choices={[...Array(7)].map((_, index) => ({ id: index + 1, name: String(index + 1) }))}
      validate={required()}
    />
    <DateInput source="start_date" validate={required()} />
    <DateInput source="end_date" validate={required()} />
  </SimpleForm>
);

const EditActions = () => {
  return (
    <TopToolbar>
      <AdminCreateBtn />
      <ListButton />
      <RefreshButton />
    </TopToolbar>
  );
};

export const CoinTrendingSettingsEdit = (props) => (
  <Edit actions={<EditActions />} title={<span>Link</span>} {...props}>
    {Form}
  </Edit>
);

export const CoinTrendingSettingsCreate = (props) => {
  return <Create {...props}>{Form}</Create>;
};
