import './App.css';
import { Admin, Layout, Resource, defaultDarkTheme } from 'react-admin';
import { authProvider } from './authProvider';
import { dataProvider } from './dataProvider';
import { Dashboard } from './dashboard';
import { UserRole } from './variables/user-role';
import { checkUserRoleAvailability } from './utils/check-user-role-availability';
import { CustomMenu } from './menu';
import { resourceRoutes } from './resources-list';
import { SecuredResourceProps } from './resources-list/types';
import { QueryClient } from 'react-query';

const withSecured = (userRole: UserRole) => (props: SecuredResourceProps) => {
  const { list, create, edit, ...rest } = props;

  if (userRole === UserRole.OBSERVER) {
    return <Resource {...{ list, edit }} {...rest} />;
  }

  const isAccess = checkUserRoleAvailability(userRole, (props.options as any)?.allowTo);

  if (isAccess) {
    return <Resource {...{ list, create, edit }} {...rest} />;
  }

  return <Resource {...rest} />;
};

const CustomLayout = (props) => <Layout {...props} menu={CustomMenu} />;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  return (
    <Admin
      queryClient={queryClient}
      authProvider={authProvider}
      dataProvider={dataProvider}
      dashboard={Dashboard}
      layout={CustomLayout}
      darkTheme={defaultDarkTheme}
      requireAuth={true}
      disableTelemetry={true}
    >
      {(role: UserRole) => [Object.values(resourceRoutes).map(withSecured(role))]}
    </Admin>
  );
};

export default App;
