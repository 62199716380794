/**
 * @description
 * Роли пользователей.
 * В зависимости от роли, пользователь имеет доступ к тому или иному контенту в админке
 *
 * Обязательно задать такие-же доступы на API
 */
export enum UserRole {
  /**
   * Полный доступ
   */
  ADMIN = 'admin',
  /**
   * Доступ ко всему на тестовом окружении
   */
  DEVELOPER = 'developer',
  /**
   * Может менять категории и теги
   */
  SUPER_MANAGER = 'super-manager',
  /**
   * Базовый контентщик на сайте
   */
  MANAGER = 'manager',
  /**
   * Доступ к контент-центру
   */
  AUTHOR = 'author',
  /**
   * Доступ только к Banners и MOBILE_LOCALIZATION
   */
  ACCOUNT_MANAGER = 'account-manager',
  /**
   * Доступ только к SeoTemplates(заголовки на сайте)
   */
  SEO = 'seo',
  /**
   * Юзер с этой ролью может видеть любые вкладки и страницы в админке (продовской и тестовой), но ничего не может редактировать.
   */
  OBSERVER = 'observer',
  /**
   * Обычный пользователь, доступ только к сайту
   */
  USER = 'user',
  /**
   * Аккаунт заблокирован. Не сможет войти в систему
   */
  BLOCKED = 'blocked',
}
