import { TextInput } from 'ra-ui-materialui';
import { BooleanInput, required, SelectInput } from 'react-admin';
import { IconWithPreview } from './components';
import { adTypes } from './variables';

export const FundraisingBanner = (props) => {
  return (
    <>
      <TextInput source="title" label="Title" fullWidth={true} validate={required()} />

      <TextInput label="Link Url" source="url" fullWidth={true} validate={required()} />

      <BooleanInput source="showButton" />

      <IconWithPreview validate={props.formType === 'edit' ? [] : required()} />
      <SelectInput label="Type of AD" source="adType" choices={adTypes} />
    </>
  );
};
