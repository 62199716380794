import {
  List,
  Datagrid,
  TextField,
  EditButton,
  TextInput,
  TopToolbar,
  ListButton,
  RefreshButton,
  Edit,
  Create,
  CreateButton,
  BulkDeleteWithUndoButton,
  NumberField,
  DateField,
  TabbedForm,
} from 'react-admin';
import { FormType, SortingDirection } from '../../variables';
import { getIsDisabledByRole } from '../../utils/get-disabled-by-role';
import { AdminDeleteButton } from '../../buttons/delete-btn';
import { ToggleButton } from '../../buttons/toggle-button';
import { SummaryTab } from './summary-tab';
import { ProjectsTab } from './projects-tab';
import { LinksTab } from './links-tab';

const EditActions = () => {
  return (
    <TopToolbar>
      <CreateButton />
      <ListButton />
      <RefreshButton />
    </TopToolbar>
  );
};

const Form = (props) => {
  const isEdit = props.type === FormType.EDIT;

  return (
    <TabbedForm>
      <SummaryTab isEdit={isEdit} />
      <ProjectsTab />
      {isEdit && <LinksTab />}
    </TabbedForm>
  );
};

export const EcosystemsCreate = () => {
  return (
    <Create>
      <Form type={FormType.CREATE} />
    </Create>
  );
};

export const EcosystemsEdit = (props) => {
  return (
    <Edit actions={<EditActions />}>
      <Form type={FormType.EDIT} />
    </Edit>
  );
};

export const EcosystemsList = () => (
  <List
    sort={{ field: 'id', order: SortingDirection.ASC }}
    perPage={50}
    filters={[<TextInput label="Name" source="q" alwaysOn={true} />]}
  >
    <Datagrid bulkActionButtons={<BulkDeleteWithUndoButton disabled={getIsDisabledByRole()} />}>
      <NumberField source="id" />
      <TextField source="name" />
      <ToggleButton source="enabled" />
      <NumberField source="projectsCount" label="Amount of projects" sortable={false} />
      <DateField source="created_at" />
      <EditButton />
      <AdminDeleteButton />
    </Datagrid>
  </List>
);
