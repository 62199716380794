import { maxLength } from 'react-admin';
import { TextInput } from 'ra-ui-materialui';
import { ImageWithPreview } from './components';

const IMAGE_SIZE = '280 x 139';
const IMAGE_SIZE_MOB = '88 x 88';

export const NativeBanner = (props) => {
  return (
    <>
      <TextInput source="title" fullWidth={true} validate={maxLength(30)} />
      <TextInput source="description" fullWidth={true} validate={maxLength(60)} />
      <TextInput source="callToAction" label="Button text" validate={maxLength(50)} />
      <TextInput source="url" label="Link URL" helperText="URL with https://" fullWidth={true} />
      <ImageWithPreview imageSize={IMAGE_SIZE} />
      <ImageWithPreview src="mobileImage" recordSrc="image_name_mobile" imageSize={IMAGE_SIZE_MOB} />
    </>
  );
};
