import { ArrayInputIterator } from '../../inputs/array-input-iterator';
import { AutocompleteInput, DateInput, ReferenceInput, TextInput } from 'ra-ui-materialui';
import { Typography } from '@mui/material';
import { FC } from 'react';
import { ArrayInput, AutocompleteArrayInput, FormDataConsumer, ReferenceArrayInput, required } from 'react-admin';
import { NumberInput } from '../../inputs/number-input';
import { SortingDirection } from '../../variables';

export const Launchpools: FC = () => {
  return (
    <FormDataConsumer className="crowdsales-input">
      {({ formData, ...rest }) => {
        return (
          formData.hasLaunchpools && (
            <>
              <Typography variant="h4">Launchpools</Typography>
              <ArrayInput label={false} source="launchpools" {...rest}>
                <ArrayInputIterator>
                  <DateInput label="Start Date" source="start_time" />

                  <DateInput label="End Date" source="end_time" />
                  <FormDataConsumer>
                    {({ scopedFormData, getSource }) => {
                      return (
                        <ReferenceInput
                          source={getSource ? getSource('platform_id') : ''}
                          reference="ido-platforms"
                          resource="ido-platforms"
                          sort={{ field: 'id', order: SortingDirection.ASC }}
                          label="Launchpool platform"
                          filter={
                            ['ICO', 'IEO', 'IDO'].includes(scopedFormData?.type) ? { type: scopedFormData?.type } : {}
                          }
                        >
                          <AutocompleteInput optionText="name" label="Launchpool platform" />
                        </ReferenceInput>
                      );
                    }}
                  </FormDataConsumer>
                  <NumberInput
                    source="token_for_sale_id"
                    value={formData.id}
                    defaultValue={formData.id}
                    validate={required()}
                    style={{ display: 'none' }}
                  />

                  <ReferenceArrayInput
                    sort={{ field: 'name', order: SortingDirection.ASC }}
                    source="pool_coin_ids"
                    reference="coins"
                    filter={{ enabled: true }}
                  >
                    <AutocompleteArrayInput
                      label="Pools"
                      optionText={(record) => `${record.name} (${record.symbol})`}
                    />
                  </ReferenceArrayInput>
                  <NumberInput label="Token For Sale" source="tokens_amount" />
                  <TextInput label="Lockup Period" source="lockup_period" />
                  <NumberInput label="Avg. Reward" source="avg_reward" />
                </ArrayInputIterator>
              </ArrayInput>
            </>
          )
        );
      }}
    </FormDataConsumer>
  );
};
