import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  SelectInput,
  Create,
  Filter,
  TopToolbar,
  ListButton,
  RefreshButton,
  UrlField,
  TextInput,
  BulkDeleteWithUndoButton,
  useRecordContext,
  DateField,
} from 'react-admin';
import { Box } from '@mui/material';
import { linkTypes } from '../../variables/link-types';
import { SortingDirection } from '../../variables';
import { AdminDeleteButton } from '../../buttons/delete-btn';
import { AdminCreateBtn } from '../../buttons/create-btn';
import { DefaultToolBar } from '../../toolbars/default-toolbar';
import { getIsDisabledByRole } from '../../utils/get-disabled-by-role';

export const LinksList = (props) => (
  <List
    {...props}
    sort={{ field: 'id', order: SortingDirection.ASC }}
    perPage={50}
    filters={
      <Filter {...props}>
        <SelectInput source="type" choices={linkTypes} alwaysOn={true} />
        <TextInput label="Url" source="q_value" alwaysOn={true} />
      </Filter>
    }
  >
    <Datagrid bulkActionButtons={<BulkDeleteWithUndoButton {...props} disabled={getIsDisabledByRole()} />}>
      <TextField source="id" />
      <TextField source="type" />
      <UrlField source="value" label="Url" target="_blank" rel="nofollow noopener noreferrer" />
      <DateField source='created_at' showTime={true} />
      <EditButton />
      <AdminDeleteButton />
    </Datagrid>
  </List>
);

const Form = () => {
  const record = useRecordContext();
  const requestedTwitterScore = record?.twitter_data?.twitter_score;
  const twitterScore = requestedTwitterScore ? Number(requestedTwitterScore).toFixed(2) : '0';

  return (
    <SimpleForm toolbar={<DefaultToolBar />} width={500}>
      <SelectInput source="type" choices={linkTypes} fullWidth={true} />
      <TextInput multiline={true} source="value" fullWidth={true} />
      {requestedTwitterScore && (
        <Box borderRadius={4} padding={2} bgcolor="aliceblue" color="black">
          Twitter Score: {twitterScore}
        </Box>
      )}
    </SimpleForm>
  );
};

const EditActions = () => {
  return (
    <TopToolbar>
      <AdminCreateBtn />
      <ListButton />
      <AdminDeleteButton />
      <RefreshButton />
    </TopToolbar>
  );
};

export const LinksEdit = (props) => {
  return (
    <Edit actions={<EditActions />}>
      <Form {...props} />
    </Edit>
  );
};

export const LinksCreate = (props) => {
  return (
    <Create>
      <Form {...props} />
    </Create>
  );
};
