import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  TextInput,
  Create,
  Filter,
  SimpleForm,
  BulkDeleteWithUndoButton,
  useRecordContext,
  required,
} from 'react-admin';
import { SortingDirection } from '../variables';
import { AdminDeleteButton } from '../buttons/delete-btn';
import { DefaultToolBar } from '../toolbars/default-toolbar';
import { getIsDisabledByRole } from '../utils/get-disabled-by-role';

const CategoryFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Name" source="q" alwaysOn />
  </Filter>
);

export const FundCategoriesList = (props) => (
  <List {...props} sort={{ field: 'id', order: SortingDirection.ASC }} perPage={50} filters={<CategoryFilter />}>
    <Datagrid bulkActionButtons={<BulkDeleteWithUndoButton {...props} disabled={getIsDisabledByRole()} />}>
      <TextField source="id" />
      <TextField source="name" />
      <EditButton />
      <AdminDeleteButton />
    </Datagrid>
  </List>
);

const FundCategoriesTitle = () => {
  const record = useRecordContext();
  return <span>Coin Category '{record?.name}'</span>;
};

const form = (
  <SimpleForm toolbar={<DefaultToolBar />}>
    <TextInput source="name" validate={required()} />
    <TextInput source="slug" validate={required()} />
  </SimpleForm>
);

export const FundCategoriesEdit = (props) => (
  <Edit title={<FundCategoriesTitle />} {...props}>
    {form}
  </Edit>
);

export const FundCategoriesCreate = (props) => <Create {...props}>{form}</Create>;
