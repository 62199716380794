import {
  List,
  Datagrid,
  TextField,
  EditButton,
  TextInput,
  BooleanInput,
  ReferenceInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ImageInput,
  ImageField,
  FunctionField,
  TopToolbar,
  ListButton,
  RefreshButton,
  Filter,
  SelectInput,
  FormDataConsumer,
  AutocompleteArrayInput,
  TabbedForm,
  Edit,
  useNotify,
  useRedirect,
  Button,
  Toolbar,
  Create,
  CreateButton,
  SimpleForm,
  BulkDeleteWithUndoButton,
  DateInput,
  useRecordContext,
  required,
} from 'react-admin';
import { getEnvs } from '../../env-lib';
import { NumberInput } from '../../inputs/number-input';
import { AutocompleteInput } from '../../inputs/autocomplete-input';
import { getRole } from '../../utils/get-role';
import { UserRole } from '../../variables/user-role';
import { imageInputAccept, SortingDirection } from '../../variables';
import { S3ImageDir } from '../../variables';
import { useState, useEffect } from 'react';
import { updateExchangePortfolio, getExchangePortfolio } from '../../api-service';
import { Fab, Input, Checkbox } from '@mui/material';
import { Add, RemoveCircle, Save } from '@mui/icons-material';
import styled from 'styled-components';
import { AdminSaveButton } from '../../buttons/save-btn';
import { getIsDisabledByRole } from '../../utils/get-disabled-by-role';
import { FormType } from '../../variables';
import { AppResourceNames } from '../../variables/app-resource-names';
import { capitalize } from '../../utils/string';
import { TrimOnBlurInput } from '../../inputs/trim-on-blur-input';
import { helperTexts } from '../../variables/helper-texts';

const WalletLine = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 5px 0;
`;

const ExchangeTitle = () => {
  const record = useRecordContext();
  return <span>Exchange '${record?.name}'</span>;
};

const imagesBasePath = getEnvs().REACT_APP_IMAGES_BASE_URL + S3ImageDir.EXCHANGES;

const notAdmin = getRole() !== UserRole.ADMIN;

export const exchangeGroup = ['main', 'dex', 'other', 'futures'];

const ExchangeFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Name" source="q" alwaysOn={true} />
  </Filter>
);

const EditActions = ({ data, resource }: any) => (
  <TopToolbar>
    <CreateButton />
    <ListButton />
    <RefreshButton />
  </TopToolbar>
);

const ExchangesPortfolioTab = () => {
  const redirect = useRedirect();
  const notify = useNotify();
  const record = useRecordContext();
  const isDisabledByRole = getIsDisabledByRole();
  const [exchangePortfolio, setExchangePortfolio] = useState<{
    wallets: string[];
    isTrackingEnabled: boolean;
    isShownOnCexTransparency: boolean;
  }>({ wallets: [], isTrackingEnabled: false, isShownOnCexTransparency: false });

  const [newWallet, setNewWallet] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const exchangeId = record.id as number;

  useEffect(() => {
    getExchangePortfolio({ exchangeId }).then((data) => setExchangePortfolio(data));
  }, [exchangeId]);

  const onRemoveItem = (wallet: string) => (e) => {
    const updatedWallets = [...exchangePortfolio.wallets].filter((_wallet) => _wallet !== wallet);
    setExchangePortfolio({ ...exchangePortfolio, wallets: updatedWallets });
  };

  const onChangeArrayInput = (wallet) => (event) => {
    const updatedWallets: string[] = [...exchangePortfolio.wallets];
    const foundWalletIndex = updatedWallets.findIndex((_wallet) => _wallet === wallet);
    updatedWallets[foundWalletIndex] = event.target.value;
    setExchangePortfolio({ ...exchangePortfolio, wallets: updatedWallets });
  };

  const onClickAddNew = () => {
    const updatedWallets: string[] = [...exchangePortfolio.wallets, newWallet];
    setExchangePortfolio({ ...exchangePortfolio, wallets: updatedWallets });
    setNewWallet('');
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (isLoading) {
      return;
    }

    setIsLoading(true);

    updateExchangePortfolio({ exchangeId, exchangePortfolio })
      .then(() => {
        redirect('list', AppResourceNames.EXCHANGES);
      })
      .catch((err) => {
        notify(err.status + ' ' + err.message, { type: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChange = (field) => (event) =>
    setExchangePortfolio({ ...exchangePortfolio, [field]: event.target.checked });

  return (
    <>
      <div>
        <span>Enable parsing</span>
        <Checkbox checked={exchangePortfolio.isTrackingEnabled} onChange={handleChange('isTrackingEnabled')} />
      </div>
      <br />
      <div>
        <span>Show exchange</span>
        <Checkbox
          checked={exchangePortfolio.isShownOnCexTransparency}
          onChange={handleChange('isShownOnCexTransparency')}
        />
      </div>
      <br />
      <div>
        <Input value={newWallet} onChange={(e) => setNewWallet(e.target.value)} />
        <Fab
          disabled={newWallet === ''}
          color="primary"
          size="small"
          onClick={onClickAddNew}
          style={{ marginLeft: 10 }}
        >
          <Add />
        </Fab>
      </div>
      {exchangePortfolio.wallets.map((_wallet, index) => (
        <WalletLine key={index}>
          <Input value={_wallet} onChange={onChangeArrayInput(_wallet)} />
          <Fab size="small" onClick={onRemoveItem(_wallet)} tabIndex={-1}>
            <RemoveCircle color="error" />
          </Fab>
        </WalletLine>
      ))}
      <br />
      <Button
        onClick={onSubmit}
        type="submit"
        color="primary"
        variant="contained"
        startIcon={<Save />}
        label="Save"
        style={{ marginBottom: 10, padding: '0px 10px' }}
        disabled={isLoading || isDisabledByRole}
      />
    </>
  );
};

const ExchangesToolbar = (props) => {
  const isSecondTab = Boolean(window.location.href.match(/\/1$/));

  if (isSecondTab) {
    return null;
  }

  const redirect = (basePath, id, data) => {
    if (props.type === FormType.EDIT) {
      return basePath;
    }

    const nextTabIndex = 1;

    return basePath + '/' + id + '/' + nextTabIndex;
  };

  return (
    <Toolbar {...props}>
      <AdminSaveButton {...props} mutationOptions redirect={redirect} disabled={props.pristine} />
    </Toolbar>
  );
};

const Form = (
  <SimpleForm>
    <TextInput source="key" validate={required()} helperText={helperTexts.keyInputValidation} />
    <TrimOnBlurInput source="name" validate={required()} />
    <TextInput source="url" validate={required()} />
    <TextInput source="tickerUrlTemplate" />
    <SelectInput source="group" choices={exchangeGroup.map((name) => ({ id: name, name: capitalize(name) }))} />
  </SimpleForm>
);

export const ExchangeCreate = (props) => {
  return <Create {...props}>{Form}</Create>;
};

export const ExchangeEdit = (props) => (
  <Edit actions={<EditActions />} title={<ExchangeTitle />} {...props}>
    <TabbedForm toolbar={<ExchangesToolbar type={props.type} />}>
      <TabbedForm.Tab label="summary">
        <TextInput disabled={true} source="id" />
        <TextInput disabled={true} source="key" validate={required()} />
        <TrimOnBlurInput source="name" validate={required()} />
        <TextInput source="url" validate={required()} />
        <TextInput source="tickerUrlTemplate" />
        <SelectInput source="group" choices={exchangeGroup.map((name) => ({ id: name, name: capitalize(name) }))} />
        <FormDataConsumer>
          {({ formData }) =>
            formData.group === 'dex' && (
              <ReferenceArrayInput
                source="platformIds"
                reference="token-platforms"
                sort={{ field: 'name', order: SortingDirection.ASC }}
              >
                <AutocompleteArrayInput optionText="name" label="Token Platforms" />
              </ReferenceArrayInput>
            )
          }
        </FormDataConsumer>
        <ReferenceInput
          label="Native Token"
          source="nativeCoinId"
          reference="coins"
          perPage={10}
          sort={{ field: 'id', order: SortingDirection.ASC }}
        >
          <AutocompleteInput optionText="fullName" />
        </ReferenceInput>
        <FunctionField
          label="Image"
          render={(record) =>
            record.imageName ? (
              <img src={imagesBasePath + record.imageName} alt="image" style={{ maxHeight: '170px' }} />
            ) : null
          }
        />
        <ImageInput source="image" label="Image" accept={imageInputAccept}>
          <ImageField source="src" title="Image" />
        </ImageInput>
        <p>Maximum size is 1MB</p>
        <BooleanInput source="enabled" />
        <BooleanInput source="isCalculatePrice" label="Participates In Calculation Of Prices" />
        <NumberInput source="foundationYear" label="Year Of Foundation" />
        <ReferenceInput label="Country" source="countryId" reference="countries" perPage={10}>
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceArrayInput
          source="featuresIds"
          reference="exchange-features"
          sort={{ field: 'group', order: SortingDirection.DESC }}
        >
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <TextInput multiline={true} label="About" source="description" />
        <TextInput source="refLink" label="Ref Link" disabled={notAdmin} />
        <TextInput source="refText" label="Ref Text" disabled={notAdmin} />
        <TextInput multiline={true} source="notification" />
        <TextInput multiline={true} label="Internal notes" source="notes" />
        <BooleanInput label="Promoted" source="isPromoted" />
        <TextInput multiline={true} label="Auditor name" source="auditorName" />
        <DateInput source="auditDate" label="Audit date" />
        <TextInput multiline={true} label="Link to audit" source="auditLink" />
        <TextInput multiline={true} label="Link to wallet" source="walletLink" />
      </TabbedForm.Tab>

      <TabbedForm.Tab label="Exchange Portfolio">
        <ExchangesPortfolioTab />
      </TabbedForm.Tab>

      <TabbedForm.Tab label="links">
        <ReferenceArrayInput source="link_ids" reference="links" sort={{ field: 'id', order: SortingDirection.DESC }}>
          <AutocompleteArrayInput filterToQuery={(q) => ({ q_value: q })} optionText="value" />
        </ReferenceArrayInput>
      </TabbedForm.Tab>
    </TabbedForm>
  </Edit>
);

export const ExchangeList = () => (
  <List sort={{ field: 'id', order: SortingDirection.ASC }} perPage={50} filters={<ExchangeFilter />}>
    <Datagrid bulkActionButtons={<BulkDeleteWithUndoButton disabled={getIsDisabledByRole()} />}>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="url" />
      <EditButton />
    </Datagrid>
  </List>
);
