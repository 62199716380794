export const linkTypes = [
  { id: 'referral', name: 'Referral link' },
  { id: 'web', name: 'Website' },
  { id: 'twitter', name: 'Twitter' },
  { id: 'reddit', name: 'Reddit' },
  { id: 'slack', name: 'Slack' },
  { id: 'discord', name: 'Discord' },
  { id: 'farcaster', name: 'Farcaster' },
  { id: 'blog', name: 'Blog' },
  { id: 'github', name: 'Github' },
  { id: 'gitlab', name: 'Gitlab' },
  { id: 'linkedin', name: 'Linkedin' },
  { id: 'facebook', name: 'Facebook' },
  { id: 'google+', name: 'Google+' },
  { id: 'explorer', name: 'Explorer' },
  { id: 'weibo', name: 'Weibo' },
  { id: 'chat', name: 'Chat' },
  { id: 'messageboard', name: 'Message Board' },
  { id: 'wechat', name: 'WeChat' },
  { id: 'telegram', name: 'Telegram' },
  { id: 'medium', name: 'Medium' },
  { id: 'announcement', name: 'Announcement' },
  { id: 'youtube', name: 'Youtube' },
  { id: 'fees', name: 'Fees' },
  { id: 'whitepaper', name: 'Whitepaper' },
  { id: 'gitbook', name: 'Gitbook' },
];
