import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  Create,
  TopToolbar,
  ListButton,
  RefreshButton,
  ImageField,
  ImageInput,
  DateField,
  BooleanInput,
  Toolbar,
  TabbedForm,
  FormTab,
  TextInput,
  BooleanField,
  TranslatableInputs,
  FunctionField,
  SelectInput,
  ArrayInput,
  DateTimeInput,
  Button,
  required,
  useRecordContext,
  useTranslatableContext,
} from 'react-admin';
import { NumberInput } from '../inputs/number-input';
import { imageInputAccept, SortingDirection } from '../variables';
import { linkTypes } from '../variables/link-types';
import { useEffect, useState } from 'react';
import { getLeaderboard, getAccrueRewards } from '../api-service';
import { DashboardTable } from '../dashboard/table';
import { Send, Stop } from '@mui/icons-material';
import { FormType } from '../variables';
import { TinymceEditor } from '../inputs/tinymce-editor';
import { AdminSaveButton } from '../buttons/save-btn';
import { AdminCreateBtn } from '../buttons/create-btn';
import { ArrayInputIterator } from '../inputs/array-input-iterator';

type LeaderboardData = {
  username: string;
  email: string;
  totalBalance: number | null;
};

const LeaderboardTab = (props) => {
  const record = useRecordContext();
  const recordId = record.id as number | undefined;
  const [leaderboard, setLeaderboard] = useState<LeaderboardData[]>([]);
  const [isWasSend, setIsWasSend] = useState(false);
  const endDate = new Date(record.endDate).getTime();
  const today = new Date().getTime();
  const enabled = record.gamificationEventId === null && today > endDate && leaderboard.length > 0;
  const [isEnabled, setIsEnabled] = useState(enabled);
  const buttonTitle = isEnabled ? 'Send Spaceships' : `Disabled`;

  useEffect(() => {
    if (recordId === undefined) {
      return;
    }

    getLeaderboard({ id: recordId }).then((data) => setLeaderboard(data));

    if (isWasSend && enabled) {
      setIsEnabled(false);
    }

    if (!isWasSend) {
      setIsEnabled(enabled);
    }
  }, [recordId, enabled, isEnabled, isWasSend]);

  const handleSendSpaceships = (e) => {
    e.preventDefault();

    if (recordId === undefined) {
      return;
    }

    if (enabled) {
      setIsWasSend(true);
      setIsEnabled(false);
    }

    try {
      getAccrueRewards({ id: recordId, rewards: props }).then((data) => data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <h4>Leaderboard info</h4>
      <div>На отправку Spaceships может потребоваться время, пожалуйста, ждите.</div>
      <Button
        label={buttonTitle}
        onClick={handleSendSpaceships}
        type="submit"
        color="primary"
        startIcon={isEnabled ? <Send /> : <Stop />}
        variant="contained"
        style={{ margin: '25px 0' }}
        disabled={!isEnabled}
      />
      {leaderboard.length ? (
        <DashboardTable>
          <thead>
            <tr>
              <th>Username</th>
              <th>Email</th>
              <th>Total Balance</th>
            </tr>
          </thead>
          <tbody>
            {(leaderboard || []).map(({ username, email, totalBalance }) => (
              <tr key={email}>
                <td>{username}</td>
                <td>{email}</td>
                <td>{totalBalance || 0}</td>
              </tr>
            ))}
          </tbody>
        </DashboardTable>
      ) : (
        <div>No info yet...</div>
      )}
    </>
  );
};

const BattleToolbar = (props) => {
  return (
    <Toolbar>
      <AdminSaveButton />
    </Toolbar>
  );
};

const Editor = () => {
  const { selectedLocale } = useTranslatableContext();

  return <TinymceEditor source={`conditions.${selectedLocale}`} />;
};

const Form = (props) => {
  return (
    <TabbedForm {...props} toolbar={<BattleToolbar type={props.type} />}>
      <FormTab label="battle">
        <BooleanInput source="enabled" label="Enabled" defaultValue={false} />
        <TextInput source="key" label="Key" validate={required()} />

        <TranslatableInputs locales={['en', 'ru']} defaultLocale="en" groupKey="conditions-fields">
          <TextInput source="name" />
          <Editor />
        </TranslatableInputs>

        <TranslatableInputs locales={['en', 'ru']} defaultLocale="en" groupKey="partnerPrize-fields">
          <TextInput source="partnerPrize" />
        </TranslatableInputs>
        <TranslatableInputs locales={['en', 'ru']} defaultLocale="en" groupKey="partnerPrizeTerms-fields">
          <TextInput source="partnerPrizeTerms" />
        </TranslatableInputs>
        <FunctionField
          label="Logo"
          render={(record) => record.logo && <img src={record.logo} alt="logo" style={{ maxHeight: '170px' }} />}
        />
        <ImageInput source="logo" label="Upload Logo" accept={imageInputAccept}>
          <ImageField source="logo" title="Logo" />
        </ImageInput>
        <hr />
        <FunctionField
          label="Button Logo"
          render={(record) =>
            record.buttonLogo && <img src={record.buttonLogo} alt="Button Logo" style={{ maxHeight: '70px' }} />
          }
        />
        <ImageInput source="buttonLogo" label="Upload Button Logo" accept={imageInputAccept}>
          <ImageField source="buttonLogo" title="Button Logo" />
        </ImageInput>
        <NumberInput source="prizeFund" label="Prize Fund" validate={required()} min={0} />
        <DateTimeInput label="Start Date" source="startDate" />
        <DateTimeInput label="End Date" source="endDate" />
        <TextInput source="predefinedBalance" label="Predefined Balance" validate={required()} />
        <hr />
        <ArrayInput source="links" label="Links" defaultValue={[]}>
          <ArrayInputIterator>
            <SelectInput source="type" label="Type" choices={linkTypes} />
            <TextInput source="value" label="Value" validate={required()} />
          </ArrayInputIterator>
        </ArrayInput>
        <hr />
        <ArrayInput source="prizeDistribution.places" label={'Prize Distribution. Places'}>
          <ArrayInputIterator>
            <NumberInput
              source="placemin"
              label="Place min"
              validate={required()}
              min={0}
              helperText={'Only number. Should be smaller than Place max'}
            />
            <NumberInput
              source="placemax"
              label="Place max"
              validate={required()}
              min={0}
              helperText={'Only number. Should be greater than Place min'}
            />
            <SelectInput
              source="type"
              label="Type"
              choices={[
                { id: 'usdt', name: 'usdt' },
                { id: 'spaceships', name: 'spaceships' },
              ]}
            />
            <NumberInput source="amount" label="Value" validate={required()} min={0} />
          </ArrayInputIterator>
        </ArrayInput>
      </FormTab>
      {props.type === FormType.EDIT && (
        <FormTab label="Leaderboard">
          <LeaderboardTab {...props} />
        </FormTab>
      )}
    </TabbedForm>
  );
};

export const BattleCreate = (props) => (
  <Create {...props}>
    <Form {...props} type={FormType.CREATE} />
  </Create>
);

const EditActions = () => {
  return (
    <TopToolbar>
      <AdminCreateBtn />
      <ListButton />
      <RefreshButton />
    </TopToolbar>
  );
};

export const BattleEdit = (props) => (
  <Edit actions={<EditActions />} {...props} mutationMode="pessimistic">
    <Form {...props} type={FormType.EDIT} />
  </Edit>
);

export const BattleList = (props) => {
  return (
    <List sort={{ field: 'createdAt', order: SortingDirection.DESC }} perPage={50} exporter={false}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="key" />
        <TextField source="name.en" label="Name (En)" />
        <ImageField
          source="logo"
          sx={{
            '& img': {
              height: 40,
              width: 40,
              objectFit: 'contain',
            },
          }}
        />
        <TextField source="prizeFund" />
        <TextField source="predefinedBalance" />
        <DateField source="startDate" showTime locales="ru" />
        <DateField source="endDate" showTime locales="ru" />
        <TextField source="participants" />
        <BooleanField source="enabled" />
        <EditButton />
      </Datagrid>
    </List>
  );
};
