/**
 * @description
 * Уровни пользователей.
 * В зависимости от уровня, пользователь имеет доступ к тому или иному контенту на сайте, а также разные лимиты
 *
 * Менять только по [таблице](https://docs.google.com/spreadsheets/d/1YMNEMTqXibY23OfHADg5c1650_NziuikpOGEVf_wuOs/edit#gid=1069172587)
 *
 * Не забываем править код в репозитории admin
 */
export enum UserLevel {
  /**
   * Базовый уровень, обычный пользователь, доступы по умолчанию
   */
  DEFAULT = 'default',
  /**
   * Профессиональный аккаунт, который имеет расширенный доступ к функциям и возможностям системы
   * Например, увеличенный лимит на количество портфолио и кошельков
   */
  PRO = 'pro',
  /**
   * Премиум-аккаунт, имеет максимальный доступ к функциям и возможностям системы
   * Например: максимальное количество портфолио, кошельков и алертов
   */
  PREMIUM = 'premium',
}

type TLimits = {
  /**
   * Максимальное количество портфолио
   */
  MAX_PORTFOLIOS: number;
  /**
   * Максимальное количество кошельков в портфолио
   */
  MAX_WALLETS_FOR_PORTFOLIO: number;
  /**
   * Максимальное количество кошельков
   */
  MAX_WALLETS_FOR_ACCOUNT: number;
  /**
   * Максимальное количество обрабатываемых транзакций на кошельке
   */
  MAX_TRANSACTIONS_FOR_WALLET: number;
};

/**
 * @description
 * Мапа уровней пользователей по лимитам.
 *
 * Менять только по [таблице](https://docs.google.com/spreadsheets/d/1YMNEMTqXibY23OfHADg5c1650_NziuikpOGEVf_wuOs/edit#gid=1069172587)
 */
export const UserLevelToLimit: Record<UserLevel, TLimits> = {
  [UserLevel.DEFAULT]: {
    MAX_PORTFOLIOS: 10,
    MAX_WALLETS_FOR_PORTFOLIO: 10,
    MAX_WALLETS_FOR_ACCOUNT: 40,
    MAX_TRANSACTIONS_FOR_WALLET: 3500,
  },
  [UserLevel.PRO]: {
    MAX_PORTFOLIOS: 20,
    MAX_WALLETS_FOR_PORTFOLIO: 20,
    MAX_WALLETS_FOR_ACCOUNT: 80,
    MAX_TRANSACTIONS_FOR_WALLET: 3500,
  },
  [UserLevel.PREMIUM]: {
    MAX_PORTFOLIOS: 1000,
    MAX_WALLETS_FOR_PORTFOLIO: 1000,
    MAX_WALLETS_FOR_ACCOUNT: 8000,
    MAX_TRANSACTIONS_FOR_WALLET: 3500,
  },
};
