import React, { useState } from 'react';
import styled from 'styled-components';
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  EditButton,
  FunctionField,
  Edit,
  SimpleForm,
  BooleanInput,
  ReferenceInput,
  Filter,
  TextInput,
  DateField,
  SelectInput,
  BulkDeleteWithUndoButton,
  useRecordContext,
  Labeled,
} from 'react-admin';
import { NewField } from '../fields/new-field';
import numeral from 'numeral';
import { differenceInDays, format, formatDistanceToNow } from 'date-fns';
import { Warning } from '@mui/icons-material';
import { exchangeGroup } from './exchanges/exchanges';
import { Button, Popover, Paper, Typography } from '@mui/material';
import { AutocompleteInput } from '../inputs/autocomplete-input';
import { SortingDirection } from '../variables';
import { DefaultToolBar } from '../toolbars/default-toolbar';
import { getIsDisabledByRole } from '../utils/get-disabled-by-role';
import { capitalize } from '../utils/string';
import { ToggleButton } from '../buttons/toggle-button';
import { NumberInput } from '../inputs/number-input';

const Title = () => {
  const record = useRecordContext();
  return <span>Ticker {record ? `${record.baseSymbol}/${record.quoteSymbol}` : ''}</span>;
};

const TickersFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Quote Symbol" source="quoteSymbol" alwaysOn={true} />
    <ReferenceInput
      label="Quote Coin"
      source="quoteCoinId"
      reference="coins"
      sort={{ field: 'id', order: SortingDirection.ASC }}
    >
      <AutocompleteInput optionText="fullName" />
    </ReferenceInput>
    <TextInput label="Base Symbol" source="baseSymbol" alwaysOn={true} />
    <ReferenceInput
      label="Base Coin"
      source="baseCoinId"
      reference="coins"
      sort={{ field: 'id', order: SortingDirection.ASC }}
    >
      <AutocompleteInput optionText="fullName" />
    </ReferenceInput>
    <ReferenceInput
      label="Exchange"
      source="exchangeId"
      reference="exchanges"
      alwaysOn={true}
      sort={{ field: 'id', order: SortingDirection.ASC }}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <SelectInput
      source="category"
      label="Category"
      alwaysOn={true}
      choices={['derivative', 'spot', 'option'].map((name) => ({ id: name, name: capitalize(name) }))}
    />
    <SelectInput
      source="exchangeGroup"
      label="Group"
      defaultValue="main"
      choices={exchangeGroup.map((name) => ({ id: name, name: capitalize(name) }))}
    />
    <TextInput label="Base Address" source="baseAddress" alwaysOn={true} />
    <TextInput label="Quote Address" source="quoteAddress" alwaysOn={true} />
    <BooleanInput label="Enabled" source="enabled" />
    <BooleanInput source="isNew" label="New" />
    <BooleanInput source="mirrored" />
    <BooleanInput source="useForPrices" label="Price Calculation" />
    <BooleanInput source="actual" label="Actual" alwaysOn={true} />
    <BooleanInput source="isBlocked" label="Blocked" />
    <BooleanInput source="highVolumeUsd" label="More 50k $ Volume (24h)" />
    <BooleanInput source="bothCoinsAvailable" label="Both Coins Available" />
    <NumberInput source='id' label="Ticker id" />
    <TextInput source='baseAndQuoteCoin' label="Base and Quote Search" />
  </Filter>
);

export const TickerEdit = (props) => (
  <Edit title={<Title />} {...props}>
    <SimpleForm toolbar={<DefaultToolBar />}>
      <TextInput disabled={true} source="id" />
      <ReferenceField label="Exchange" source="exchangeId" reference="exchanges">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceInput
        label="Base Coin"
        source="baseCoinId"
        reference="coins"
        perPage={30}
        allowEmpty
        sort={{ field: 'id', order: SortingDirection.ASC }}
      >
        <AutocompleteInput optionText="fullName" />
      </ReferenceInput>
      <ReferenceInput
        label="Quote Coin"
        source="quoteCoinId"
        reference="coins"
        perPage={10}
        allowEmpty
        sort={{ field: 'id', order: SortingDirection.ASC }}
      >
        <AutocompleteInput optionText="fullName" />
      </ReferenceInput>
      <BooleanInput source="enabled" />
      <BooleanInput label="Mirrored (use ticker for quote currency)" source="mirrored" />
      <BooleanInput label="Blocked (data protection)" source="isBlocked" />
      <BooleanInput label="Use for prices calculation" source="useForPrices" />
      <BooleanInput label="Use for volumes calculation" source="useForVolumes" />
      <BooleanInput label="Use for exchange volumes calculation" source="useForExchangeVolumes" />
      <BooleanInput label="Parse candlestick history and show TV charts" source="writeHistory" />
      <Labeled>
        <DateField source="createdAt" showTime={true} />
      </Labeled>
      <Labeled label="Last Parsed">
        <DateField source="updatedAt" showTime={true} />
      </Labeled>
    </SimpleForm>
  </Edit>
);

const PriceColumn = (ticker) => {
  const { priceUsd: tickerPrice, volumeUsd, baseCoin } = ticker;
  if (!tickerPrice) {
    return null;
  }
  const coinPrice = baseCoin ? baseCoin.priceUSD : undefined;
  const percentFromCoin = coinPrice ? ((tickerPrice - coinPrice) / coinPrice) * 100 : null;
  const hasBigDiffWithCoinPrice = percentFromCoin ? Math.abs(percentFromCoin) > 15 && volumeUsd > 100 : false;

  return (
    <Typography
      color={hasBigDiffWithCoinPrice ? 'Red' : 'InfoText'}
      variant="body2"
      title={
        percentFromCoin
          ? [
              `Coin = $${(+coinPrice).toFixed(6)}`,
              `Ticker = $${(+tickerPrice).toFixed(6)}`,
              `Diff = ${percentFromCoin.toFixed(3)}%`,
            ].join('\n')
          : ''
      }
    >
      {hasBigDiffWithCoinPrice && <Warning color="error" style={{ fontSize: 18, verticalAlign: 'bottom' }} />}$
      {tickerPrice.toFixed(3)}
      {percentFromCoin && <span style={{ opacity: 0.5 }}> ({percentFromCoin.toFixed(2)}%)</span>}
    </Typography>
  );
};

const UpdatedAtField = ({ source }: { label: string; source: string }) => {
  const record = useRecordContext();
  const date = record[source];
  const { futureExpiredDate } = record;

  const updatedTime = new Date(date);

  return (
    <Typography variant="body2" color={differenceInDays(Date.now(), updatedTime) > 1 ? 'GrayText' : 'InfoText'}>
      <div title={date}>{formatDistanceToNow(updatedTime, { addSuffix: true })}</div>
      {futureExpiredDate ? (
        <div style={{ fontSize: '10px' }}>Exp. {format(new Date(futureExpiredDate), 'dd-MM-yyyy')}</div>
      ) : null}
    </Typography>
  );
};

const StyledButton = styled(Button)`
  padding: 3px 5px !important;
  font-size: 12px !important;
`;

const ContainerAddresses = styled.div`
  font-size: 10px;
  line-height: 1.1;
`;

const Addresses = ({ ticker }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  if (!(ticker.externalId || ticker.baseAddress || ticker.quoteAddress)) {
    return null;
  }

  return (
    <ContainerAddresses>
      <StyledButton aria-describedby={id} variant="contained" onClick={handleClick}>
        addresses
      </StyledButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Paper sx={{ p: 2 }}>
          <Typography variant="subtitle1">
            <b>Pair ID</b>: <Typography variant="caption">{ticker.externalId || '-'}</Typography>
          </Typography>
          <Typography variant="subtitle1">
            <b>Base address</b>: <Typography variant="caption">{ticker.baseAddress || '-'}</Typography>
          </Typography>
          <Typography variant="subtitle1">
            <b>Quote address</b>: <Typography variant="caption">{ticker.quoteAddress || '-'}</Typography>
          </Typography>
        </Paper>
      </Popover>
    </ContainerAddresses>
  );
};

export const TickerList = (props) => {
  function formatNumber(number) {
    if (isNaN(number)) {
      return null;
    }

    if (number >= 1000) {
      return numeral(number).format('0.00a');
    }

    return numeral(number).format('0.00');
  }

  return (
    <List
      {...props}
      filters={<TickersFilter />}
      perPage={25}
      sort={{ field: 'createdAt', order: 'DESC' }}
      filterDefaultValues={{ category: 'spot', actual: true }}
    >
      <Datagrid bulkActionButtons={<BulkDeleteWithUndoButton {...props} disabled={getIsDisabledByRole()} />}>
        <ToggleButton source="enabled" />
        <NewField source="isNew" label="New" />
        <TextField source="id" />
        <ReferenceField label="Exchange" source="exchangeId" reference="exchanges">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Mapping" source="mapping.id" reference="coin-mappings" sortable={false}>
          <TextField source="id" />
        </ReferenceField>
        <FunctionField
          label="Symbol"
          render={(ticker) => {
            const { baseSymbol, quoteSymbol, urlOnExchange } = ticker;
            return (
              <div>
                <div>
                  <a rel="nofollow noopener noreferrer" target="_blank" href={urlOnExchange}>
                    {baseSymbol}/{quoteSymbol}
                  </a>
                </div>
                {ticker && <Addresses ticker={ticker} />}
              </div>
            );
          }}
        />
        <FunctionField label="Price" sortBy="priceDifference" render={PriceColumn} />
        <FunctionField
          label="Volume"
          sortBy="volumeUsd"
          render={(ticker) => {
            const volumeUsd = formatNumber(Number(ticker.volumeUsd));
            const volume = formatNumber(Number(ticker.volume));

            return (
              <>
                {typeof volumeUsd === 'string' && (
                  <>
                    <span>${volumeUsd}</span>
                    <br />
                  </>
                )}
                {typeof volume === 'string' && (
                  <span style={{ whiteSpace: 'nowrap' }}>
                    {volume} {ticker.baseSymbol}
                  </span>
                )}
              </>
            );
          }}
        />
        <ReferenceField label="Base Coin" source="baseCoinId" reference="coins">
          <FunctionField
            render={(ticker) => {
              const { name, priceUSD, priceCalculatedAt } = ticker;
              return (
                <span>
                  {name}
                  {priceUSD ? '' : <b> NEW</b>}
                  {priceUSD && differenceInDays(Date.now(), priceCalculatedAt) > 1 ? <b> NO DATA</b> : ''}
                </span>
              );
            }}
          />
        </ReferenceField>
        <ReferenceField label="Quote Coin" source="quoteCoinId" reference="coins">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="createdAt" />
        <UpdatedAtField label="Last Parsed" source="updatedAt" />
        <EditButton />
      </Datagrid>
    </List>
  );
};
