import {
  BulkDeleteWithUndoButton,
  Datagrid,
  Filter,
  FunctionField,
  List,
  minValue,
  Show,
  ShowButton,
  SimpleShowLayout,
  TextField,
  TextInput,
} from 'react-admin';
import { SortingDirection } from '../variables';
import { getIsDisabledByRole } from '../utils/get-disabled-by-role';
import { NumberInput } from '../inputs/number-input';

const AdminLogFilter = (props) => (
  <Filter {...props}>
    <TextInput style={{ height: 58 }} source="entity_name" name="entity_name" label="Entity Name" alwaysOn={true} />
    <NumberInput style={{ height: 58 }} source="entity_id" name="entity_id" label="Entity ID" validate={minValue(0)} alwaysOn={true} />
    <NumberInput style={{ height: 58 }} source="userId" name="userId" label="User ID" alwaysOn={true} />
    <TextInput style={{ height: 58 }} source="entity_name" name="entity_name" label="Entity Name" alwaysOn={true} />
  </Filter>
);

export const AdminLogList = (props) => (
  <List {...props} sort={{ field: 'id', order: SortingDirection.ASC }} perPage={50} filters={<AdminLogFilter />}>
    <Datagrid bulkActionButtons={<BulkDeleteWithUndoButton {...props} disabled={getIsDisabledByRole()} />}>
      <TextField source="id" />
      <TextField source="time" />
      <TextField source="entity_id" />
      <TextField source="entity_name" />
      <TextField source="email" />
      <ShowButton />
    </Datagrid>
  </List>
);

export const AdminLogShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="time" />
      <TextField source="entity_id" />
      <TextField source="entity_name" />
      <TextField source="email" />
      <FunctionField label="Changed" source="changed" render={(data) => JSON.stringify(data.changed)} />
    </SimpleShowLayout>
  </Show>
);
