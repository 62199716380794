import styled from 'styled-components';

const Table = styled.table`
  border-spacing: 0;
  empty-cells: hide;

  td {
    padding: 10px 20px;
    text-align: center;
    border-bottom: 1px solid #f4eee8;
    transition: all 0.1s ease;
  }

  th:first-child,
  td:first-child {
    min-width: 225px;
    text-align: left;
    color: #3d3511;
    font-weight: bold;
  }

  th {
    padding: 10px 20px;
    color: #3d3511;
    border-bottom: 1px solid #f4eee8;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  td:nth-child(even) {
    background-color: #e5e5e5;
  }

  td:nth-child(odd) {
    background-color: whitesmoke;
  }

  th:nth-child(even) {
    background-color: #e5e5e5;
  }

  th:nth-child(odd) {
    background-color: whitesmoke;
  }

  tbody > tr:first-child > td:first-child {
    border-top-left-radius: 5px;
  }

  tbody > tr:last-child > td:first-child {
    border-bottom-left-radius: 5px;
  }

  tr:hover td {
    background: #7aa8d6;
    color: #fff;
  }
`;

export const DashboardTable = ({ children }) => <Table>{children}</Table>;
