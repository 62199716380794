import { TabbedForm, ReferenceArrayInput, AutocompleteArrayInput } from 'react-admin';
import { SortingDirection } from '../../variables';

export const LinksTab = (props) => {
  return (
    <TabbedForm.Tab label="Links" {...props}>
      <ReferenceArrayInput source="linkIds" reference="links" sort={{ field: 'id', order: SortingDirection.DESC }}>
        <AutocompleteArrayInput filterToQuery={(q) => ({ q_value: q })} optionText="value" />
      </ReferenceArrayInput>
    </TabbedForm.Tab>
  );
};
