
enum IdoPlatformShield {
  HOT = 'hot',
  NEW = 'new',
  TRENDING = 'trending',
};

export const IdoPlatformShieldMap = {
  [IdoPlatformShield.HOT]: 'Hot',
  [IdoPlatformShield.TRENDING]: 'Hot + Trending',
  [IdoPlatformShield.NEW]: 'New',
};