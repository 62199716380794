import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  Show,
  SimpleShowLayout,
  DateField,
  Filter,
  TextInput,
  NumberField,
  ReferenceField,
  BulkDeleteWithUndoButton,
  useRecordContext,
} from 'react-admin';
import { OpenInNew } from '@mui/icons-material';
import { FRONTEND_URL, SortingDirection } from '../variables';
import { getIsDisabledByRole } from '../utils/get-disabled-by-role';

const CmcCoinFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput source="name" label="Name" alwaysOn />
      <TextInput source="slug" label="Slug" alwaysOn />
    </Filter>
  );
};

export const CmcCoinList = (props) => {
  return (
    <List
      {...props}
      sort={{ field: 'market_cap', order: SortingDirection.DESC }}
      perPage={50}
      filters={<CmcCoinFilter />}
    >
      <Datagrid bulkActionButtons={<BulkDeleteWithUndoButton {...props} disabled={getIsDisabledByRole()} />}>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="symbol" />
        <DateField source="updatedAt" showTime label="Last Parsing" />
        <DateField source="createdAt" showTime label="First Parsing" />
        <DateField source="last_updated" showTime label="CMC Last Updated" />
        <DateField source="date_added" showTime label="CMC Date Added" />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

const CmcCoinTitle: any = () => {
  const record = useRecordContext();

  if (!record) {
    return null;
  }

  return (
    <span>
      CoinMarketCap Coin &nbsp;
      {record.cryptorankCoin && (
        <a
          rel="nofollow noopener noreferrer"
          target="_blank"
          href={`${FRONTEND_URL}/${record.cryptorankCoin.isIco ? 'ico' : 'price'}/${record.cryptorankCoin.key}`}
        >
          <OpenInNew />
        </a>
      )}
      <a
        rel="nofollow noopener noreferrer"
        target="_blank"
        href={`https://coinmarketcap.com/currencies/${record.slug}`}
        style={{
          verticalAlign: 'super',
          fontSize: '10px',
          textDecoration: 'none',
          border: '2px solid',
          borderRadius: '2px',
          display: 'inline-block',
          padding: '2px',
          lineHeight: '1',
          marginLeft: '8px',
        }}
      >
        CMC
      </a>
      {record.cryptorankCoin?.coinGeckoCoinId && (
        <a
          rel="nofollow noopener noreferrer"
          target="_blank"
          href={`https://www.coingecko.com/en/coins/${record.cryptorankCoin.coinGeckoCoinId}`}
          style={{
            verticalAlign: 'super',
            fontSize: '10px',
            textDecoration: 'none',
            border: '2px solid',
            borderRadius: '2px',
            display: 'inline-block',
            padding: '2px',
            lineHeight: '1',
            marginLeft: '8px',
          }}
        >
          CGK
        </a>
      )}
    </span>
  );
};

export const CmcCoinShow = (props) => {
  return (
    <Show {...props} title={<CmcCoinTitle />}>
      <SimpleShowLayout>
        <NumberField source="id" label="ID" />
        <TextField source="symbol" />
        <TextField source="name" />
        <TextField source="slug" />
        <ReferenceField label="Bound Cryptorank Coin" source="cryptorankCoin.id" reference="coins" resource="coins">
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="num_market_pairs" label="Количество тикеров на CMC" />
        <NumberField source="circulating_supply" label="Circulating Supply" />
        <NumberField source="max_supply" label="Max Supply" />
        <NumberField source="total_supply" label="Total Supply" />
        <NumberField source="cmc_rank" label="Rank монеты на CMC" />
        <DateField source="date_added" showTime label="Дата, когда монета была добавлена на CMC" />
        <DateField source="last_updated" showTime label="Дата, когда CMC последний раз обновляли монету " />
        <NumberField source="priceUSD" label="Цена в USD" />
        <NumberField source="volume24h" label="Объем за 24 часа в USD" />
        <NumberField source="volume_change_24h" label="Изменение объема за 24 часа в процентах" />
        <NumberField source="market_cap" label="Market Cap: (Price USD * Circulating)" />
        <NumberField source="fully_diluted_market_cap" label="Fully Diluted Market Cap" />
        <NumberField source="market_cap_dominance" label="Market Cap Dominance" />
        <DateField source="updatedAt" showTime label="Дата, когда мы последний раз парсили данные" />
        <DateField source="createdAt" showTime label="Дата, когда мы первый раз спарсили монету" />
      </SimpleShowLayout>
    </Show>
  );
};
