import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  Filter,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  BulkDeleteWithUndoButton,
} from 'react-admin';
import { EnabledField } from '../fields/enabled-field';
import { SortingDirection } from '../variables';
import { coinTypes } from '../variables/coin-types';
import { AdminDeleteButton } from '../buttons/delete-btn';
import { getIsDisabledByRole } from '../utils/get-disabled-by-role';

const IcoFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Name or Symbol" source="q" alwaysOn />
    <TextInput label="Symbol (strict)" source="symbol" alwaysOn />
    <SelectInput source="type" choices={coinTypes} />
    <ReferenceInput
      label="Coin Category"
      source="categoryId"
      reference="coin-category"
      allowEmpty
      perPage={100}
      sort={{ field: 'name', order: SortingDirection.ASC }}
    >
      <SelectInput optionText="name" optionValue="id" />
    </ReferenceInput>
    <BooleanInput defaultValue label="Coingecko Updates" source="updateSupplyFromExternalAPI" />
    <BooleanInput defaultValue label="Sponsored ICO" source="isSponsored" />
    <BooleanInput defaultValue label="Without image" source="withoutImage" />
    <BooleanInput defaultValue label="Has notification" source="hasNotification" />
    <BooleanInput defaultValue label="Inactive" source="inactive" />
  </Filter>
);

export const IcoList = (props) => (
  <List {...props} sort={{ field: 'id', order: SortingDirection.ASC }} perPage={50} filters={<IcoFilter />}>
    <Datagrid bulkActionButtons={<BulkDeleteWithUndoButton disabled={getIsDisabledByRole()} />}>
      <EnabledField source="enabled" label="On" />
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="symbol" label="Symbol" />
      <DateField source="icoEndDate" label="ICO End Date" locales="ru" emptyText="Not Set" sortable={false} />
      <DateField source="createdAt" label="Created At" showTime locales="ru" />
      <EditButton />
      <AdminDeleteButton />
    </Datagrid>
  </List>
);
