export const shortDescriptionMaxLength = 150;

export const tokenStandards = {
  Ethereum: ['ERC-20', 'ERC-223', 'ERC-721', 'ERC-827'],
  NEO: ['NEP-5'],
  TRON: ['TRX-10', 'TRC-20'],
  BSC: ['BEP-20'],
  SOLANA: ['SPL'],
};

export const icoScreenshotsPath = 'content.icoData.screenshots';
