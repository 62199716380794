import * as React from 'react';
import { Filter, TextInput } from 'react-admin';

export const ImageHubFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput source="image_name" label="Name" alwaysOn />
    </Filter>
  );
};

// TODO: Не удалось реализовать фильтрацию по пользователям. Оставлю код, на котором остановился

// <ReferenceInput
//   label="User"
//   source="uploader_id"
//   reference="users"
//   resource="users"
//   alwaysOn={false}
//   sort={{ field: 'id', order: SortingDirection.ASC }}
//   filter={{ role: [UserRole.MANAGER, UserRole.DEVELOPER, UserRole.SUPER_MANAGER, UserRole.ADMIN, UserRole.ACCOUNT_MANAGER] }}
// >
//   <AutocompleteInput optionText="email" />
// </ReferenceInput>
