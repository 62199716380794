import { useNotify } from 'react-admin';
import { useState, useEffect, useMemo, ChangeEvent } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
  SelectChangeEvent,
} from '@mui/material';
import { postCoinAllocation } from '../../../api-service';
import { Delete, Send, ComputerSharp } from '@mui/icons-material';
import { BatchList } from './batch-list';
import { ColumnContainer, RowContainer, StyledAllocationForm } from '../styled';
import {
  CoinAllocationUnlockType,
  CoinAllocationFrequency,
  ICoinAllocation,
  allocationTitleArray,
  CoinAllocationBatch,
  initCoinAllocationBatch,
  formatDate,
  changeTokenPercentAmount,
  addToAllocationDate,
  getFrequencyValueInDays,
  addToDate,
  Cliff,
  initialCliffData,
  calculateEndDateOfVesting,
  getInitialTgeUnlockPercent,
  CoinAllocationGroup,
} from './utils';
import { TextFieldNumber } from '../../../inputs/textfield-number';

type Props = {
  allocation: ICoinAllocation;
  coinId: number;
  index: number;
  handleDeleteAllocation: (allocationId: number, index: number) => Promise<void>;
  totalSupply: number;
  tgeData: string;
  totalStartDate: string;
};

export const AllocationForm = (props: Props) => {
  const { allocation, coinId, index, handleDeleteAllocation, totalSupply, tgeData, totalStartDate } = props;

  const allocationId = allocation.id;
  const notify = useNotify();
  const [isLoading, setIsLoading] = useState(false);
  const [allocationForm, setAllocationForm] = useState<ICoinAllocation>(allocation);
  const [cliff, setCliff] = useState<Cliff>(initialCliffData);
  const [batchesArray, setBatchesArray] = useState<CoinAllocationBatch[]>(allocation.batches);
  const [percentForEachBatch, setPercentForEachBatch] = useState(0);
  const [batchesQty, setBatchesQty] = useState(0);
  const [linearCalculation, setLinearCalculation] = useState(false);
  const [tgeUnlockPercent, setTgeUnlockPercent] = useState<number | null>(
    getInitialTgeUnlockPercent(allocationForm.batches)
  );

  const startDate = useMemo(
    () => (allocationForm?.round_date ? new Date(allocationForm?.round_date) : new Date()),
    [allocationForm?.round_date]
  );

  const hasCliff = formatDate(tgeData, true) !== formatDate(allocationForm?.round_date, true);
  const hasTgeUnlock = typeof tgeUnlockPercent === 'number';
  const isZeroBatch = hasTgeUnlock || linearCalculation;
  const zeroBatchUnlockPercent = isZeroBatch ? tgeUnlockPercent ?? percentForEachBatch : 0;
  const zeroBatchDate = linearCalculation || hasTgeUnlock ? tgeData : startDate.toString();

  const endDate = calculateEndDateOfVesting(
    startDate,
    allocationForm.vesting_duration_type || CoinAllocationFrequency.DAY,
    allocationForm.vesting_duration_value || 0
  );

  const batchesTotalPercent =
    batchesArray.length > 0
      ? batchesArray
          .map((batch) => batch.unlock_percent)
          .reduce((curr, acc) => Number(curr) + Number(acc), 0)
          .toFixed(2)
      : 0;

  // Создаем массив батчей с датами при линейном вестинге
  const calculateBatchList = (percentForEachBatch, batchesQty, allocationForm) => {
    const {
      round_date,
      unlock_frequency_type = CoinAllocationFrequency.DAY,
      unlock_frequency_value,
      vesting_duration_value,
    } = allocationForm;

    if (!round_date || !unlock_frequency_value || !vesting_duration_value) {
      notify('Пожалуйста, заполните все необходимые поля');
      return;
    }

    const allBatches: {
      date: string;
      unlock_percent: any;
      is_tge: boolean;
    }[] = [];

    const initialDate = new Date(round_date);

    // Дата от которой начинаем рассчитывать периодичность
    let frequencyStartDate = initialDate;

    if (isZeroBatch) {
      allBatches.push({ date: zeroBatchDate, unlock_percent: zeroBatchUnlockPercent, is_tge: true });

      if (formatDate(zeroBatchDate, true) === formatDate(initialDate, true)) {
        frequencyStartDate = addToAllocationDate(
          frequencyStartDate,
          unlock_frequency_type,
          Number(unlock_frequency_value),
          initialDate
        );
      }
    }

    // Создаём массив батчей и добавляем им дату
    const calculatedBatches = Array.from({ length: batchesQty }, () => {
      const batchDate = formatDate(frequencyStartDate, true);
      const batchEndDate = addToAllocationDate(
        frequencyStartDate,
        unlock_frequency_type,
        Number(unlock_frequency_value),
        initialDate
      );

      frequencyStartDate = batchEndDate;
      return { date: batchDate, unlock_percent: percentForEachBatch, is_tge: false };
    });

    allBatches.push(...calculatedBatches);
    setBatchesArray(allBatches);
    setAllocationForm({ ...allocationForm, batches: allBatches });
  };

  useEffect(() => {
    // Считаем процент выплат для каждого батча
    const totalBatches = isZeroBatch ? batchesQty + 1 : batchesQty;
    setPercentForEachBatch(
      linearCalculation
        ? parseFloat((100 / totalBatches).toFixed(3))
        : parseFloat(((100 - zeroBatchUnlockPercent) / batchesQty).toFixed(3))
    );

    // Считаем количество батчей
    const oneDay = 24 * 60 * 60 * 1000; // один день в миллисекундах

    const daysBetween = Math.round((endDate.getTime() - startDate.getTime()) / oneDay);
    const frequencyValueInDays = getFrequencyValueInDays(
      allocationForm.unlock_frequency_type || CoinAllocationFrequency.DAY,
      allocationForm.unlock_frequency_value || 0
    );

    let result = Math.round(daysBetween / frequencyValueInDays);

    if (hasCliff) {
      result += 1;
    }

    setBatchesQty(result);
  }, [
    allocationForm.round_date,
    allocationForm.unlock_frequency_type,
    allocationForm.unlock_frequency_value,
    tgeData,
    linearCalculation,
    endDate,
    startDate,
    batchesQty,
    isZeroBatch,
    zeroBatchUnlockPercent,
    hasCliff,
  ]);

  const handleChangeTextField = (e, isNumberValue: boolean = false) => {
    const { name, value } = e.target;
    setAllocationForm({
      ...allocationForm,
      [name]: isNumberValue ? Number(value) : value,
    });
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    const name = event.target.name as keyof ICoinAllocation;
    const value = event.target.value as ICoinAllocation[keyof ICoinAllocation];

    if (value === CoinAllocationUnlockType.NONLINEAR) {
      setAllocationForm((prevFormData) => ({
        ...prevFormData,
        [name]: value,
        round_date: null,
        unlock_frequency_type: null,
        unlock_frequency_value: null,
        vesting_duration_type: null,
        vesting_duration_value: null,
        vesting_cliff_type: null,
        vesting_cliff_value: null,
      }));
      return;
    } else if (value === '') {
      setAllocationForm((prevFormData) => ({ ...prevFormData, [name]: null }));
      return;
    } else {
      setAllocationForm((prevFormData) => ({ ...prevFormData, [name]: value }));
    }
  };

  const handleAddBatch = () => setBatchesArray((prevState) => [...prevState, initCoinAllocationBatch]);

  const handleDeleteBatch = (index: number) => {
    setBatchesArray((prevState) => prevState.filter((_, i) => i !== index));
    setAllocationForm((prevFormDataAllocation) => ({
      ...prevFormDataAllocation,
      batches: prevFormDataAllocation.batches.filter((_, i) => i !== index),
    }));
  };

  const handleChangeBatch = (index: number, values: Partial<Record<keyof CoinAllocationBatch, any>>) => {
    const updatedBatches = [...batchesArray];

    updatedBatches[index] = { ...updatedBatches[index], ...values };
    console.log(updatedBatches);

    setBatchesArray(updatedBatches);

    setAllocationForm((prevFormData) => ({ ...prevFormData, batches: updatedBatches }));
  };

  const handleTgeUnlockChange = (event) => {
    const newValue = Number(event.target.value);
    setTgeUnlockPercent(newValue);
    setLinearCalculation(false);
  };

  const handleLinearCalcChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    setLinearCalculation(newValue);
    setTgeUnlockPercent(newValue === true ? null : 0);
  };

  const handleToggleCliffModal = (isOpen: boolean) => {
    if (!totalStartDate) {
      notify('Поле "Total Start Date" не заполнено', { type: 'error' });
      return;
    }
    setCliff({ ...cliff, isOpen });
  };

  const handleApplyCliff = () => {
    const round_date = addToDate(new Date(totalStartDate), cliff.duration_type, cliff.duration_value);
    setAllocationForm((prevFormData) => ({ ...prevFormData, round_date }));
    handleToggleCliffModal(false);
  };

  const handleCliffTypeChange = ({ target: { value } }) => {
    const duration_type = value;
    setCliff((prevCliff) => ({ ...prevCliff, duration_type }));
  };

  const handleCliffValueChange = ({ target: { value } }) => {
    const duration_value = Number(value);
    setCliff((prevCliff) => ({ ...prevCliff, duration_value }));
  };

  const postAllocationForm = (formData, e) => {
    e.preventDefault();

    if (
      new Date(allocationForm?.round_date || 0).getTime() === 0 &&
      allocationForm.unlock_type === CoinAllocationUnlockType.LINEAR
    ) {
      notify('Пожалуйста, заполните Start Date');
      return;
    }

    setIsLoading(true);
    try {
      postCoinAllocation({ coinId, formData });
      notify('Allocation has been sent');
    } catch (error: any) {
      notify(error.status + ' ' + error.message, { type: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const startDateValue =
    allocationForm.round_date && new Date(allocationForm?.round_date).getTime() !== 0
      ? formatDate(allocationForm.round_date, true)
      : null;

  return (
    <StyledAllocationForm>
      <h3>Allocation: {index}</h3>
      <ColumnContainer>
        <FormControl fullWidth margin="normal">
          <InputLabel id="name">Name</InputLabel>
          <Select labelId="name" name="name" value={allocationForm.name} onChange={handleSelectChange}>
            {allocationTitleArray.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel>Group</InputLabel>
          <Select name="group" value={allocationForm.group ?? ''} onChange={handleSelectChange} required>
            <MenuItem value={''}>-</MenuItem>
            {Object.values(CoinAllocationGroup).map((groupName) => (
              <MenuItem value={groupName} key={groupName}>
                {groupName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          name="name"
          label="Alter Name"
          value={allocationForm.name}
          onChange={handleChangeTextField}
          margin="normal"
          required
        />
        <TextFieldNumber
          name="tokens_percent"
          value={allocationForm.tokens_percent}
          label="Tokens percent %"
          onChange={(e) => changeTokenPercentAmount(e, setAllocationForm, totalSupply)}
          margin="normal"
          InputProps={{ inputProps: { type: 'number', min: 0, max: 100 } }}
          required
        />
        <TextFieldNumber
          name="tokens"
          value={Math.round(allocationForm.tokens || 0)}
          label="Tokens Amount"
          onChange={(e) => changeTokenPercentAmount(e, setAllocationForm, totalSupply)}
          margin="normal"
          required
        />
        <h2>Vesting</h2>
        <FormControl fullWidth margin="normal">
          <InputLabel>Type of unlock</InputLabel>
          <Select name="unlock_type" value={allocationForm.unlock_type ?? ''} onChange={handleSelectChange} required>
            <MenuItem value={''}>-</MenuItem>
            <MenuItem value={CoinAllocationUnlockType.LINEAR}>Linear</MenuItem>
            <MenuItem value={CoinAllocationUnlockType.NONLINEAR}>Non Linear</MenuItem>
          </Select>
        </FormControl>
        {allocationForm.unlock_type === CoinAllocationUnlockType.NONLINEAR && (
          <>
            <h3>Batches list (total percent - {batchesTotalPercent}%)</h3>
            <BatchList
              batchesArray={batchesArray}
              changeBatch={handleChangeBatch}
              deleteBatch={handleDeleteBatch}
              addBatch={handleAddBatch}
              unlockType={allocationForm.unlock_type}
              tokens={allocationForm.tokens}
              tgeDate={tgeData}
            />
          </>
        )}

        {allocationForm.unlock_type === CoinAllocationUnlockType.LINEAR && (
          <>
            <label htmlFor="round_date">Start Date (начало линейного разлока у раунда):*</label>
            <RowContainer>
              <TextField
                id="round_date"
                name="round_date"
                type="date"
                margin="normal"
                value={startDateValue}
                onChange={handleChangeTextField}
                required
              />
              <Button variant="outlined" color="primary" onClick={() => handleToggleCliffModal(true)}>
                Set cliff of total start date
              </Button>
              <Dialog open={cliff.isOpen}>
                <DialogTitle>Cliff</DialogTitle>
                <DialogContent>
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="vesting_cliff_type">Type</InputLabel>
                    <Select labelId="cliff_duration_type" value={cliff.duration_type} onChange={handleCliffTypeChange}>
                      {Object.values(CoinAllocationFrequency).map((duration) => (
                        <MenuItem key={duration} value={duration}>
                          {duration}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextFieldNumber
                    label="Value"
                    value={cliff.duration_value}
                    onChange={handleCliffValueChange}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ inputProps: { min: 0 } }}
                    autoFocus={true}
                    onFocus={(e) => e.target.select()}
                    required={true}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => handleToggleCliffModal(false)} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={handleApplyCliff} color="primary">
                    Apply
                  </Button>
                </DialogActions>
              </Dialog>
            </RowContainer>

            <RowContainer>
              <div style={{ width: '100%' }}>
                <Tooltip title="Разлок на TGE рассчитывается линейно">
                  <label>Linear payout on TGE</label>
                </Tooltip>
                <Switch
                  checked={linearCalculation}
                  onChange={handleLinearCalcChange}
                  name="is_hidden"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              </div>
              {!linearCalculation && (
                <TextFieldNumber
                  name="tge_unlock_percent"
                  label="TGE Unlock (%) (размер разлока на TGE)"
                  value={tgeUnlockPercent}
                  onChange={handleTgeUnlockChange}
                  style={{ width: '100%' }}
                  required={true}
                  disabled={linearCalculation}
                  InputProps={{
                    inputProps: { type: 'number', min: 0, max: 100 },
                    onInput: (event: React.ChangeEvent<HTMLInputElement>) => {
                      const value = parseInt(event.target.value);
                      if (value > 100) {
                        event.target.value = '100';
                      }
                    },
                  }}
                />
              )}
            </RowContainer>
            <h4 style={{ margin: '10px 0' }}>Duration (продолжительность вестинга)</h4>
            <RowContainer>
              <FormControl fullWidth margin="normal">
                <InputLabel id="vesting_duration_type">Type</InputLabel>
                <Select
                  labelId="vesting_duration_type"
                  name="vesting_duration_type"
                  value={allocationForm.vesting_duration_type || ''}
                  onChange={handleSelectChange}
                >
                  {Object.values(CoinAllocationFrequency).map((duration) => (
                    <MenuItem key={duration} value={duration}>
                      {duration}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextFieldNumber
                name="vesting_duration_value"
                label="Value *"
                value={allocationForm.vesting_duration_value}
                onChange={(e) => handleChangeTextField(e, true)}
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true }}
                InputProps={{ inputProps: { min: 1 } }}
              />
            </RowContainer>
            <h4 style={{ margin: '10px 0' }}>Frequency (периодичность разлока)</h4>
            <RowContainer>
              <FormControl fullWidth margin="normal">
                <InputLabel id="unlock_frequency_type">Type</InputLabel>
                <Select
                  labelId="unlock_frequency_type"
                  name="unlock_frequency_type"
                  value={allocationForm.unlock_frequency_type || ''}
                  onChange={handleSelectChange}
                >
                  {Object.values(CoinAllocationFrequency).map((frequency) => (
                    <MenuItem key={frequency} value={frequency}>
                      {frequency}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextFieldNumber
                name="unlock_frequency_value"
                label="Value *"
                value={allocationForm.unlock_frequency_value}
                onChange={(e) => handleChangeTextField(e, true)}
                fullWidth
                margin="normal"
                InputProps={{ inputProps: { min: 1 } }}
                InputLabelProps={{ shrink: true }}
              />
            </RowContainer>
            <ColumnContainer style={{ gap: '10px' }}>
              End date: {formatDate(endDate, true)}
              <div>
                Batch on TGE (Разблокировка на TGE): {isZeroBatch ? 'Существует:' : 'Отсутствует'}{' '}
                {isZeroBatch && batchesArray.length && `процент - ${zeroBatchUnlockPercent}%`}
              </div>
              Total Batch (Общее количество разлоков): {batchesQty} {isZeroBatch ? '+ 1 (нулевой батч на TGE)' : null}
              <div>Estimated Batch (Расчетный процент для линейного разлока): {percentForEachBatch}%</div>
            </ColumnContainer>

            <Button
              color="primary"
              startIcon={<ComputerSharp />}
              style={{ border: '1px solid', margin: '10px 0' }}
              onClick={() => {
                calculateBatchList(percentForEachBatch, batchesQty, allocationForm);
              }}
            >
              Calculate batches
            </Button>
            <h3>Batches list (total percent - {batchesTotalPercent}%)</h3>
            <BatchList
              batchesArray={batchesArray}
              changeBatch={handleChangeBatch}
              deleteBatch={handleDeleteBatch}
              addBatch={handleAddBatch}
              unlockType={allocationForm.unlock_type}
              tokens={allocationForm.tokens}
              tgeDate={tgeData}
            />
          </>
        )}
        <RowContainer>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => postAllocationForm(allocationForm, e)}
            disabled={isLoading}
            startIcon={<Send />}
          >
            Send allocation
          </Button>
          <Button
            variant="contained"
            style={{ background: 'red', color: 'white' }}
            onClick={(e) => handleDeleteAllocation(allocationId, index - 1)}
            startIcon={<Delete />}
            disabled={isLoading}
          >
            Delete allocation
          </Button>
        </RowContainer>
      </ColumnContainer>
    </StyledAllocationForm>
  );
};
