import { Editor } from '@tinymce/tinymce-react';
import { TextInput } from 'ra-ui-materialui';
import { useEffect, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

type Props = {
  source: string;
  disableMarkdown?: boolean;
  maxLength?: number;
  plainTextSource?: string;
  defaultValue?: string;
};

export const TinymceEditor = (props: Props) => {
  const { source, disableMarkdown, maxLength, plainTextSource, defaultValue } = props;

  const initData = useWatch({ name: source });

  const { setValue } = useFormContext();
  const editorRef = useRef(null) as any;

  const [error, setError] = useState('');
  // save value because admin formdata somehow does not pass initData on second render
  const [initValue, setInitValue] = useState(initData);

  useEffect(() => {
    initData && setInitValue(initData);
  }, [initData]);

  const setContent = () => {
    if (!editorRef.current?.getContent) {
      return;
    }

    const editorContent = disableMarkdown
      ? editorRef.current?.getContent({ format: 'text' })
      : editorRef.current?.getContent();

    if (maxLength && maxLength < editorContent.length) {
      setError(`Max symbols: ${maxLength}`);
      return;
    }

    setError('');
    setValue(source, editorContent, { shouldDirty: true });
    plainTextSource &&
      setValue(plainTextSource, editorRef.current?.getContent({ format: 'text' }), { shouldDirty: true });
  };

  return (
    <div style={{ marginBottom: 40 }}>
      <Editor
        tinymceScriptSrc={'/tinymce/tinymce.min.js'}
        textareaName={'Body Text'}
        ref={editorRef}
        onInit={(evt, editor) => (editorRef?.current ? (editorRef.current = editor) : undefined)}
        initialValue={initValue || '<p></p>'}
        onBlur={setContent}
        init={{
          height: 500,
          menubar: true,
          plugins: [
            'preview',
            'code',
            'fullscreen',
            'code',
            'help',
            'wordcount',
            'advlist',
            'autolink',
            'lists',
            'link',
            'image',
            'charmap',
            'anchor',
            'searchreplace',
            'visualblocks',
            'insertdatetime',
            'media',
            'table',
          ],

          link_title: false,

          link_rel_list: [
            { title: 'No Follow', value: 'nofollow' },
            { title: 'Do Follow', value: 'dofollow' },
            { title: 'No Referrer', value: 'noreferrer' },
            { title: 'No Index', value: 'noindex' },
          ],
          toolbar: disableMarkdown
            ? 'undo redo | help'
            : 'undo redo | blocks | ' +
              'bold italic forecolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | link | help',

          content_style: 'body { font-size:14px }',
          link_default_target: '_blank',
        }}
      />

      {plainTextSource && <TextInput style={{ visibility: 'hidden', height: 0, width: 0 }} source={plainTextSource} />}

      <TextInput style={{ visibility: 'hidden', height: 0, width: 0 }} source={source} defaultValue={defaultValue} />
      {error && <div style={{ color: 'red', marginTop: 0 }}>{error}</div>}
    </div>
  );
};
