import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  TextInput,
  SimpleForm,
  TopToolbar,
  ListButton,
  RefreshButton,
  DateField,
  FormDataConsumer,
  FileInput,
  FileField,
  Toolbar,
} from 'react-admin';
import { AdminSaveButton } from '../buttons/save-btn';

const UserEditToolbar = (props) => (
  <Toolbar {...props}>
    <AdminSaveButton />
  </Toolbar>
);

export const MobileLocalizationList = (props) => (
  <List {...props} perPage={50}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="version" />
      <DateField source="updatedAt" />
      <EditButton />
    </Datagrid>
  </List>
);

const handleDownload = (formData) => {
  var fileToSave = new Blob([JSON.stringify(formData.data, undefined, 2)], {
    type: 'application/json',
  });

  const url = window.URL.createObjectURL(fileToSave);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = `${formData.name}-${formData.version}.json`;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
};

const getForm = (type: 'edit' | 'create') => (
  <SimpleForm toolbar={<UserEditToolbar />}>
    <TextInput source="key" disabled={type === 'edit'} />
    <TextInput source="name" disabled={type === 'edit'} />
    {type === 'edit' && (
      <FormDataConsumer>
        {({ formData }) => <button onClick={() => handleDownload(formData)}>Download current version</button>}
      </FormDataConsumer>
    )}
    <br />
    <FileInput source="files" label="Updated localization" accept="application/json" multiple={false}>
      <FileField source="files" title="title" />
    </FileInput>
  </SimpleForm>
);

const EditActions = () => {
  return (
    <TopToolbar>
      <ListButton />
      <RefreshButton />
    </TopToolbar>
  );
};

export const MobileLocalizationEdit = (props) => (
  <Edit actions={<EditActions />} {...props}>
    {getForm('edit')}
  </Edit>
);
