import { FC } from 'react';
import { TabbedForm, TextInput } from 'ra-ui-materialui';

export const OtherTab: FC = (props) => {
  return (
    <TabbedForm.Tab label="other" {...props}>
      <TextInput multiline={true} label="Notification" source="content.notification" />
      <TextInput multiline={true} label="Internal Notes" source="content.notes" />
    </TabbedForm.Tab>
  );
};
