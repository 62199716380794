import { FC } from 'react';
import { DateInput, TabbedForm } from 'ra-ui-materialui';
import { NumberInput } from '../../inputs/number-input';

export const AthTab: FC = (props) => {
  return (
    <TabbedForm.Tab label="ATH" {...props}>
      <NumberInput label="ATH USD" source="athData.USD" />
      <NumberInput label="ATH BTC" source="athData.BTC" />
      <NumberInput label="ATH ETH" source="athData.ETH" />
      <NumberInput label="ATH Market Cap (USD)" source="athData.marketCap" />
      <DateInput label="ATH Date (USD)" source="athData.date" />
      <DateInput label="ATH Date (BTC)" source="athData.dateBTC" />
      <DateInput label="ATH Date (ETH)" source="athData.dateETH" />
    </TabbedForm.Tab>
  );
};
