import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  TextInput,
  Create,
  CreateButton,
  ListButton,
  RefreshButton,
  Filter,
  ImageField,
  ImageInput,
  FunctionField,
  ReferenceInput,
  BulkDeleteWithUndoButton,
  maxLength,
  TopToolbar,
  useRecordContext,
  TabbedForm,
  ArrayInput,
  AutocompleteArrayInput,
  ReferenceArrayInput,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import { getEnvs } from '../env-lib';
import { NumberInput } from '../inputs/number-input';
import { AutocompleteInput } from '../inputs/autocomplete-input';
import { imageInputAccept, SortingDirection } from '../variables';
import { S3ImageDir } from '../variables';
import { AdminDeleteButton } from '../buttons/delete-btn';
import { DefaultToolBar } from '../toolbars/default-toolbar';
import { getIsDisabledByRole } from '../utils/get-disabled-by-role';
import { RichTextInputCustomToolbar } from '../toolbars/rich-text-input-toolbar';
import { ArrayInputIterator } from '../inputs/array-input-iterator';
import { TrimOnBlurInput } from '../inputs/trim-on-blur-input';
import { helperTexts } from '../variables/helper-texts';

const imagesBasePath = getEnvs().REACT_APP_IMAGES_BASE_URL + S3ImageDir.FUNDS;
const shortDescriptionMaxLength = 150;

const getForm = (type: 'edit' | 'create') => {
  return (
    <TabbedForm toolbar={<DefaultToolBar />}>
      <TabbedForm.Tab label="summary">
        <TrimOnBlurInput source="name" />
        <TextInput source="slug" helperText={helperTexts.keyInputValidation} />
        <ReferenceInput
          label="Category"
          source="categoryId"
          reference="fund-categories"
          sort={{ field: 'id', order: SortingDirection.ASC }}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <NumberInput source="tier" />

        <ReferenceInput label="Jurisdiction" source="countryId" reference="countries" perPage={10} allowEmpty={true}>
          <AutocompleteInput optionText="name" />
        </ReferenceInput>

        {type === 'edit' && (
          <>
            <FunctionField
              label="Image"
              render={(record) =>
                record.logo ? (
                  <img src={imagesBasePath + record.logo} alt="image" style={{ maxHeight: '170px' }} />
                ) : null
              }
            />
            <ImageInput source="image" label="Image" accept={imageInputAccept}>
              <ImageField source="src" title="Image" />
            </ImageInput>
            <p>Maximum size is 1MB</p>

            <TextInput
              multiline
              label="Short description"
              source="shortDescription"
              validate={maxLength(shortDescriptionMaxLength)}
            />
            <RichTextInput source="description" toolbar={<RichTextInputCustomToolbar />} fullWidth={true} />
          </>
        )}
      </TabbedForm.Tab>

      <TabbedForm.Tab label="Team">
        <ArrayInput label="Select Person" source="team">
          <ArrayInputIterator>
            <ReferenceInput label="Persons" source="person_id" reference="persons" perPage={300}>
              <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <ReferenceArrayInput
              label="Jobs"
              source="job_ids"
              resource="job_ids"
              reference="persons-jobs"
              perPage={300}
              sort={{ field: 'name', order: SortingDirection.ASC }}
            >
              <AutocompleteArrayInput optionText="name" />
            </ReferenceArrayInput>
            <NumberInput label="Priority" source="priority" step={1} min={1} />
          </ArrayInputIterator>
        </ArrayInput>
      </TabbedForm.Tab>

      <TabbedForm.Tab label="links">
        <ReferenceArrayInput source="link_ids" reference="links" sort={{ field: 'id', order: SortingDirection.DESC }}>
          <AutocompleteArrayInput filterToQuery={(q) => ({ q_value: q })} optionText="value" />
        </ReferenceArrayInput>
      </TabbedForm.Tab>
    </TabbedForm>
  );
};

export const FundCreate = (props) => <Create {...props}>{getForm('create')}</Create>;

const EditActions = () => {
  return (
    <TopToolbar>
      <CreateButton />
      <ListButton />
      <RefreshButton />
    </TopToolbar>
  );
};

const FundTitle = () => {
  const record = useRecordContext();
  return <span>Fund '{record?.name}'</span>;
};

export const FundEdit = (props) => (
  <Edit actions={<EditActions />} title={<FundTitle />} {...props}>
    {getForm('edit')}
  </Edit>
);

const FundsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Name" source="q" alwaysOn />
  </Filter>
);

export const FundsList = (props) => (
  <List {...props} sort={{ field: 'id', order: SortingDirection.ASC }} perPage={50} filters={<FundsFilter />}>
    <Datagrid bulkActionButtons={<BulkDeleteWithUndoButton {...props} disabled={getIsDisabledByRole()} />}>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="tier" />
      <EditButton />
      <AdminDeleteButton />
    </Datagrid>
  </List>
);
