import { createElement, useState } from 'react';
import { Collapse, List, ListItemButton, ListItemIcon as IconWrapper, ListItemText, SvgIcon } from '@mui/material';
import { List as ListIcon, Inbox, ExpandLess, ExpandMore } from '@mui/icons-material';
import { MenuItemLink } from 'ra-ui-materialui';
import { MenuGroups } from '../variables/menu-groups';

type Props = {
  name: MenuGroups;
  resources: {
    name: string;
    icon?: typeof SvgIcon;
    options?: {
      label: string;
      group: MenuGroups;
    };
  }[];
};

export const NestedList = ({ name, resources }: Props) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const firstItemIcon = resources[0]?.icon;

  return (
    <List component="nav" disablePadding={true}>
      <ListItemButton onClick={handleClick} style={{ padding: '6px 16px' }}>
        <IconWrapper style={{ minWidth: 40 }}>
          {(firstItemIcon && createElement(firstItemIcon)) || <Inbox />}
        </IconWrapper>
        <ListItemText primary={name} style={{ textTransform: 'capitalize', margin: 0, whiteSpace: 'nowrap' }} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding style={{ marginLeft: 20 }}>
          {resources.map(({ name, icon, options }) => (
            <MenuItemLink
              key={name}
              to={'/' + name}
              primaryText={(options && options.label) || name}
              leftIcon={(icon && createElement(icon)) || <ListIcon />}
              style={{ textTransform: 'capitalize' }}
            />
          ))}
        </List>
      </Collapse>
    </List>
  );
};
