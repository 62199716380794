import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  Create,
  TopToolbar,
  CreateButton,
  ListButton,
  RefreshButton,
  SimpleForm,
  DateField,
  TextInput,
  ImageInput,
  ImageField,
  BooleanInput,
  SelectInput,
  DateTimeInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  FunctionField,
  BulkDeleteWithUndoButton,
  required,
} from 'react-admin';
import { imageInputAccept, SortingDirection } from '../../variables';
import { TinymceEditor } from '../../inputs/tinymce-editor';
import { getIsDisabledByRole } from '../../utils/get-disabled-by-role';
import { DefaultToolBar } from '../../toolbars/default-toolbar';
import { AdminDeleteButton } from '../../buttons/delete-btn';

const getForm = () => (
  <SimpleForm toolbar={<DefaultToolBar />}>
    <TextInput label="Title" source="title" required />
    <TextInput label="News page url" source="urlId" required />

    <SelectInput
      required
      defaultValue={'en'}
      source="lang"
      choices={[
        { id: 'en', name: 'en' },
        { id: 'ru', name: 'ru' },
      ]}
    />

    <FunctionField
      label="Uploaded image"
      render={(record) => record?.imageUrl && <img src={record?.imageUrl} alt="Logo" style={{ maxHeight: '70px' }} />}
    />

    <ImageInput source="imageUrl" label="Image" accept={imageInputAccept} validate={required()}>
      <ImageField title="Image" />
    </ImageInput>

    <TextInput source="description" label="Lead Text" required />

    <TinymceEditor source="fullContent" />

    <BooleanInput source="isAdvertisement" label="AD" defaultValue={true} />

    <SelectInput
      source="priority"
      label="Order"
      emptyText='Unpinned'
      choices={[
        { id: 1, name: '1' },
        { id: 2, name: '2' },
        { id: 3, name: '3' },
      ]}
    />

    <DateTimeInput label="Publish date" source="pubDate" required />
    <DateTimeInput label="Order expire time" source="priorityEndsAt" />

    <ReferenceArrayInput
      source="categoriesIds"
      reference="news-category"
      sort={{ field: 'name', order: SortingDirection.ASC }}
    >
      <AutocompleteArrayInput optionText="name" />
    </ReferenceArrayInput>

    <ReferenceArrayInput source="fundsIds" reference="funds" sort={{ field: 'name', order: SortingDirection.ASC }}>
      <AutocompleteArrayInput optionText="name" />
    </ReferenceArrayInput>

    <ReferenceArrayInput
      source="coinsIds"
      reference="coins"
      sort={{ field: 'name', order: SortingDirection.ASC }}
      filter={{ enabled: true }}
    >
      <AutocompleteArrayInput optionText="name" />
    </ReferenceArrayInput>

    <TextInput label="Link URL" source="url" required />
    <TextInput label="Link Name" source="linkName" />
    <TextInput label="Published by / Source Name" source="sourceName" />
  </SimpleForm>
);

const EditActions = (props) => {
  return (
    <TopToolbar>
      <CreateButton />
      <ListButton />
      <RefreshButton />
    </TopToolbar>
  );
};

export const SponsoredNewsEdit = (props) => {
  return (
    <Edit actions={<EditActions />} title={<span>Link</span>} {...props}>
      {getForm()}
    </Edit>
  );
};

export const SponsoredNewsCreate = (props) => {
  return <Create {...props}>{getForm()}</Create>;
};

export const SponsoredNewsList = (props) => (
  <List {...props} sort={{ field: 'id', order: SortingDirection.DESC }} filter={{ isCustom: true }} perPage={50}>
    <Datagrid bulkActionButtons={<BulkDeleteWithUndoButton {...props} disabled={getIsDisabledByRole()} />}>
      <TextField source="title" />
      <TextField source="priority" />
      <DateField source="pubDate" label="Publish date" />
      <DateField source="priorityEndsAt" label="Order expire time" />
      <DateField source="createdAt" label="Create at" />
      <EditButton />
      <AdminDeleteButton />
    </Datagrid>
  </List>
);
