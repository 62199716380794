import { AttachFile } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { SimpleForm, FileInput, FileField, useNotify } from 'react-admin';
import styled from 'styled-components';
import { getEnvs } from '../env-lib';
import { LocalStorageKeys } from '../variables';
import { Button } from '@mui/material';
import { getIsDisabledByRole } from '../utils/get-disabled-by-role';
import { getCurrentAppVersion } from '../api-service';

const Highlighted = styled.span`
  background-color: #707070;
  color: #fff;
  border-radius: 5px;
  padding: 3px;
`;

const StyledForm = styled(SimpleForm)`
  width: 700px;
  font-family: 'Roboto';

  [data-testid='dropzone'] {
    background-color: #e4e4e4;
    border-radius: 10px;
  }
`;

const Progress = styled.div<{ state: number }>`
  margin-left: 10px;
  width: 200px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.25);
  box-shadow:
    inset 0 1px 2px rgba(0, 0, 0, 0.25),
    0 1px rgba(255, 255, 255, 0.08);
  border-radius: 10px;
  position: relative;
  padding: 2px;
  opacity: ${(p) => !p.state && 0};
  transition: opacity 0.2s ease;

  > b {
    width: ${(p) => p.state}%;
    height: 100%;
    display: block;
    border-radius: inherit;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
    transition: 0.4s linear;
    background-color: ${(p) => (p.state === 100 ? '#76e57f' : '#4183c4')};
    transition-property: width, background-color;
  }
`;

const Preview = styled.div`
  display: flex;
  align-items: center;
  color: #565656;

  > i {
    display: inline-flex;
    padding: 4px;
    border-radius: 100%;
    background-color: antiquewhite;
  }

  > b {
    margin: 0 5px;
  }

  > span {
    font-weight: 500;
  }
`;

const validateApkFile = (fileName: string) => new RegExp(/^[0-9.]*.apk$/).test(fileName);

export const MobileDownload = (props) => {
  const [currentVersion, setCurrentVersion] = useState<string>();
  const [file, setFile] = useState<File | null>(null);
  const [progress, setProgress] = useState(0);
  const isDisabledByRole = getIsDisabledByRole();

  const notify = useNotify();

  useEffect(() => {
    getCurrentAppVersion().then(setCurrentVersion);
  }, []);

  const onProgress = (e: ProgressEvent<XMLHttpRequestEventTarget>) => {
    setProgress(Math.round(100 / (e.total / e.loaded)));
  };

  const uploadFile = () => {
    if (!file) {
      return;
    }

    const API_ENDPOINT = getEnvs().REACT_APP_API_SERVER + '/v0/admin/mobile-uploader';
    const auth = localStorage.getItem(LocalStorageKeys.AUTH);
    const request = new XMLHttpRequest();
    const formData = new FormData();
    request.upload.addEventListener('progress', onProgress, false);
    request.open('POST', API_ENDPOINT, true);
    request.setRequestHeader('Authorization', `Bearer ${auth}`);
    formData.append('apk', file);
    request.send(formData);

    request.onreadystatechange = function () {
      if (this.readyState === 4) {
        if (this.status >= 200 && this.status < 400) {
          notify('Файл успешно отправлен', { type: 'success' });
          setProgress(0);
          setFile(null);
        } else {
          notify('Ошибка при отправке файла', { type: 'error' });
          setProgress(0);
        }
      }
    };
  };

  const onChangeFile = (file: File | null) => {
    if (!file) {
      setFile(null);
      return;
    }

    if (!validateApkFile(file.name)) {
      notify('Имя должно содержать только версию, например: 15.apk, 1.0.2.apk', { type: 'error' });
      return;
    }
    setFile(file);
  };

  return (
    <>
      <StyledForm {...props} toolbar={false}>
        <FileInput
          parse={() => null}
          source="apk"
          label="Upload Mobile App"
          accept=".apk"
          multiple={false}
          onChange={onChangeFile}
        >
          <FileField source="apk" title="title" />
        </FileInput>
        {file && (
          <Preview>
            <i>
              <AttachFile />
            </i>
            <b>APK</b>
            <span>v{file.name.replace(/.apk$/, '')}</span>
          </Preview>
        )}

        {currentVersion && <span>Currently downloaded version: {currentVersion}</span>}

        <ul style={{ width: 700 }}>
          <li>
            Загружать можно только файлы с расширением <Highlighted>.apk</Highlighted>
          </li>
          <li>
            В названии приложения должна быть только версия, например <Highlighted>15.apk</Highlighted>
          </li>
        </ul>
      </StyledForm>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button onClick={uploadFile} disabled={!file || Boolean(progress) || isDisabledByRole} variant='contained'>
          Upload
        </Button>
        <Progress state={progress}>
          <b />
        </Progress>
      </div>
    </>
  );
};
