import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  Create,
  TopToolbar,
  ListButton,
  RefreshButton,
  SimpleForm,
  DateField,
  ReferenceInput,
  AutocompleteInput,
  DateInput,
  ReferenceField,
  SelectInput,
  BulkDeleteWithUndoButton,
  required,
} from 'react-admin';
import { SortingDirection } from '../../variables';
import { AdminDeleteButton } from '../../buttons/delete-btn';
import { AdminCreateBtn } from '../../buttons/create-btn';
import { DefaultToolBar } from '../../toolbars/default-toolbar';
import { getIsDisabledByRole } from '../../utils/get-disabled-by-role';

const Form = (
  <SimpleForm toolbar={<DefaultToolBar />}>
    <ReferenceInput
      label="Project"
      source="coin_id"
      filter={{ enabled: true }}
      perPage={10}
      reference="coins"
      sort={{ field: 'id', order: SortingDirection.ASC }}
    >
      <AutocompleteInput optionText="fullName" validate={required()} />
    </ReferenceInput>
    <SelectInput
      label="Priority"
      source="priority"
      choices={new Array(5).fill(0).map((_, i) => ({ id: (i + 1).toString(), name: (i + 1).toString() }))}
      validate={required()}
    />
    <DateInput label="Start date" source="start_date" validate={required()} />
    <DateInput label="End date" source="end_date" validate={required()} />
  </SimpleForm>
);

const EditActions = () => {
  return (
    <TopToolbar>
      <AdminCreateBtn />
      <ListButton />
      <RefreshButton />
    </TopToolbar>
  );
};

export const SponsoredFundingRoundEdit = (props) => (
  <Edit actions={<EditActions />} title={<span>Link</span>} {...props}>
    {Form}
  </Edit>
);

export const SponsoredFundingRoundCreate = (props) => {
  return <Create {...props}>{Form}</Create>;
};

export const SponsoredFundingRoundList = (props) => (
  <List {...props} sort={{ field: 'id', order: SortingDirection.DESC }} perPage={50}>
    <Datagrid bulkActionButtons={<BulkDeleteWithUndoButton {...props} disabled={getIsDisabledByRole()} />}>
      <ReferenceField label="Project" source="coin_id" reference="coins">
        <TextField source="fullName" />
      </ReferenceField>
      <TextField source="priority" label="Priority" />
      <DateField source="start_date" label="Start Date" />
      <DateField source="end_date" label="End Date" />
      <EditButton />
      <AdminDeleteButton />
    </Datagrid>
  </List>
);
