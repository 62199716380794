import {
  AutocompleteInput,
  FunctionField,
  ImageField,
  ImageInput,
  ReferenceInput,
  TextInput,
  TranslatableInputs,
} from 'react-admin';
import { imageInputAccept, SortingDirection } from '../../variables';
import { QuestType, RewardsType } from './variables';
import { NumberInput } from '../../inputs/number-input';

export const getRewardFormByType = (rewardType: RewardsType, questType: QuestType) => {
  switch (true) {
    case rewardType === RewardsType.TOKEN:
      return (
        <>
          <ReferenceInput
            label="Tokens"
            source="coinId"
            reference="coins"
            alwaysOn={true}
            sort={{ field: 'id', order: SortingDirection.ASC }}
          >
            <AutocompleteInput optionText="fullName" />
          </ReferenceInput>
          <div>
            <NumberInput source="rewardData.tokensAmount" label="Tokens amount" required min={0} />
          </div>
          <div>
            <ReferenceInput
              label="Token Platform"
              source="tokenPlatformId"
              reference="token-platforms"
              alwaysOn={true}
              sort={{ field: 'id', order: SortingDirection.ASC }}
            >
              <AutocompleteInput optionText="name" />
            </ReferenceInput>
          </div>
          {questType === QuestType.LOTTERY && (
            <NumberInput source="rewardData.winnersAmount" label="Winners amount" required min={0} />
          )}
        </>
      );
    case rewardType === RewardsType.NFT:
      return (
        <>
          {questType === QuestType.LOTTERY && (
            <div>
              <NumberInput source="rewardData.winnersAmount" label="Winners amount" required min={0} />
              <div>
                <NumberInput source="rewardData.nftAmount" label="NFT amount" required min={0} />
              </div>
            </div>
          )}
          <div>
            <TextInput source="rewardData.hyperlinkName" label="Hyperlink Name" />
          </div>
          <div>
            <TextInput source="rewardData.hyperlink" label="Hyperlink" />
          </div>
          <FunctionField
            label="Logo"
            render={(record) =>
              record.rewardData?.logo && <img src={record?.rewardData?.logo} alt="logo" style={{ maxHeight: '70px' }} />
            }
          />
          <ImageInput source="rewardData.logo" label="Upload Logo (252 x 252)" accept={imageInputAccept}>
            <ImageField source="rewardData.logo" title="Logo" />
          </ImageInput>
          <div>
            <ReferenceInput
              label="Token Platform"
              source="tokenPlatformId"
              reference="token-platforms"
              alwaysOn={true}
              sort={{ field: 'id', order: SortingDirection.ASC }}
            >
              <AutocompleteInput optionText="name" />
            </ReferenceInput>
          </div>
        </>
      );
    case rewardType === RewardsType.CUSTOM && questType === QuestType.LOTTERY:
      return (
        <>
          <div>
            <TranslatableInputs locales={['en', 'ru']} defaultLocale="en">
              <TextInput source="rewardData.customName" label="Custom Name" />
            </TranslatableInputs>
            <NumberInput source="rewardData.winnersAmount" label="Winners amount" required min={0} />
            <div>
              <NumberInput source="rewardData.customAmount" label="Custom amount" required min={0} />
            </div>
          </div>
          <div>
            <TextInput source="rewardData.hyperlinkName" label="Hyperlink Name" />
          </div>
          <div>
            <TextInput source="rewardData.hyperlink" label="Hyperlink" />
          </div>
          <FunctionField
            label="Logo"
            render={(record) =>
              record.rewardData?.logo && <img src={record?.rewardData?.logo} alt="logo" style={{ maxHeight: '70px' }} />
            }
          />
          <ImageInput source="rewardData.logo" label="Upload Logo (252 x 252)" accept={imageInputAccept}>
            <ImageField source="rewardData.logo" title="Logo" />
          </ImageInput>
          <div>
            <ReferenceInput
              label="Token Platform"
              source="tokenPlatformId"
              reference="token-platforms"
              alwaysOn={true}
              sort={{ field: 'id', order: SortingDirection.ASC }}
            >
              <AutocompleteInput optionText="name" />
            </ReferenceInput>
          </div>
        </>
      );
    case rewardType === RewardsType.CUSTOM:
      return (
        <>
          <TranslatableInputs locales={['en', 'ru']} defaultLocale="en">
            <TextInput source="rewardData.nameTranslations" label="Name Translations" />
          </TranslatableInputs>
          <div>
            <TextInput source="rewardData.hyperlinkName" label="Hyperlink Name" />
          </div>
          <div>
            <TextInput source="rewardData.hyperlink" label="Hyperlink" />
          </div>
          <FunctionField
            label="Logo"
            render={(record) => record.logo && <img src={record.logo} alt="logo" style={{ maxHeight: '70px' }} />}
          />
          <ImageInput source="rewardData.logo" label="Upload Logo (252 x 252)" accept={imageInputAccept}>
            <ImageField source="logo" title="Logo" />
          </ImageInput>
        </>
      );
    default:
      return null;
  }
};
