import { icoScreenshotsPath } from "./resources/coins/constants";

const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

const parse = (file: File) => {
  return new Promise((resolve) => {
    let content = '';
    const reader = new FileReader();
    reader.onloadend = function (e: any) {
      content = e.target.result;
      resolve(JSON.parse(content));
    };
    reader.readAsText(file);
  });
};

const getMedia = async (data) => {
  let path = '';

  const getIterate = async (obj, currentPath: string) => {
    for (const prop in obj) {
      if (typeof obj[prop] === 'object') {
        const newPath = currentPath ? `${currentPath}.${prop}` : prop;
        await getIterate(obj[prop], newPath);
      }
  
      if (!(obj[prop]?.rawFile instanceof File)) {
        continue;
      }
  
      if (obj[prop]?.rawFile.type === 'application/json') {
        const result = await parse(obj[prop]?.rawFile);
        obj.data = result;
        continue;
      }

      if (currentPath === icoScreenshotsPath) {
        obj[prop] = {
          data: await convertFileToBase64(obj[prop]),
          description: obj[prop].title.replace(/\.[^/.]+$/, '') // trim a file extension
        };
      } else {
        obj[prop] = await convertFileToBase64(obj[prop]);
      }
    }
  }

  await getIterate(data, path)
  
  return data;
};

export const addUploadCapabilities = (dataProvider, method: 'create' | 'update') => async (resource, params) => {
  const data = await getMedia(params.data);

  return dataProvider[method](resource, { ...params, data });
};
