import { TextInput } from 'ra-ui-materialui';
import { ArrayInput, ReferenceInput, required } from 'react-admin';
import { IconWithPreview } from './components';
import { SortingDirection } from '../../variables';
import { AutocompleteInput } from '../../inputs/autocomplete-input';
import { ArrayInputIterator } from '../../inputs/array-input-iterator';

export const AuditBanner = () => {
  return (
    <>
      <TextInput
        source="title"
        label="Title (название на кнопке. только одна локаль)"
        fullWidth={true}
        validate={required()}
      />
      <IconWithPreview />
      <ArrayInput label="Coin" source="coin_audits">
        <ArrayInputIterator key={'coin_id'}>
          <ReferenceInput
            label="Coin"
            source="coin_id"
            reference="coins"
            alwaysOn={true}
            sort={{ field: 'id', order: SortingDirection.ASC }}
          >
            <AutocompleteInput optionText="fullName" />
          </ReferenceInput>

          <TextInput
            source="url"
            label="Link URL"
            helperText="URL with https://"
            fullWidth={true}
            validate={required()}
          />
        </ArrayInputIterator>
      </ArrayInput>
      {/* TODO: Инпут ниже добален, чтобы не удалять обязательный параметр url с бэка */}
      <TextInput
        source="url"
        value="https://123"
        defaultValue="https://123"
        helperText="Не очищать поле. Необходимо для корректной обработки запроса"
        fullWidth={true}
        style={{ opacity: 0, height: 0, margin: 0 }}
        disabled
      />
    </>
  );
};
