import { TaskGroup, TaskType } from './types';

// Все типы можно посмотреть тут https://github.com/CryptoRank/cryptorank_tma_front/blob/main/src/services/types.ts
export const taskTypeChoices = [
  { id: TaskType.DAILY, name: 'Daily Task' },
  { id: TaskType.JOIN_CHAT, name: 'Join Chat Task' },
  { id: TaskType.UNIQUE, name: 'Unique Task' },
  { id: TaskType.PUBLICATION_STORY, name: 'Publication of Story' },
  { id: TaskType.QUIZ, name: 'Quiz' },
  { id: TaskType.RAFFLE_QUEST, name: 'Raffle Quest' },
];

export const taskGroupChoices = [
  { id: TaskGroup.MAIN, name: 'Main' },
  { id: TaskGroup.DAILY, name: 'Daily' },
  { id: TaskGroup.CRYPTORANK, name: 'Cryptorank' },
];
