import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  TextInput,
  Create,
  TabbedForm,
  FormTab,
  ArrayInput,
  SelectInput,
  TopToolbar,
  CreateButton,
  ListButton,
  BooleanInput,
  RefreshButton,
  Filter,
  BulkDeleteWithUndoButton,
  useRecordContext,
  maxLength,
} from 'react-admin';
import styled from 'styled-components';
import { SortingDirection } from '../variables';
import { AdminDeleteButton } from '../buttons/delete-btn';
import { DefaultToolBar } from '../toolbars/default-toolbar';
import { getIsDisabledByRole } from '../utils/get-disabled-by-role';
import { ArrayInputIterator } from '../inputs/array-input-iterator';
import { locales } from '../variables';
import { TrimOnBlurInput } from '../inputs/trim-on-blur-input';
import { helperTexts } from '../variables/helper-texts';

const TagLocalization = styled.div`
  width: 100% !important;
  & .MuiTypography-root {
    display: none;
  }
`;

const TagsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Name" source="q" alwaysOn />
  </Filter>
);
export const CoinTagList = (props) => (
  <List sort={{ field: 'id', order: SortingDirection.ASC }} perPage={50} filters={<TagsFilter />}>
    <Datagrid bulkActionButtons={<BulkDeleteWithUndoButton {...props} disabled={getIsDisabledByRole()} />}>
      <TextField source="id" />
      <TextField source="name" />
      <EditButton />
      <AdminDeleteButton />
    </Datagrid>
  </List>
);

const CoinTagTitle = () => {
  const record = useRecordContext();
  return <span>Coin Tag '{record?.name}'</span>;
};

const getForm = (type: 'edit' | 'create') => (
  <TabbedForm toolbar={<DefaultToolBar />}>
    <FormTab label="summary">
      <TrimOnBlurInput required source="name" validate={[maxLength(64)]} />
      <TextInput required source="slug" validate={[maxLength(64)]} helperText={helperTexts.keyInputValidation} />
      <TextInput source="description" validate={[maxLength(256)]} />
      <BooleanInput source="enabled" defaultValue={true} />
      {type === 'edit' ? (
        <>
          <BooleanInput label="ICO List" source="isIcoList" />
        </>
      ) : null}
    </FormTab>
    <FormTab label="localization">
      <TagLocalization>
        <ArrayInput label="Tag Localization" source="localization">
          <ArrayInputIterator>
            <SelectInput label="Locale" source="locale" choices={locales} />
            <TrimOnBlurInput source="name" validate={[maxLength(64)]} />
            <TextInput source="description" validate={[maxLength(256)]} />
          </ArrayInputIterator>
        </ArrayInput>
      </TagLocalization>
    </FormTab>
  </TabbedForm>
);

const EditActions = () => {
  return (
    <TopToolbar>
      <CreateButton />
      <ListButton />
      <RefreshButton />
    </TopToolbar>
  );
};

export const CoinTagEdit = (props) => (
  <Edit actions={<EditActions />} title={<CoinTagTitle />} {...props}>
    {getForm('edit')}
  </Edit>
);

export const CoinTagCreate = (props) => <Create {...props}>{getForm('create')}</Create>;
