import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  Create,
  TopToolbar,
  CreateButton,
  ListButton,
  RefreshButton,
  SimpleForm,
  DateField,
  TextInput,
  BooleanInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from 'react-admin';
import { SortingDirection } from '../../variables';
import { DefaultToolBar } from '../../toolbars/default-toolbar';
import { AdminDeleteButton } from '../../buttons/delete-btn';

const formatYoutubeLink = (link: string) => {
  if (!link || !link.includes('v=')) {
    return link;
  }
  return link.split('v=')[1].split('&')[0];
};

const Form = (
  <SimpleForm toolbar={<DefaultToolBar />}>
    <TextInput label="Title" source="title" required />
    <TextInput label="Paste url or id" source="id" parse={formatYoutubeLink} required />

    <BooleanInput source="localEn" label="English locale" />
    <BooleanInput source="localRu" label="Russian locale" />

    <ReferenceArrayInput source="enTagsIds" reference="content-tags">
      <AutocompleteArrayInput />
    </ReferenceArrayInput>

    <ReferenceArrayInput source="ruTagsIds" reference="content-tags">
      <AutocompleteArrayInput />
    </ReferenceArrayInput>

    <ReferenceArrayInput source="fundsIds" reference="funds" sort={{ field: 'name', order: SortingDirection.ASC }}>
      <AutocompleteArrayInput optionText="name" />
    </ReferenceArrayInput>

    <ReferenceArrayInput
      source="coinsIds"
      reference="coins"
      sort={{ field: 'name', order: SortingDirection.ASC }}
      filter={{ enabled: true }}
    >
      <AutocompleteArrayInput optionText="name" />
    </ReferenceArrayInput>
  </SimpleForm>
);

const EditActions = (props) => {
  return (
    <TopToolbar>
      <CreateButton />
      <ListButton />
      <RefreshButton />
    </TopToolbar>
  );
};

export const YoutubeVideosEdit = (props) => (
  <Edit actions={<EditActions />} title={<span>Link</span>} {...props}>
    {Form}
  </Edit>
);

export const YoutubeVideosCreate = (props) => {
  return <Create {...props}>{Form}</Create>;
};

export const YoutubeVideosList = (props) => (
  <List {...props} sort={{ field: 'id', order: SortingDirection.DESC }} perPage={50}>
    <Datagrid>
      <TextField source="title" />
      <TextField source="author" />
      <DateField source="created_at" />
      <EditButton />
      <AdminDeleteButton />
    </Datagrid>
  </List>
);
