export enum CrowdsaleType {
  ANGEL = 'Angel',
  ICO = 'ICO',
  IEO = 'IEO',
  IDO = 'IDO',
  NODE_SALE = 'Node Sale',
  KOL = 'KOL',
  PRIVATE_PRESALE = 'Private/Pre-sale',
  PRIVATE = 'Private',
  PRIVATE2 = 'Private 2',
  PRESALE = 'Pre-sale',
  STRATEGIC = 'Strategic',
  STRATEGIC2 = 'Strategic 2',
  PRE_SEED = 'Pre-Seed',
  SEED = 'Seed',
}
