import { checkNodeEnv } from './utils/check-node-env';

export enum AvailableEnvMode {
  PRODUCTION = 'production',
  TEST = 'test',
  DEVELOPMENT = 'development',
  STAGING = 'staging',
}

interface Environments {
  REACT_APP_API_SERVER: string;
  REACT_APP_IMAGES_BASE_URL: string;
  REACT_APP_FRONTEND_URL: string;
  REACT_APP_ENV: AvailableEnvMode;
}

export function getEnvs(): Environments {
  checkNodeEnv();
  // @ts-ignore
  if (window && window._env_) {
    // @ts-ignore
    return window._env_;
  }
  return {
    REACT_APP_API_SERVER: process.env.REACT_APP_API_SERVER as string,
    REACT_APP_IMAGES_BASE_URL: process.env.REACT_APP_IMAGES_BASE_URL as string,
    REACT_APP_FRONTEND_URL: process.env.REACT_APP_FRONTEND_URL as string,
    REACT_APP_ENV: process.env.REACT_APP_ENV as AvailableEnvMode,
  };
}
