import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  UrlField,
  FunctionField,
  ImageField,
  EditButton,
} from 'react-admin';
import { SortingDirection } from '../../variables';
import { ImageHubFilter } from './filter';
import { CopyButton } from '../../buttons/copy-button';

const imageFieldStyle = {
  '&& > img': {
    width: 50,
    height: 'auto'
  },
};

export const ImageHubList = (props) => {
  const MAX_DESCRIPTION_LENGTH = 20;

  return (
    <List
      {...props}
      sort={{ field: 'created_at', order: SortingDirection.DESC }}
      perPage={50}
      filters={<ImageHubFilter />}
    >
      <Datagrid>
        <TextField source="id" label="ID" />
        <ImageField source="image_url" label="Image" sx={imageFieldStyle} />
        <TextField source="image_name" label="Name" />
        <FunctionField
          label="Description"
          render={(record) => {
            const description = record.description || 'Not Set';
            return `${description.substring(0, MAX_DESCRIPTION_LENGTH)} ${
              description.length > MAX_DESCRIPTION_LENGTH ? '...' : ''
            }`;
          }}
        />
          <TextField source="uploader_email" label="Uploader" />
        <DateField source="created_at" showTime label="Creation Date" />
        <DateField source="updated_at" showTime label="Last Modified" />
        <UrlField source="image_url" label="URL" />
        <FunctionField label="Copy URL" render={(record) => <CopyButton textToCopy={record.image_url} />} />
        <EditButton />
      </Datagrid>
    </List>
  );
};