import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  TextInput,
  Create,
  TopToolbar,
  CreateButton,
  ListButton,
  RefreshButton,
  Filter,
  SimpleForm,
  DateField,
  BooleanInput,
  SelectInput,
  BulkDeleteWithUndoButton,
  useRecordContext,
} from 'react-admin';
import { SortingDirection } from '../../variables';
import { EnabledField } from '../../fields/enabled-field';
import { DefaultToolBar } from '../../toolbars/default-toolbar';
import { getIsDisabledByRole } from '../../utils/get-disabled-by-role';

const locales = [
  { id: 'ru', name: 'Russian' },
  { id: 'en', name: 'English' },
];

const NewsSourceForm = (
  <SimpleForm toolbar={<DefaultToolBar />}>
    <BooleanInput source="enabled" helperText="Включает/Отключает парсинг, старые новости никуда не пропадут" />
    <BooleanInput source="isSelectedByDefault" helperText="Включает/Отключает выбор источника по умолчанию" />
    <TextInput source="name" required helperText="Имя источника - отображается в новостях" />
    <TextInput
      source="url"
      label="Source URL"
      required
      helperText="Ссылка на сам источник, например: https://incrypted.net/"
    />
    <TextInput
      source="feedUrl"
      label="Feed URL"
      required
      helperText="Ссылка на RSS-Feed, например: https://incrypted.net/feed/"
    />
    <SelectInput source="lang" label="Lang" choices={locales} required helperText="Язык новостей" />
  </SimpleForm>
);

export const NewsSourceCreate = (props) => {
  return <Create {...props}>{NewsSourceForm}</Create>;
};

const NewsSourceEditActions = () => {
  return (
    <TopToolbar>
      <CreateButton />
      <ListButton />
      <RefreshButton />
    </TopToolbar>
  );
};

const NewsSourceTitle = () => {
  const record = useRecordContext();
  return <span>News Source '{record?.name}'</span>;
};

export const NewsSourceEdit = (props) => (
  <Edit actions={<NewsSourceEditActions />} title={<NewsSourceTitle />} {...props}>
    {NewsSourceForm}
  </Edit>
);

const NewsSourceFilter = (props) => (
  <Filter {...props}>
    <TextInput source="name" label="Name" alwaysOn />
    <SelectInput source="lang" label="Lang" choices={locales} alwaysOn />
    <BooleanInput source="enabled" label="Enabled" />
  </Filter>
);

export const NewsSourceList = (props) => (
  <List {...props} sort={{ field: 'id', order: SortingDirection.ASC }} perPage={50} filters={<NewsSourceFilter />}>
    <Datagrid bulkActionButtons={<BulkDeleteWithUndoButton {...props} disabled={getIsDisabledByRole()} />}>
      <TextField source="id" label="ID" />
      <EnabledField source="enabled" label="On/Off" />
      <EnabledField source="isSelectedByDefault" label="Selected by default" />
      <TextField source="name" label="Source Name" />
      <TextField source="lang" label="Lang" />
      <DateField source="lastParsedAt" label="Last Parsing" />
      <DateField source="createdAt" label="Date Added" />
      <EditButton />
    </Datagrid>
  </List>
);
