import {
  List,
  Datagrid,
  TextField,
  EditButton,
  TextInput,
  Toolbar,
  Filter,
  BooleanInput,
  BooleanField,
  Create,
  TranslatableInputs,
  ImageInput,
  ArrayInput,
  SelectInput,
  FormDataConsumer,
  Edit,
  required,
  FunctionField,
  ImageField,
  SimpleForm,
  maxLength,
  minValue,
} from 'react-admin';
import { AdminSaveButton } from '../buttons/save-btn';
import { FormType, imageInputAccept } from '../variables';
import { AdminDeleteButton } from '../buttons/delete-btn';
import { ArrayInputIterator } from '../inputs/array-input-iterator';
import { NumberInput } from '../inputs/number-input';

const priorities = Array.from({ length: 20 }, (_, index) => ({ id: `${index + 1}`, name: `${index + 1}` }));

enum TypeSlider {
  WEB = 'web',
  APP = 'app',
}

const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Story Name" source="q" alwaysOn={true} />
    <BooleanInput label="Enabled" source="enable" alwaysOn={true} />
  </Filter>
);

const StoriesToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <AdminSaveButton {...props} mutationOptions redirect={props.basePath} disabled={props.pristine} />
    </Toolbar>
  );
};

const getInputs = (data: Record<string, any> | undefined, getSource: ((source: string) => string) | undefined) => {
  if (!data) {
    return null;
  }

  if (data.action_type === TypeSlider.APP) {
    return (
      <div style={{ display: 'flex', gap: '10px' }}>
        <TextInput label="Route name" source={getSource ? getSource('app.routeName') : 'app.routeName'} />
        <TextInput label="Route Params" source={getSource ? getSource('app.params') : 'app.params'} />
      </div>
    );
  }

  return <TextInput label="Link" source={getSource ? getSource('action_link') : 'action_link'} title="Link" />;
};

const Form = (props) => {
  return (
    <SimpleForm {...props} toolbar={<StoriesToolbar type={props.type} />}>
      <BooleanInput source="enable" label="Enabled" defaultValue={false} />

      <SelectInput source="priority" label="Priority" choices={priorities} />

      <TranslatableInputs locales={['en', 'ru']} defaultLocale="en" groupKey="name-group">
        <TextInput source="name" validate={[required(), maxLength(20)]} />
      </TranslatableInputs>

      <FunctionField
        label="Image"
        render={(record) =>
          record?.image_name && <img src={record.image_name} alt="image" style={{ maxHeight: '200px' }} />
        }
      />
      <ImageInput source="image_name" label="Upload Story Logo" accept={imageInputAccept} validate={required()}>
        <ImageField source="src" title="Image" />
      </ImageInput>
      <hr />

      <FormDataConsumer>
        {({ formData }) => (
          <ArrayInput source="slides" label={'Sliders'} validate={required()}>
            <ArrayInputIterator disableAdd={formData?.slides?.length >= 5}>
              <NumberInput source="order" validate={[required(), minValue(0)]} />

              <FormDataConsumer>
                {({ getSource }) => (
                  <TranslatableInputs locales={['en', 'ru']} defaultLocale="en" groupKey="text-group">
                    <TextInput label="Text" source={getSource ? getSource('text') : 'text'} validate={maxLength(40)} />
                  </TranslatableInputs>
                )}
              </FormDataConsumer>
              <FormDataConsumer>
                {({ scopedFormData }) =>
                  typeof scopedFormData?.image_name === 'string' && (
                    <img src={scopedFormData.image_name} alt="image" style={{ maxHeight: '200px' }} />
                  )
                }
              </FormDataConsumer>
              <ImageInput
                validate={required()}
                source="image_name"
                label="Upload Preview Image (320 x 200)"
                accept={imageInputAccept}
              >
                <ImageField source="src" title="Image" />
              </ImageInput>

              <SelectInput
                source="action_type"
                label="Type"
                choices={[
                  { id: TypeSlider.WEB, name: 'Web' },
                  { id: TypeSlider.APP, name: 'App' },
                ]}
              />

              <FormDataConsumer>
                {({ scopedFormData, getSource }) => getInputs(scopedFormData, getSource)}
              </FormDataConsumer>

              <FormDataConsumer>
                {({ getSource }) => (
                  <TranslatableInputs locales={['en', 'ru']} defaultLocale="en" groupKey="action_text-group">
                    <TextInput
                      label="Button text"
                      source={getSource ? getSource('action_text') : 'action_text'}
                      validate={maxLength(25)}
                    />
                  </TranslatableInputs>
                )}
              </FormDataConsumer>
            </ArrayInputIterator>
          </ArrayInput>
        )}
      </FormDataConsumer>
    </SimpleForm>
  );
};

export const MobileStoriesCreate = (props) => (
  <Create {...props}>
    <Form {...props} type={FormType.CREATE} />
  </Create>
);

export const MobileStoriesEdit = (props) => (
  <Edit {...props}>
    <Form {...props} type={FormType.EDIT} />
  </Edit>
);

export const MobileStoriesList = (props) => (
  <List {...props} perPage={50} filters={<Filters />}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="id" />
      <TextField source="name.en" label="Name" sortable={false} />
      <TextField source="priority" />
      <TextField source="count_slides" />
      <BooleanField source="enable" />
      <EditButton />
      <AdminDeleteButton />
    </Datagrid>
  </List>
);
