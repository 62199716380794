import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  TextInput,
  Create,
  TabbedForm,
  FormTab,
  ArrayInput,
  SelectInput,
  Filter,
  BooleanInput,
  BulkDeleteWithUndoButton,
  useRecordContext,
  required,
} from 'react-admin';
import styled from 'styled-components';
import { locales } from '../variables';
import { SortingDirection } from '../variables';
import { AdminDeleteButton } from '../buttons/delete-btn';
import { DefaultToolBar } from '../toolbars/default-toolbar';
import { getIsDisabledByRole } from '../utils/get-disabled-by-role';
import { ArrayInputIterator } from '../inputs/array-input-iterator';
import { TrimOnBlurInput } from '../inputs/trim-on-blur-input';
import { helperTexts } from '../variables/helper-texts';

const CategoryLocalization = styled.div`
  width: 100% !important;
  & .MuiTypography-root {
    display: none;
  }
`;

const CategoryFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Name" source="q" alwaysOn />
  </Filter>
);

export const CoinCategoryList = (props) => (
  <List {...props} sort={{ field: 'id', order: SortingDirection.ASC }} perPage={50} filters={<CategoryFilter />}>
    <Datagrid bulkActionButtons={<BulkDeleteWithUndoButton {...props} disabled={getIsDisabledByRole()} />}>
      <TextField source="id" />
      <TextField source="name" />
      <EditButton />
      <AdminDeleteButton />
    </Datagrid>
  </List>
);

const CoinCategoryTitle = () => {
  const record = useRecordContext();
  return <span>Coin Category '{record?.name}'</span>;
};

const getForm = (type: 'edit' | 'create') => (
  <TabbedForm toolbar={<DefaultToolBar />}>
    <FormTab label="summary">
      <TrimOnBlurInput source="name" />
      {type === 'edit' ? (
        <>
          <TextInput source="slug" helperText={helperTexts.keyInputValidation} />
          <BooleanInput label="ICO List" source="isIcoList" />
        </>
      ) : null}
    </FormTab>
    <FormTab label="localization">
      <CategoryLocalization>
        <ArrayInput label="Category Localization" source="localization" validate={required()}>
          <ArrayInputIterator>
            <SelectInput label="Locale" source="locale" choices={locales} validate={required()} />
            <TrimOnBlurInput source="name" validate={required()} />
          </ArrayInputIterator>
        </ArrayInput>
      </CategoryLocalization>
    </FormTab>
  </TabbedForm>
);

export const CoinCategoryEdit = (props) => (
  <Edit title={<CoinCategoryTitle />} {...props}>
    {getForm('edit')}
  </Edit>
);

export const CoinCategoryCreate = (props) => <Create {...props}>{getForm('create')}</Create>;
