import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  TextInput,
  Create,
  SimpleForm,
  BulkDeleteWithUndoButton,
  ImageField,
  ImageInput,
  required,
} from 'react-admin';
import { DefaultToolBar } from '../../toolbars/default-toolbar';
import { getIsDisabledByRole } from '../../utils/get-disabled-by-role';
import { NumberInput } from '../../inputs/number-input';
import { imageInputAccept } from '../../variables';

const Form = (
  <SimpleForm toolbar={<DefaultToolBar />}>
    <TextInput source="locale.en.name" required label="En Name" />
    <TextInput source="locale.en.title" label="En H1 Title" />

    <TextInput source="locale.ru.name" required label="Ru Name" />
    <TextInput source="locale.ru.title" label="Ru H1 Title" />

    <TextInput source="slug" required label="Url slug" />
    <NumberInput source="position" label="Position" max={10} min={1} />

    <ImageInput source="icon" accept={imageInputAccept} validate={required()}>
      <ImageField title="icon" />
    </ImageInput>

    <ImageInput source="background" label="background" accept={imageInputAccept}>
      <ImageField title="background" />
    </ImageInput>
  </SimpleForm>
);

export const CategoriesCreate = (props) => {
  return <Create {...props}>{Form}</Create>;
};

export const CategoriesEdit = (props) => <Edit {...props}>{Form}</Edit>;

export const CategoriesList = (props) => (
  <List {...props} perPage={50}>
    <Datagrid bulkActionButtons={<BulkDeleteWithUndoButton {...props} disabled={getIsDisabledByRole()} />}>
      <TextField source="slug" label="Slug" />
      <TextField source="position" label="Position" />
      <EditButton />
    </Datagrid>
  </List>
);
