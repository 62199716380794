import { FunctionField, ImageField, ImageInput, Validator } from 'react-admin';
import { getEnvs } from '../../env-lib';
import { imageInputAccept, S3ImageDir } from '../../variables';

const imagesBasePath = getEnvs().REACT_APP_IMAGES_BASE_URL + S3ImageDir.SRENNAB;

type Props = {
  validate?: Validator | Validator[];
  imageSize?: string;
  src?: string;
  recordSrc?: string;
};

export const ImageWithPreview = (props: Props) => {
  return (
    <>
      <FunctionField
        label="Image"
        render={(record) =>
          record?.[props.recordSrc || 'imageName'] ? (
            <img src={imagesBasePath + record?.[props.recordSrc || 'imageName']} alt="image" />
          ) : null
        }
      />
      <ImageInput
        source={props.src || `image`}
        label={`Image ${props.imageSize ? props.imageSize : ''}`}
        accept={imageInputAccept}
        validate={props.validate}
      >
        <ImageField source={'src'} title="Image" />
      </ImageInput>
    </>
  );
};

export const IconWithPreview = (props) => {
  return (
    <>
      <FunctionField
        label="Icon"
        render={(record) => (record?.iconName ? <img src={imagesBasePath + record.iconName} alt="icon" /> : null)}
      />
      <ImageInput source="icon" label="Icon" accept={imageInputAccept}>
        <ImageField source="src" title="Icon" />
      </ImageInput>
    </>
  );
};
