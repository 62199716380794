import { TextInput } from 'ra-ui-materialui';
import { ImageWithPreview } from './components';
import { maxLength } from 'react-admin';

export const NewsBanner = (props) => {
  return (
    <>
      <TextInput source="title" fullWidth={true} validate={maxLength(30)}  />
      <TextInput source="description" fullWidth={true} validate={maxLength(60)}  />
      <TextInput source="buttonText" validate={maxLength(20)}  />
      <TextInput source="url" label="Link URL" helperText="URL with https://" fullWidth={true} />
      <ImageWithPreview />
    </>
  );
};
