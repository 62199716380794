import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  Filter,
  ReferenceInput,
  ReferenceField,
  FormDataConsumer,
  BulkDeleteWithUndoButton,
} from 'react-admin';
import { AutocompleteInput } from '../inputs/autocomplete-input';
import { SortingDirection } from '../variables';
import { AdminDeleteButton } from '../buttons/delete-btn';
import { DefaultToolBar } from '../toolbars/default-toolbar';
import { getIsDisabledByRole } from '../utils/get-disabled-by-role';

const CoinMappingFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Symbol" source="symbol" alwaysOn={true} />
    <ReferenceInput label="Coin" source="coinId" reference="coins" sort={{ field: 'id', order: SortingDirection.ASC }}>
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      label="Exchange"
      source="exchangeId"
      reference="exchanges"
      sort={{ field: 'id', order: SortingDirection.ASC }}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

export const CoinMappingList = (props) => (
  <List {...props} sort={{ field: 'id', order: SortingDirection.ASC }} perPage={50} filters={<CoinMappingFilter />}>
    <Datagrid bulkActionButtons={<BulkDeleteWithUndoButton {...props} disabled={getIsDisabledByRole()} />}>
      <TextField source="id" />
      <ReferenceField label="Coin" source="coinId" reference="coins">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Exchange" source="exchangeId" reference="exchanges">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="symbol" />
      <EditButton />
      <AdminDeleteButton />
    </Datagrid>
  </List>
);

const CoinMappingTitle = () => {
  return <span>Mapping edit</span>;
};

const CoinSelectInput = (
  <ReferenceInput
    label="Coin"
    source="coinId"
    reference="coins"
    perPage={10}
    sort={{ field: 'id', order: SortingDirection.ASC }}
  >
    <AutocompleteInput optionText="name" />
  </ReferenceInput>
);

export const CoinMappingEdit = (props) => (
  <Edit title={<CoinMappingTitle />} {...props}>
    <SimpleForm toolbar={<DefaultToolBar />}>
      {CoinSelectInput}
      <ReferenceField label="Exchange" source="exchangeId" reference="exchanges">
        <TextField source="name" />
      </ReferenceField>
      <TextInput disabled={true} source="symbol" />
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          !formData.coinId && (
            <>
              <TextInput label="Name" source="coin.name" />
              <TextInput label="Symbol" source="coin.symbol" />
              <TextInput label="Key" source="coin.key" />
            </>
          )
        }
      </FormDataConsumer>
      <div style={{ height: '200px' }} />
    </SimpleForm>
  </Edit>
);

export const CoinMappingCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      {CoinSelectInput}
      <ReferenceInput
        label="Exchange"
        source="exchangeId"
        reference="exchanges"
        perPage={10}
        sort={{ field: 'id', order: SortingDirection.ASC }}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <TextInput source="symbol" />
      <div style={{ height: '200px' }} />
    </SimpleForm>
  </Create>
);
