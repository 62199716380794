import styled from 'styled-components';
import { Box } from '@mui/material';

export const PlayButton = styled.button`
  position: absolute;
  bottom: -4px;
  left: 50%;
  transform: translateX(-50%);
  width: 23px;
  height: 23px;
  border-radius: 50%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${(p) => !p.disabled && 'pointer'};

  > svg {
    width: 28px;
    height: 28px;
    fill: #90caf9;
  }
`;

export const ResponseTextWrapper = styled.div`
  margin-top: 12px;
  word-break: break-all;
`;

export const FieldWrapper = styled(Box).attrs({ border: 1, borderColor: 'divider' })`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 752px;
  border-radius: 6px;
  padding: 12px;
`;

export const ScriptResultWrapper = styled(Box).attrs({ border: 1, borderColor: 'divider' })`
  width: 50%;
  padding: 20px 10px;
  color: #00620a;
  font-size: 18px;
  background-color: #000;
  margin: 8px 0;
  border-radius: 0 6px 0 0;
  display: flex;
`;
