import { useEffect, useState } from 'react';
import { getLiFiDashboard } from '../../api-service';
import { Loading, Pagination } from 'ra-ui-materialui';
import { LoaderWrapper } from '../../dashboard/loader-wrapper';
import { LifiDashboardInfo, LiFiDashboardOrderBy, LifiParams } from './types';
import { SortingDirection } from '../../variables';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { TableSortLabel } from '@mui/material';
import { getRole } from '../../utils/get-role';
import { UserRole } from '../../variables/user-role';

const ClickableTableHeaders = [
  {
    name: LiFiDashboardOrderBy.BLOCKCHAIN,
    title: 'Chain',
  },
  {
    name: LiFiDashboardOrderBy.COINNAME,
    title: 'Name',
  },
  {
    name: LiFiDashboardOrderBy.AMOUNT,
    title: 'Fee, USD',
  },
  {
    name: LiFiDashboardOrderBy.AMOUNT,
    title: 'Volume, USD',
    sortable: false,
  },
];

const customStyles = {
  fontWeight: 'bold',
};

export const LiFiDashboard = () => {
  const [lifiInfo, setLifiInfo] = useState<LifiDashboardInfo>();
  const [params, setParams] = useState<LifiParams>({
    offset: 0,
    limit: 25,
    orderBy: LiFiDashboardOrderBy.AMOUNT,
    orderDirection: SortingDirection.DESC,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isObserver = getRole() === UserRole.OBSERVER;
  const commissionPercent = 0.0035;

  useEffect(() => {
    const fetchLifiInfo = async () => {
      try {
        setIsLoading(true);
        const data = await getLiFiDashboard(params);
        setLifiInfo(data);
      } catch (error) {
        console.error('Failed to fetch LiFi info:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchLifiInfo();
  }, [params]);

  if (!lifiInfo || isLoading) {
    return <Loading />;
  }

  if (isObserver) {
    return null;
  }

  const setPage = (page: number) => {
    setParams({ ...params, offset: params.limit * (page - 1) });
  };

  const setRowsPerPage = (rowsPerPage: number) => {
    setParams({ ...params, limit: rowsPerPage });
  };

  const total = lifiInfo.count;
  const page = Math.floor(params.offset / params.limit) + 1;
  const handleSort = (value: LiFiDashboardOrderBy) => {
    const isAsc = params.orderDirection === SortingDirection.ASC;
    setParams({ ...params, orderBy: value, orderDirection: isAsc ? SortingDirection.DESC : SortingDirection.ASC });
  };

  return (
    <LoaderWrapper>
      {lifiInfo && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={3}></TableCell>
                <TableCell style={customStyles}>Total fee, USD {lifiInfo.data.totalAmount.toFixed(2)}</TableCell>
                <TableCell style={customStyles}>Total Volume, USD {(lifiInfo.data.totalAmount / commissionPercent).toFixed(2)}</TableCell>
              </TableRow>
              <TableRow style={customStyles}>
                <TableCell style={customStyles}>#</TableCell>
                {ClickableTableHeaders.map(({ name, title, sortable = true }) => (
                  <TableCell key={title} style={customStyles}>
                    <TableSortLabel
                      disabled={sortable === false}
                      active={sortable && params.orderBy === name}
                      direction={params.orderDirection === SortingDirection.DESC ? 'desc' : 'asc'}
                      onClick={() => handleSort(name)}
                    >
                      {title}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {lifiInfo.data.swaps.map(({ coinName, blockchain, amount }, index) => {
                const countNumber = index + 1 + params.offset;
                return (
                  <TableRow key={'table-row_' + coinName + index + amount + blockchain + countNumber}>
                    <TableCell>{countNumber}</TableCell>
                    <TableCell>{blockchain}</TableCell>
                    <TableCell>{coinName}</TableCell>
                    <TableCell>{amount}</TableCell>
                    <TableCell>{Math.round(amount / commissionPercent)}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <Pagination
            page={page}
            setPage={setPage}
            total={total}
            rowsPerPageOptions={[25, 50]}
            setPerPage={setRowsPerPage}
            perPage={params.limit}
          />
        </TableContainer>
      )}
    </LoaderWrapper>
  );
};
