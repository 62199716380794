import { Button } from 'react-admin';
import { addNewsLogo, getNewsLogos, removeNewsLogo } from '../../api-service';
import { useEffect, useRef, useState } from 'react';
import { Fab } from '@mui/material';
import { RemoveCircle, Save } from '@mui/icons-material';
import { getIsDisabledByRole } from '../../utils/get-disabled-by-role';
import { imageInputAccept } from '../../variables';

export const NewsLogoList = () => {
  const [logos, setLogos] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [newLogo, setNewLogo] = useState<any>();
  const inputRef = useRef<HTMLInputElement>(null);
  const isDisabledByRole = getIsDisabledByRole();
  useEffect(() => {
    getNewsLogos().then((logosData) => setLogos(logosData.data.map((logoUrl) => logosData.baseUrl + logoUrl)));
  }, []);

  const onRemoveItem = (deleteLogo) => () => {
    const logoName = deleteLogo.split('/').reverse()[0];
    removeNewsLogo(logoName);
    setLogos(logos?.filter((logo) => logo !== deleteLogo));
  };

  const onSubmit = () => {
    if (!newLogo) {
      return;
    }
    setIsLoading(true);
    const format: string = newLogo.split(';')[0].split('/')[1];

    addNewsLogo({ image: newLogo, name: new Date().getTime() + format }).then(() => {
      setLogos([newLogo, ...logos]);
      setNewLogo(undefined);
      if (inputRef?.current?.value !== undefined) {
        inputRef.current.value = '';
      }
      setIsLoading(false);
    });
  };

  const onChange = async ({ target: { files } }) => {
    const file = files[0];

    if (!file) {
      setNewLogo(undefined);
      return;
    }

    const fileSize = file.size / 1024 / 1024; // in MiB
    if (fileSize > 1) {
      alert('File size exceeds 1 MiB');
      setNewLogo(undefined);
      return;
    }

    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.addEventListener('load', () => {
      setNewLogo(reader.result);
    });
  };

  return (
    <>
      <br />

      <label htmlFor="image_uploads">Add news image (PNG, JPG, JPEG)</label>
      <input
        ref={inputRef}
        id="image_uploads"
        name="image_uploads"
        type="file"
        onChange={onChange}
        style={{ marginTop: 20, marginBottom: 20 }}
        accept={imageInputAccept}
      />
      {newLogo && <img width={300} src={newLogo} alt="new-logo" />}
      <Button
        disabled={!newLogo || isLoading || isDisabledByRole}
        onClick={onSubmit}
        type="submit"
        color="primary"
        variant="contained"
        startIcon={<Save />}
        style={{ marginBottom: 20, width: 150, marginTop: 20 }}
      >
        <>
          <span>{isLoading ? 'Loading...' : 'Save'}</span>
        </>
      </Button>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
        {logos?.map((logo) => (
          <div key={logo} style={{ display: 'flex', gap: 30, alignItems: 'center' }}>
            <img src={logo} width={300} alt={logo} />
            <Fab disabled={isDisabledByRole} size="small" onClick={onRemoveItem(logo)} tabIndex={-1}>
              <RemoveCircle color="error" />
            </Fab>
          </div>
        ))}
      </div>
    </>
  );
};
