import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  Create,
  TopToolbar,
  CreateButton,
  ListButton,
  RefreshButton,
  DateField,
  TextInput,
  ImageInput,
  ImageField,
  BooleanInput,
  DateTimeInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  FormDataConsumer,
  BulkDeleteWithUndoButton,
  ReferenceInput,
  AutocompleteInput,
  TabbedForm,
  required,
  FunctionField,
  BooleanField,
  DateInput,
} from 'react-admin';
import { imageInputAccept, SortingDirection } from '../../variables';
import { TinymceEditor } from '../../inputs/tinymce-editor';
import { getIsDisabledByRole } from '../../utils/get-disabled-by-role';
import { DefaultToolBar } from '../../toolbars/default-toolbar';
import { AdminDeleteButton } from '../../buttons/delete-btn';
import { TrimOnBlurInput } from '../../inputs/trim-on-blur-input';
import { helperTexts } from '../../variables/helper-texts';
import { addIdToMainText, generateTimestampID, getArticleNavigation } from '../../utils/articles-parser';

const convertData = (data) => {
  const timestampId = generateTimestampID();

  return {
    ...data,
    locale: {
      ...data.locale,
      en: {
        ...data.locale.en,
        main_text: addIdToMainText(data.locale.en.main_text, timestampId),
        article_navigation: getArticleNavigation(data.locale.en.main_text, timestampId),
      },
      ru: {
        ...data.locale.ru,
        main_text: addIdToMainText(data.locale.ru.main_text, timestampId),
        article_navigation: getArticleNavigation(data.locale.ru.main_text, timestampId),
      },
    },
  };
};

const Form = () => {
  return (
    <TabbedForm noValidate={true} toolbar={<DefaultToolBar />}>
      <TabbedForm.Tab label="Main">
        <ReferenceInput label="Author" reference="articles-authors" source="author_id">
          <AutocompleteInput optionText="name" optionValue="id" validate={required()} />
        </ReferenceInput>

        <TextInput label="Page url" source="slug" validate={required()} helperText={helperTexts.keyInputValidation} />

        <FunctionField
          label="Uploaded image"
          render={(record) =>
            record.lead_image && <img src={record.lead_image} alt="Logo" style={{ maxHeight: '400px' }} />
          }
        />

        <ImageInput source="lead_image" label="Image" accept={imageInputAccept} validate={required()}>
          <ImageField title="Image" />
        </ImageInput>

        <BooleanInput defaultValue={true} source="is_lead_image_active" label="Show preview image" />
        <div style={{ fontSize: 12, margin: '-20px 0 20px', width: 500 }}>
          If disabled preview image won't be displayed in article's body
        </div>

        <ReferenceInput label="Subcategory" source="subcategory_id" reference="articles-subcategories" required>
          <AutocompleteInput optionText="slug" validate={required()} />
        </ReferenceInput>

        <BooleanInput source="is_sponsored" label="Sponsored" />
        <BooleanInput source="exclusive" defaultValue={false} />

        <DateTimeInput label="Publish date" source="publish_date" validate={required()} />

        <FormDataConsumer>
          {({ formData }) => (
            <BooleanInput
              disabled={formData.is_2nd_sort_featured}
              source="is_1st_sort_featured"
              label="1st Sort Featured"
            />
          )}
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData }) => (
            <BooleanInput
              disabled={formData.is_1st_sort_featured}
              source="is_2nd_sort_featured"
              label="2nd Sort Featured"
            />
          )}
        </FormDataConsumer>

        <ReferenceArrayInput
          sort={{ field: 'name', order: SortingDirection.ASC }}
          source="coinsIds"
          reference="coins"
          filter={{ enabled: true }}
        >
          <AutocompleteArrayInput label="Widget coins" optionText={(record) => `${record.name} (${record.symbol})`} />
        </ReferenceArrayInput>

        <BooleanInput source="show_on_coin_page" />

        <ReferenceArrayInput
          label="Widget funds"
          sort={{ field: 'name', order: SortingDirection.ASC }}
          source="fundsIds"
          reference="funds"
        >
          <AutocompleteArrayInput optionText="name" />
        </ReferenceArrayInput>

        <BooleanInput source="show_on_fund_page" />

        <DateInput source="show_on_page_until" />
      </TabbedForm.Tab>

      <TabbedForm.Tab label="En" aria-required={false}>
        <TrimOnBlurInput source="locale.en.title" label="Title" />

        <div style={{ marginBottom: 20 }}>Lead Text</div>

        <TinymceEditor maxLength={500} disableMarkdown source="locale.en.lead_text" />

        <div style={{ marginBottom: 20 }}>Key Takeaways</div>
        <TextInput style={{ width: '600px' }} label="Key Takeaways header" source="locale.en.key_takeaways_header" />

        <TinymceEditor source="locale.en.key_takeaways_body" />

        <div style={{ marginBottom: 20 }}>Main Text</div>

        <TinymceEditor source="locale.en.main_text" />

        <TextInput label="CTA Button" source="locale.en.cta_button_text" />
        <TextInput label="CTA Button URL" source="locale.en.cta_button_url" />

        <ReferenceArrayInput source="tagsIds.en" reference="content-tags">
          <AutocompleteArrayInput optionText="name" label="En Locale Tags" />
        </ReferenceArrayInput>

        <ReferenceArrayInput source="relatedArticlesIds.en" reference="articles">
          <AutocompleteArrayInput
            optionText="slug"
            optionValue="id"
            label="Related Articles En"
            style={{ width: 600 }}
          />
        </ReferenceArrayInput>
      </TabbedForm.Tab>

      <TabbedForm.Tab label="Ru">
        <TrimOnBlurInput source="locale.ru.title" label="Title" />

        <div style={{ marginBottom: 20 }}>Lead Text</div>

        <TinymceEditor maxLength={500} disableMarkdown source="locale.ru.lead_text" />

        <div style={{ marginBottom: 20 }}>Key Takeaways</div>
        <TextInput style={{ width: '600px' }} label="Key Takeaways header" source="locale.ru.key_takeaways_header" />

        <TinymceEditor source="locale.ru.key_takeaways_body" />

        <div style={{ marginBottom: 20 }}>Main Text</div>

        <TinymceEditor source="locale.ru.main_text" />

        <TextInput label="CTA Button" source="locale.ru.cta_button_text" />
        <TextInput label="CTA Button URL" source="locale.ru.cta_button_url" />

        <ReferenceArrayInput source="tagsIds.ru" reference="content-tags">
          <AutocompleteArrayInput optionText="name" label="Ru Locale Tags" />
        </ReferenceArrayInput>
        <ReferenceArrayInput source="relatedArticlesIds.ru" reference="articles">
          <AutocompleteArrayInput
            optionText="slug"
            optionValue="id"
            label="Related Articles Ru"
            style={{ width: 600 }}
          />
        </ReferenceArrayInput>
      </TabbedForm.Tab>
    </TabbedForm>
  );
};

const EditActions = (props) => {
  return (
    <TopToolbar>
      <CreateButton />
      <ListButton />
      <RefreshButton />
    </TopToolbar>
  );
};

export const ArticlesEdit = (props) => {
  return (
    <Edit actions={<EditActions />} transform={convertData} title={<span>Link</span>} {...props}>
      <Form {...props} />
    </Edit>
  );
};

export const ArticlesCreate = (props) => {
  return (
    <Create transform={convertData} {...props}>
      <Form {...props} />
    </Create>
  );
};

const imageFieldStyle = {
  '&& > img': {
    width: 40,
    height: 'auto',
  },
};

export const ArticlesList = (props) => (
  <List {...props} sort={{ field: 'id', order: SortingDirection.DESC }} perPage={50}>
    <Datagrid bulkActionButtons={<BulkDeleteWithUndoButton {...props} disabled={getIsDisabledByRole()} />}>
      <ImageField source="lead_image" label="Image" sx={imageFieldStyle} />
      <TextField source="slug" label="Slug" />
      <DateField source="publish_date" label="Publish date" />
      <BooleanField source="enLocale" sortable={false} />
      <BooleanField source="ruLocale" sortable={false} />
      <EditButton />
      <AdminDeleteButton />
    </Datagrid>
  </List>
);
