import { useEffect, useState } from 'react';

import {
  List,
  Datagrid,
  TextField,
  TextInput,
  SimpleForm,
  Edit,
  NumberInput,
  Create,
  SelectInput,
  EditButton,
  useEditController,
} from 'react-admin';
import { getQuizPresets } from '../../api-service';

export const QuizConfigListList = (props) => {
  return (
    <List perPage={50} {...props}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" label="Id" />
        <TextField source="preset" label="Preset" />
        <TextField source="answer_delta" label="Answer delta" />
        <TextField source="decimal_point" label="Decimal point" />
        <TextField source="coin" label="Coin" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export const QuizConfigEdit = (props) => {
  const { record } = useEditController();

  const [selectedPreset, setSelectedPreset] = useState(null);
  const [presets, setPresets] = useState([]);

  useEffect(() => {
    if (record?.preset) {
      setSelectedPreset(record.preset);
    }
    const fetchData = async () => {
      try {
        const res = await getQuizPresets();
        const selectData = res.map((preset) => ({ id: preset, name: preset }));
        setPresets(selectData);
      } catch (error) {
        console.error('Error fetching presets', error);
      }
    };

    fetchData();
  }, [record]);

  const handleSelectPreset = (preset) => {
    if (!preset) {
      setSelectedPreset(null);
    }
    setSelectedPreset(preset.target.value);
  };

  const transformData = (data) => {
    if (selectedPreset !== 'coin_price') {
      return {
        ...data,
        coin: null,
      };
    } else {
      return data;
    }
  };

  return (
    <Edit {...props} redirect={(basePath) => basePath} transform={transformData}>
      <SimpleForm>
        <SelectInput source="preset" label="Select Preset" choices={presets} onChange={handleSelectPreset} required />
        <NumberInput source="answer_delta" label="Answer delta" />
        <NumberInput source="decimal_point" label="Decimal point" />
        {selectedPreset === 'coin_price' && <TextInput source="coin" label="Coin" />}
      </SimpleForm>
    </Edit>
  );
};

export const QuizConfigCreate = (props) => {
  const [selectedPreset, setSelectedPreset] = useState(null);
  const [presets, setPresets] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getQuizPresets();
        const selectData = res.map((preset) => ({ id: preset, name: preset }));
        setPresets(selectData);
      } catch (error) {
        console.error('Error fetching presets', error);
      }
    };

    fetchData();
  }, []);

  const handleSelectPreset = (preset) => {
    if (!preset) {
      setSelectedPreset(null);
    }
    setSelectedPreset(preset.target.value);
  };
  return (
    <Create {...props} redirect={(basePath) => basePath}>
      <SimpleForm>
        {presets ? (
          <SelectInput source="preset" label="Select Preset" choices={presets} onChange={handleSelectPreset} required />
        ) : (
          <p>Error fetching presets</p>
        )}
        <NumberInput source="answer_delta" label="Answer delta" />
        <NumberInput source="decimal_point" label="Decimal point" />
        {selectedPreset === 'coin_price' && <TextInput source="coin" label="Coin" />}
      </SimpleForm>
    </Create>
  );
};
