import { Card, CardHeader } from '@mui/material';
import { ProjectStatistics } from './project-statistics';

export const Dashboard = () => {
  return (
    <Card>
      <CardHeader title="Welcome" />
      <ProjectStatistics />
    </Card>
  );
};
