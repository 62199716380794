import {
  List,
  Datagrid,
  TextField,
  EditButton,
  TextInput,
  TopToolbar,
  ListButton,
  RefreshButton,
  FormDataConsumer,
  Edit,
  Create,
  CreateButton,
  SimpleForm,
  BulkDeleteWithUndoButton,
  required,
  NumberField,
  ArrayInput,
  maxLength,
} from 'react-admin';
import { SortingDirection } from '../../variables';
import { getIsDisabledByRole } from '../../utils/get-disabled-by-role';
import { FormType } from '../../variables';
import { AdminDeleteButton } from '../../buttons/delete-btn';
import { ArrayInputIterator } from '../../inputs/array-input-iterator';

const EditActions = () => (
  <TopToolbar>
    <CreateButton />
    <ListButton />
    <RefreshButton />
  </TopToolbar>
);

const Form = (props: { type: FormType }) => (
  <SimpleForm>
    <TextInput source="key" validate={[required(), maxLength(30)]} />
    <FormDataConsumer>
      {({ formData }) => (
        <ArrayInput
          source="locales"
          defaultValue={props.type === FormType.CREATE ? [{ lang: 'en' }, { lang: 'ru' }] : undefined}
        >
          <ArrayInputIterator
            disableAdd
            disableRemove
            disableReordering
            getItemLabel={(index) => formData.locales?.[index]?.lang}
            source="locales"
          >
            <TextInput source="name" validate={[required()]} />
            <TextInput source="description" />
          </ArrayInputIterator>
        </ArrayInput>
      )}
    </FormDataConsumer>
  </SimpleForm>
);

export const EcosystemTagsCreate = () => {
  return (
    <Create redirect="list">
      <Form type={FormType.CREATE} />
    </Create>
  );
};

export const EcosystemTagsEdit = () => {
  return (
    <Edit actions={<EditActions />}>
      <Form type={FormType.EDIT} />
    </Edit>
  );
};

export const EcosystemTagsList = () => (
  <List
    sort={{ field: 'id', order: SortingDirection.ASC }}
    perPage={50}
    filters={[<TextInput source="name" alwaysOn={true} />]}
  >
    <Datagrid bulkActionButtons={<BulkDeleteWithUndoButton disabled={getIsDisabledByRole()} />}>
      <NumberField source="id" />
      <TextField source="key" sortable={false} />
      <EditButton />
      <AdminDeleteButton />
    </Datagrid>
  </List>
);
