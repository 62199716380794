import {
  ClearButtons,
  FormatButtons,
  LevelSelect,
  LinkButtons,
  ListButtons,
  RichTextInputToolbar,
} from 'ra-input-rich-text';

export const RichTextInputCustomToolbar = (props) => {
  return (
    <RichTextInputToolbar {...props}>
      <LevelSelect />
      <FormatButtons />
      <ListButtons />
      <LinkButtons />
      <ClearButtons />
    </RichTextInputToolbar>
  );
};
