import { TextInput } from 'ra-ui-materialui';
import { IconWithPreview, ImageWithPreview } from './components';
import { maxLength } from 'react-admin';

export const HeaderTextBanner = (props) => {
  return (
    <>
      <TextInput source="title" fullWidth={true} validate={maxLength(30)} />
      <TextInput source="description" fullWidth={true} validate={maxLength(60)} />
      <TextInput source="descriptionMobile" fullWidth={true} validate={maxLength(60)} />
      <TextInput source="callToAction" label="Button text" validate={maxLength(50)} />
      <TextInput source="url" label="Link URL" helperText="URL with https://" fullWidth={true} />
      <ImageWithPreview />
      <IconWithPreview />
    </>
  );
};
