import { FiberNew } from '@mui/icons-material';
import { useRecordContext } from 'react-admin';

export const NewField = ({ source, ...rest }) => {
  const record = useRecordContext();

  if (!record[source]) {
    return null;
  }

  return <FiberNew titleAccess="New" {...rest} />;
};
