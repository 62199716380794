import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  TextInput,
  Create,
  SimpleForm,
  BulkDeleteWithUndoButton,
  required,
  Toolbar,
  BooleanInput,
  SelectInput,
  ReferenceArrayInput,
  BooleanField,
  Filter,
  SelectArrayInput,
} from 'react-admin';
import { FormType, SortingDirection } from '../variables';
import { getIsDisabledByRole } from '../utils/get-disabled-by-role';
import { AdminSaveButton } from '../buttons/save-btn';
import { ApiTariffType } from '../variables/api-tarrif-types';
import { NumberInput } from '../inputs/number-input';
import { AppResourceNames } from '../variables/app-resource-names';
import { VestingIntervalsMap } from '../variables/vesting-intervals';

const ApiPlansFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Name" source="q" alwaysOn />
  </Filter>
);

export const ApiPlansList = (props) => (
  <List {...props} sort={{ field: 'id', order: SortingDirection.ASC }} perPage={50} filters={<ApiPlansFilter />}>
    <Datagrid bulkActionButtons={<BulkDeleteWithUndoButton {...props} disabled={getIsDisabledByRole()} />}>
      <TextField source="id" />
      <TextField source="name" />
      <BooleanField source="enabled" />
      <EditButton />
    </Datagrid>
  </List>
);

const ApiPlansToolbar = () => {
  return (
    <Toolbar>
      <AdminSaveButton />
    </Toolbar>
  );
};

const Form = (props: { type: FormType }) => {
  return (
    <SimpleForm toolbar={<ApiPlansToolbar />}>
      <BooleanInput source="enabled" defaultValue={true} />
      <TextInput source="name" validate={required()} />
      <SelectInput
        source="type"
        choices={Object.values(ApiTariffType).map((name) => ({ id: name, name: name.toUpperCase() }))}
        validate={required()}
        disabled={props.type === FormType.EDIT}
      />
      <TextInput source="description" multiline={true} style={{ width: '35%' }} validate={required()} />
      <NumberInput source="creditsPerDay" validate={required()} />
      <NumberInput source="creditsPerMonth" validate={required()} />
      <TextInput source="pricingMonthly" validate={required()} />
      <TextInput source="pricingAnnually" validate={required()} />
      <ReferenceArrayInput source="apiEndpointIds" reference="api-endpoints" resource="api-endpoints">
        <SelectArrayInput optionText="path" defaultValue={[]} />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        source="requiredTariffIds"
        reference={AppResourceNames.API_TARIFFS}
        resource={AppResourceNames.API_TARIFFS}
      >
        <SelectArrayInput optionText="name" defaultValue={[]} />
      </ReferenceArrayInput>
      <NumberInput source="max_days_5m_historical" label="5 min points" validate={required()} />
      <NumberInput source="max_days_24h_historical" label="Daily points" defaultValue={null} />
      <SelectInput
        source="vesting_chart_min_interval"
        label="Vesting Interval"
        choices={Object.keys(VestingIntervalsMap).map((key) => ({ id: key, name: VestingIntervalsMap[key] }))}
      />
      <label style={{ marginTop: -20, fontSize: '0.75rem', opacity: 0.75 }}>
        This field is required for currencies/:id/vesting/chart endpoint. It sets minimum available interval depending on the plan.
      </label>
    </SimpleForm>
  );
};

export const ApiPlansEdit = () => (
  <Edit>
    <Form type={FormType.EDIT} />
  </Edit>
);

export const ApiPlansCreate = () => (
  <Create>
    <Form type={FormType.CREATE} />
  </Create>
);
