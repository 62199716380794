export type ArticleLink = { name: string; id: string };
export type ArticleNavigation = { link: ArticleLink; sublinks: ArticleLink[] }[];

export const generateTimestampID = () => {
  return Date.now().toString();
};

export const getIdFromString = (string: string, prefix?: string, uniqueId?: string): string => {
  let id = string
    .toLowerCase()
    .replace(/[^A-Za-zа-яА-Я0-9\s]/g, '')
    .trim()
    .replace(/\s+/g, '-');

  if (prefix) {
    id = `${prefix}-${id}`;
  }

  if (uniqueId) {
    id = `${id}-${uniqueId}`;
  }

  return id;
};

export const getTranslateString = (str: string): string => {
  const translitMap: Map<string, string> = new Map([
    ['а', 'a'],
    ['б', 'b'],
    ['в', 'v'],
    ['г', 'g'],
    ['д', 'd'],
    ['е', 'e'],
    ['є', 'e'],
    ['ё', 'e'],
    ['ж', 'j'],
    ['з', 'z'],
    ['и', 'i'],
    ['ї', 'yi'],
    ['й', 'i'],
    ['к', 'k'],
    ['л', 'l'],
    ['м', 'm'],
    ['н', 'n'],
    ['о', 'o'],
    ['п', 'p'],
    ['р', 'r'],
    ['с', 's'],
    ['т', 't'],
    ['у', 'u'],
    ['ф', 'f'],
    ['х', 'h'],
    ['ц', 'c'],
    ['ч', 'ch'],
    ['ш', 'sh'],
    ['щ', 'shch'],
    ['ы', 'y'],
    ['э', 'e'],
    ['ю', 'u'],
    ['я', 'ya'],
  ]);

  str = str.replace(/[ъь]+/g, '');

  return Array.from(str).reduce(
    (acc, symbol) =>
      acc +
      (translitMap.get(symbol) ||
        (translitMap.get(symbol.toLowerCase()) === undefined && symbol) ||
        translitMap.get(symbol.toLowerCase())?.toUpperCase()),
    ''
  );
};

export const getTitleElements = (text: string) => {
  const tempDiv = document.createElement('div');

  tempDiv.innerHTML = text;
  const elements = tempDiv.querySelectorAll('h2, h3');

  return { elements, tempDiv };
};

export const addIdToMainText = (text: string, additionalId: string) => {
  const { elements, tempDiv } = getTitleElements(text);

  elements.forEach((element) => {
    const id = getIdFromString(element?.textContent || '', 'heading', additionalId);
    const translatedId = getTranslateString(id);

    element.id = translatedId || '';
  });

  return tempDiv.innerHTML;
};

export const getArticleNavigation = (text: string, additionalId: string) => {
  const { elements } = getTitleElements(text);

  const articleNavigation: ArticleNavigation = [];
  let currentH2: { name: string; id: string } | null = null;

  elements?.forEach((element) => {
    const { tagName } = element;
    const name = element?.textContent?.trim();
    const newId = getTranslateString(getIdFromString(name || '', 'heading', additionalId));

    if (tagName === 'H2') {
      currentH2 = { name: name || '', id: newId };
      articleNavigation.push({ link: currentH2, sublinks: [] });
    } else if (tagName === 'H3' && currentH2) {
      articleNavigation[articleNavigation.length - 1].sublinks.push({ name: name || '', id: newId });
    }
  });

  return articleNavigation;
};
