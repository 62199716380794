import { Toolbar, ToolbarProps } from 'react-admin';
import { AdminSaveButton } from '../buttons/save-btn';
import { AdminDeleteButton } from '../buttons/delete-btn';

export const DefaultToolBar = (props: ToolbarProps) => {
  return (
    <Toolbar {...props} style={{ justifyContent: 'space-between' }}>
      <AdminSaveButton />
      <AdminDeleteButton />
    </Toolbar>
  );
};
