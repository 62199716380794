import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin';
import { getEnvs } from './env-lib';
import { LocalStorageKeys } from './variables';

export const authProvider = (type, params: any = {}) => {
  // called when the user attempts to log in
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    return fetch(getEnvs().REACT_APP_API_SERVER + '/v0/auth/admin', {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({ email: username, password }),
    })
      .then(async (res) => {
        if (!res.ok) {
          const data = await res.json();
          throw new Error(data.message);
        }
        return res.json();
      })
      .then(({ role, key }) => {
        localStorage.setItem(LocalStorageKeys.AUTH, key);
        localStorage.setItem(LocalStorageKeys.ROLE, role);
      });
  }
  // called when the user clicks on the logout button
  if (type === AUTH_LOGOUT) {
    localStorage.removeItem(LocalStorageKeys.AUTH);
    localStorage.removeItem(LocalStorageKeys.ROLE);
    return Promise.resolve();
  }
  // called when the API returns an error
  if (type === AUTH_ERROR) {
    const { status } = params;
    if (status === 401) {
      localStorage.removeItem(LocalStorageKeys.AUTH);
      return Promise.reject('Auth error');
    }
    return Promise.resolve();
  }
  // called when the user navigates to a new location
  if (type === AUTH_CHECK) {
    return localStorage.getItem(LocalStorageKeys.AUTH) ? Promise.resolve() : Promise.reject('Auth error');
  }
  if (type === AUTH_GET_PERMISSIONS) {
    const role = localStorage.getItem(LocalStorageKeys.ROLE);
    return role ? Promise.resolve(role) : Promise.reject();
  }
  return Promise.reject('Unknown method');
};
