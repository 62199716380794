import { required, TextInput } from 'react-admin';
import { IconWithPreview } from './components';

export const HeaderGlobalBanner = () => {
  return (
    <>
      <IconWithPreview />
      <TextInput source="url" label="URL for link" fullWidth={true} validate={required()} />
    </>
  );
};
