export enum DropHuntingActivityStatus {
  POTENTIAL = 'POTENTIAL',
  CONFIRMED = 'CONFIRMED',
  VERIFICATION = 'VERIFICATION',
  REWARD_AVAILABLE = 'REWARD_AVAILABLE',
  DISTRIBUTED = 'DISTRIBUTED',
  SNAPSHOT = 'SNAPSHOT',
}

export enum DateDisplay {
  YEAR = 'YEAR',
  QUARTER = 'QUARTER',
  MONTH = 'MONTH',
  DATE = 'DATE',
  DATE_TIME = 'DATE_TIME',
}

export enum DropHuntingTaskStatus {
  UPCOMING = 'UPCOMING',
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}
