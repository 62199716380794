import { SortingDirection } from '../../variables';

export type LifiDashboardInfo = { data: { swaps: LiFiSwap[]; totalAmount: number }; count: number };

export type LiFiSwap = {
  blockchain: string;
  amount: number;
  coinName: string;
};

export type LifiParams = {
  limit: number;
  offset: number;
  orderBy: LiFiDashboardOrderBy;
  orderDirection: SortingDirection;
};

export enum LiFiDashboardOrderBy {
  BLOCKCHAIN = 'blockchain',
  AMOUNT = 'amount',
  COINNAME = 'coinName',
}
