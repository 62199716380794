import { addDays, addMonths, addQuarters, addWeeks, addYears, endOfMonth, isLastDayOfMonth } from 'date-fns';

export const allocationTitleArray = [
  'Partnership & Advisors',
  'Team & Advisors',
  'Ecosystem',
  'Community',
  'Marketing',
  'Angel investors',
  'Charity',
  'Listing Liquidity',
  'Team',
  'Pre-sale',
  'Private/Pre-Sale',
  'Token sale',
  'Public Sale',
  'Private investor',
  'Foundation',
  'Miners',
  'Target Donation',
  'Open Donation',
  'Bounty',
  'Community and Miners',
];

export interface CoinVestingFormData {
  total_start_date: string;
  tge_start_date: string;
  links: string[] | null;
  is_hidden: boolean;
}

export const initialFormData: CoinVestingFormData = {
  total_start_date: '',
  tge_start_date: '',
  links: [],
  is_hidden: false,
};

export enum CoinAllocationUnlockType {
  LINEAR = 'linear',
  NONLINEAR = 'nonlinear',
}

export enum CoinAllocationFrequency {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year',
}

export type CoinAllocationBatch = {
  coin_allocation_id?: number;
  date: Date | string;
  is_tge: boolean;
  unlock_percent: number;
};

export const initCoinAllocationBatch: CoinAllocationBatch = {
  coin_allocation_id: 0,
  date: new Date(),
  is_tge: false,
  unlock_percent: 0,
};

export enum CoinAllocationGroup {
  VC = 'VC',
  TEAM = 'Team',
  COMMUNITY = 'Community',
}

export interface ICoinAllocation {
  id: number;
  name: string;
  tokens_percent: number;
  tokens: number | null;
  unlock_type: CoinAllocationUnlockType | null;
  unlock_frequency_type: CoinAllocationFrequency | null;
  unlock_frequency_value: number | null;
  vesting_duration_type: CoinAllocationFrequency | null;
  vesting_duration_value: number | null;
  round_date: Date | null;
  batches: CoinAllocationBatch[];
  group: CoinAllocationGroup | null;
}

export type Cliff = {
  duration_type: CoinAllocationFrequency;
  duration_value: number;
  isOpen: boolean;
};

export const initialCliffData: Cliff = {
  duration_type: CoinAllocationFrequency.DAY,
  duration_value: 0,
  isOpen: false,
};

export type TokensModal = {
  value: string;
  batchIndex?: number;
  isOpen: boolean;
  isValid: boolean;
};

export const initialTokensModal: TokensModal = {
  value: '',
  isOpen: false,
  isValid: false,
};

export const initialAllocationFormData: ICoinAllocation = {
  id: 0,
  name: '',
  tokens_percent: 0,
  tokens: null,
  unlock_type: null,
  unlock_frequency_type: CoinAllocationFrequency.DAY,
  unlock_frequency_value: 1,
  vesting_duration_type: CoinAllocationFrequency.DAY,
  vesting_duration_value: 1,
  round_date: null,
  batches: [],
  group: null,
};

export const formatDate = (dateString, withoutTime = false) => {
  if (!dateString) {
    return '';
  }

  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    throw new Error(`Invalid date string, ${dateString}`);
  }

  if (withoutTime) {
    date.setHours(12, 0, 0, 0);
    return date.toISOString().substring(0, 10);
  }

  return date.toISOString().substring(0, 16);
};

export const changeTokenPercentAmount = (
  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  setFormDataAllocation,
  totalSupply: number
) => {
  const { name, value } = e.target;

  if (name === 'tokens') {
    const tokensValue = parseFloat(value);

    if (!isNaN(tokensValue)) {
      const percentValue = (tokensValue / totalSupply) * 100;
      setFormDataAllocation((prevState) => ({
        ...prevState,
        tokens: tokensValue,
        tokens_percent: Number(percentValue.toFixed(3)),
      }));
    }
  } else if (name === 'tokens_percent') {
    const percentValue = parseFloat(value);

    if (!isNaN(percentValue)) {
      const tokensValue = (totalSupply / 100) * percentValue;
      setFormDataAllocation((prevState) => ({
        ...prevState,
        tokens: tokensValue,
        tokens_percent: Number(percentValue.toFixed(3)),
      }));
    }
  }
};

export function addToDate(date: Date, timeType: CoinAllocationFrequency, timeValue: number): Date {
  switch (timeType) {
    case CoinAllocationFrequency.DAY:
      return addDays(date, timeValue);
    case CoinAllocationFrequency.WEEK:
      return addWeeks(date, timeValue);
    case CoinAllocationFrequency.MONTH:
      return addMonths(date, timeValue);
    case CoinAllocationFrequency.QUARTER:
      return addQuarters(date, timeValue);
    case CoinAllocationFrequency.YEAR:
      return addYears(date, timeValue);
    default:
      throw new Error(`Invalid frequency: ${timeType}`);
  }
}

export function addToAllocationDate(
  startDate: Date,
  frequency: CoinAllocationFrequency,
  frequencyValue: number,
  initialDate?: Date | null
): Date {
  const calculatedDate = addToDate(startDate, frequency, frequencyValue);

  if (
    [CoinAllocationFrequency.MONTH, CoinAllocationFrequency.QUARTER, CoinAllocationFrequency.YEAR].includes(
      frequency
    ) &&
    isLastDayOfMonth(startDate)
  ) {
    return endOfMonth(calculatedDate);
  }

  if (frequency !== CoinAllocationFrequency.MONTH) {
    return calculatedDate;
  }

  // Если в текущем месяце меньше дней, чем в initialDate,
  // то отдаем последний день месяца, те максимально возможный
  if (initialDate && initialDate.getDate() >= endOfMonth(calculatedDate).getDate()) {
    return endOfMonth(calculatedDate);
  }

  // Если в предыдущем месяце оказалось мало дней, то startDate может быть некорректна,
  // например startDate=2023-02-29 при initialDate=2023-01-30,
  // но дней месяца хватает, то жестко устанавливаем дату
  if (initialDate && initialDate.getDate() !== calculatedDate.getDate()) {
    return new Date(calculatedDate.setDate(initialDate.getDate()));
  }

  return calculatedDate;
}

export function calculateEndDateOfVesting(
  startDate: Date,
  durationFrequencyType: CoinAllocationFrequency,
  durationFrequencyValue: number
): Date {
  const calculatedDate = addToDate(startDate, durationFrequencyType, durationFrequencyValue);

  if (
    [CoinAllocationFrequency.MONTH, CoinAllocationFrequency.QUARTER, CoinAllocationFrequency.YEAR].includes(
      durationFrequencyType
    ) &&
    isLastDayOfMonth(startDate)
  ) {
    return endOfMonth(calculatedDate);
  }

  return calculatedDate;
}

export function getFrequencyValueInDays(frequencyType: CoinAllocationFrequency, frequencyValue: number): number {
  switch (frequencyType) {
    case CoinAllocationFrequency.DAY:
      return 1 * frequencyValue;
    case CoinAllocationFrequency.WEEK:
      return 7 * frequencyValue;
    case CoinAllocationFrequency.MONTH:
      return 30.5 * frequencyValue;
    case CoinAllocationFrequency.QUARTER:
      return 30.5 * 3 * frequencyValue;
    case CoinAllocationFrequency.YEAR:
      return 365 * frequencyValue;
    default:
      throw new Error(`Invalid frequency: ${frequencyType}`);
  }
}

export const getInitialTgeUnlockPercent = (batches: CoinAllocationBatch[]) => {
  const firstBatch = batches[0];

  if (!firstBatch?.is_tge) {
    return 0;
  }

  return firstBatch.unlock_percent;
};
