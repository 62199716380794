import { maxLength } from 'react-admin';
import { TextInput } from 'ra-ui-materialui';
import { IconWithPreview } from './components';

export const PortfolioBanner = (props) => {
  return (
    <>
      <TextInput source="title" fullWidth={true} validate={maxLength(20)} />
      <TextInput source="url" label="Link URL" helperText="URL with https://" fullWidth={true} />
      <IconWithPreview />
    </>
  );
};
