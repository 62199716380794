import { FC } from 'react';
import { ArrayInput, SelectInput, TabbedForm, TextInput } from 'ra-ui-materialui';
import { ArrayInputIterator } from '../../inputs/array-input-iterator';
import { RichTextInput } from 'ra-input-rich-text';
import { maxLength } from 'react-admin';
import { RichTextInputCustomToolbar } from '../../toolbars/rich-text-input-toolbar';
import { shortDescriptionMaxLength } from './constants';
import { locales } from '../../variables';

export const LocalizationTab: FC = (props) => {
  return (
    <TabbedForm.Tab label="localization" {...props}>
      <ArrayInput label="Coin Localization" source="contentLocalization">
        <ArrayInputIterator>
          <SelectInput label="Locale" source="locale" choices={locales} />
          <TextInput label="Name" source="name" />
          <TextInput label="Alter Name" source="alterName" />
          <TextInput label="Alter Name Token Platform" source="tokenPlatformName" />
          <RichTextInput
            label="Description"
            source="description"
            toolbar={<RichTextInputCustomToolbar />}
            fullWidth={true}
          />
          <TextInput
            multiline={true}
            label="Short Description"
            source="shortDescription"
            validate={maxLength(shortDescriptionMaxLength)}
            placeholder={`Maximum of ${shortDescriptionMaxLength} characters`}
          />
          <TextInput multiline={true} label="Notification" source="notification" />
          <RichTextInput
            label="Crowdsales Description"
            source="icoDataDescription"
            toolbar={<RichTextInputCustomToolbar />}
            fullWidth={true}
          />
        </ArrayInputIterator>
      </ArrayInput>
    </TabbedForm.Tab>
  );
};
