import { ArrayInputIterator } from '../../inputs/array-input-iterator';
import {
  AutocompleteArrayInput,
  BooleanInput,
  DateInput,
  ReferenceArrayInput,
  SelectInput,
  TextInput,
} from 'ra-ui-materialui';
import { Button, Typography } from '@mui/material';
import { FC } from 'react';
import { FormDataConsumer, required } from 'react-admin';
import { FundingRoundsArrayInput, Divider } from './styled';
import { NumberInput } from '../../inputs/number-input';
import { SortingDirection } from '../../variables';
import { useFormContext } from 'react-hook-form';

const roundTypes = {
  Undisclosed: 'Undisclosed',
  'PRE SEED': 'Pre-Seed',
  PRESALE: 'Presale',
  'Extended Pre-Seed': 'Extended Pre-Seed',
  SEED: 'Seed',
  'Extended Seed': 'Extended Seed',
  STRATEGIC: 'Strategic',
  'SERIES A': 'Series A',
  'Pre-Series A': 'Pre-Series A',
  'Extended Series A': 'Extended Series A',
  'Extended Series B': 'Extended Series B',
  'SERIES B': 'Series B',
  'Pre-Series B': 'Pre-Series B',
  'SERIES C': 'Series C',
  'Extended Series C': 'Extended Series C',
  'SERIES D': 'Series D',
  'Extended Series D': 'Extended Series D',
  'Series E': 'Series E',
  'Series F': 'Series F',
  'Series G': 'Series G',
  'Series H': 'Series H',
  ANGEL: 'Angel',
  IPO: 'IPO',
  'Post-IPO': 'Post-IPO',
  'Post-IPO Debt': 'Post-IPO Debt',
  'Debt Financing': 'Debt Financing',
  'Private Token Sale': 'Private Token Sale',
  'Treasury Diversification': 'Treasury Diversification',
  Grant: 'Grant',
  'M&A': 'M&A',
  'OTC Purchase': 'OTC Purchase',
  Incubation: 'Incubation',
};

const CalculateButton = (props) => {
  const { getValues, setValue } = useFormContext();
  const { raise, price_usd, tokens_for_sale } = getValues(props.source);
  const calculateIsDisabled = [raise, price_usd, tokens_for_sale].filter(Boolean).length !== 2;

  const onCalculate = () => {
    if (calculateIsDisabled) {
      return;
    }

    let field = '';
    let value = 0;

    if (!raise) {
      field = 'raise';
      value = tokens_for_sale * price_usd;
    }

    if (!price_usd) {
      field = 'price_usd';
      value = raise / tokens_for_sale;
    }

    if (!tokens_for_sale) {
      field = 'tokens_for_sale';
      value = raise / price_usd;
    }

    setValue(`${props.source}.${field}`, value, { shouldDirty: true });
  };

  return (
    <div style={{ marginTop: 8 }}>
      <Button variant="outlined" onClick={() => onCalculate()} disabled={calculateIsDisabled}>
        Calculate
      </Button>
      <Typography variant="caption" marginLeft={1}>
        This button calculates third value based on two available values above
      </Typography>
    </div>
  );
};

export const FundingRounds: FC = () => {
  return (
    <FormDataConsumer className="crowdsales-input">
      {({ formData }) =>
        formData.hasFundingRounds && (
          <>
            <Typography variant="h4">Funding Rounds</Typography>
            <FundingRoundsArrayInput label={false} source="fundingRounds">
              <ArrayInputIterator>
                <SelectInput
                  label="Round type"
                  source="crowdsalesType"
                  choices={Object.entries(roundTypes).map(([id, name]) => ({ id, name }))}
                  validate={[required()]}
                />
                <SelectInput
                  label="Priority"
                  source="priorityRating"
                  choices={new Array(10).fill(0).map((_, i) => ({ id: (i + 1).toString(), name: (i + 1).toString() }))}
                />
                <BooleanInput
                  source="for_vc_profit_calculation"
                  label="Include in the calculation of VC Profit"
                  defaultValue={true}
                  helperText="If disabled, this round won't be included in the calculation of the VC Unrealized Profit and VC Realized Profit metrics"
                />
                <NumberInput label="Valuation (USD)" source="valuation" />
                <DateInput source="date" validate={[required()]} />

                <NumberInput label="Raised" source="raise" />
                <NumberInput label="Price" source="price_usd" />
                <NumberInput source="tokens_for_sale" />
                <CalculateButton />
                <TextInput source="lockup_period" />

                <TextInput source="linkToAnnouncement" validate={required()} />
                <NumberInput source="coinId" defaultValue={formData.id} disabled={true} style={{ display: 'none' }} />
                <ReferenceArrayInput
                  source="leadFundIds"
                  reference="funds"
                  sort={{ field: 'name', order: SortingDirection.ASC }}
                >
                  <AutocompleteArrayInput optionText="name" label="Lead funds" fullWidth={true} />
                </ReferenceArrayInput>
                <ReferenceArrayInput
                  source="fundIds"
                  reference="funds"
                  sort={{ field: 'name', order: SortingDirection.ASC }}
                >
                  <AutocompleteArrayInput optionText="name" label="Funds" fullWidth={true} />
                </ReferenceArrayInput>
              </ArrayInputIterator>
            </FundingRoundsArrayInput>
            <Divider style={{ width: '100%', marginBottom: 20 }} />
          </>
        )
      }
    </FormDataConsumer>
  );
};
