import { FC } from 'react';
import { SelectInput, TabbedForm } from 'ra-ui-materialui';

export const IcoRatesTab: FC = (props) => {
  return (
    <TabbedForm.Tab label="ICO Rates" {...props}>
      <SelectInput
        label="Cryptorank Interest"
        source="content.icoData.rates.cryptorankInterest"
        choices={['Negative', 'Neutral', 'Medium', 'High', 'Very High'].map((name) => ({ id: name, name }))}
        style={{ width: '250px' }}
      />
    </TabbedForm.Tab>
  );
};
