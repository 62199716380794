import {
  List,
  Datagrid,
  TextField,
  EditButton,
  TextInput,
  TopToolbar,
  ListButton,
  RefreshButton,
  Edit,
  SimpleForm,
  Create,
  DateField,
  ArrayInput,
  CreateButton,
  Filter,
} from 'react-admin';
import { NumberInput } from '../../inputs/number-input';
import { SortingDirection } from '../../variables';
import { ArrayInputIterator } from '../../inputs/array-input-iterator';
import { AdminDeleteButton } from '../../buttons/delete-btn';

const DexscreenerFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Name" source="q" alwaysOn />
  </Filter>
);

const EditActions = () => (
  <TopToolbar>
    <CreateButton />
    <ListButton />
    <RefreshButton />
  </TopToolbar>
);

const Form = (
  <SimpleForm>
    <TextInput source="name" />
    <NumberInput source="exchange_id" label="Exchange id" />
    <TextInput source="chain" label="Token Platform (chain)" />
    <TextInput source="newPairs" label="New Pairs (string)" />
    <ArrayInput source="pairs">
      <ArrayInputIterator>
        <TextInput source="" label="Address of traded pairs" />
      </ArrayInputIterator>
    </ArrayInput>
  </SimpleForm>
);

export const DexscreenerCreate = (props) => <Create {...props}>{Form}</Create>;

export const DexscreenerEdit = (props) => (
  <Edit actions={<EditActions />} {...props}>
    {Form}
  </Edit>
);

export const DexscreenerList = (props) => (
  <List {...props} perPage={50} sort={{ field: 'id', order: SortingDirection.ASC }} filters={<DexscreenerFilter />}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="chain" />
      <TextField source="exchange_id" />
      <DateField source="created_at" showTime locales="ru" />
      <DateField source="updated_at" showTime locales="ru" />
      <EditButton />
      <AdminDeleteButton />
    </Datagrid>
  </List>
);
