export enum Periods {
  H24 = 'H24',
  D7 = 'D7',
  D30 = 'D30',
  M3 = 'M3',
  M6 = 'M6',
  TOTAL = 'TOTAL',
}

export type PeriodTypes = { [key in Periods]: number };

export type ProjectStats = Record<string, PeriodTypes>;

export type ApiStats = {
  freeCount: number;
  paidCount: number;
  tariffs: {
    histCount: PeriodTypes;
    name: string;
    pricingAnnually: number;
  }[];
};
