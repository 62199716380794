import * as React from 'react';
import { FC } from 'react';
import { Button, useNotify } from 'react-admin';
import { FileCopy } from '@mui/icons-material';

type Props = {
  textToCopy: string;
  label?: string;
};

/**
 * Кнопка копирования текста в буфер обмена.
 */
export const CopyButton: FC<Props> = ({ textToCopy, label }) => {
  const notify = useNotify();

  const handleCopyClick = () => {
    navigator.clipboard.writeText(textToCopy).then(
      () => {
        notify('Text copied successfully', {type: 'info'}); // Успешное копирование
      },
      (err) => {
        notify('Failed to copy text', {type: 'warning'}); // Неудачное копирование
      }
    );
  };

  return <Button variant="outlined" startIcon={<FileCopy />} label={label ?? 'Copy'} onClick={handleCopyClick} />;
};
