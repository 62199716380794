import { DateDisplay, DropHuntingActivityStatus, DropHuntingTaskStatus } from './types';

export const activityStatusTypes = [
  { id: DropHuntingActivityStatus.POTENTIAL, name: 'Potential' },
  { id: DropHuntingActivityStatus.CONFIRMED, name: 'Confirmed' },
  { id: DropHuntingActivityStatus.SNAPSHOT, name: 'Snapshot' },
  { id: DropHuntingActivityStatus.VERIFICATION, name: 'Verification' },
  { id: DropHuntingActivityStatus.REWARD_AVAILABLE, name: 'Reward available' },
  { id: DropHuntingActivityStatus.DISTRIBUTED, name: 'Distributed' },
];

export const dateDisplayTypes = [
  { id: DateDisplay.DATE_TIME, name: 'Date + Time' },
  { id: DateDisplay.DATE, name: 'Date' },
  { id: DateDisplay.MONTH, name: 'Month' },
  { id: DateDisplay.QUARTER, name: 'Quarter' },
  { id: DateDisplay.YEAR, name: 'Year' },
];

export const taskStatusTypes = [
  { id: DropHuntingTaskStatus.UPCOMING, name: 'Upcoming' },
  { id: DropHuntingTaskStatus.OPEN, name: 'Open' },
  { id: DropHuntingTaskStatus.CLOSED, name: 'Closed' },
];

export const defaultTaskStatusType = taskStatusTypes[1].id;
export const defaultDateDisplayType = dateDisplayTypes[0].id;
