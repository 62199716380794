import { createElement } from 'react';
import { Menu, usePermissions, useResourceDefinitions } from 'react-admin';
import ListItemIcon from '@mui/icons-material/List';
import { UserRole } from '../variables/user-role';
import { checkUserRoleAvailability } from '../utils/check-user-role-availability';
import { MenuGroups } from '../variables/menu-groups';
import { NestedList } from './nested-list';

export const CustomMenu = () => {
  const userRole: UserRole | undefined = usePermissions().permissions;
  const resourceDefinitions = useResourceDefinitions();
  const resources = Object.values(resourceDefinitions);

  const resourceGroups = resources
    .filter(({ options }) => options?.group && userRole && checkUserRoleAvailability(userRole, options.allowTo))
    .reduce((acc, curr) => {
      const groupName: MenuGroups = curr.options.group;
      if (acc[groupName]) {
        acc[groupName].push(curr);
        return acc;
      }

      acc[groupName] = [curr];

      return acc;
    }, {});

  if (!userRole) {
    return null;
  }

  return (
    <Menu>
      <Menu.DashboardItem />

      {resources
        .filter(({ hasList, options }) => hasList && !options?.group)
        .map((resource) => (
          <Menu.Item
            key={resource.name}
            leftIcon={(resource.icon && createElement(resource.icon)) || <ListItemIcon />}
            primaryText={resource.options?.label || resource.name}
            style={{ textTransform: 'capitalize' }}
            to={`/${resource.name}`}
          />
        ))}

      {Object.entries(resourceGroups).map(([groupName, resources]) => (
        <NestedList name={groupName as MenuGroups} resources={resources as any} key={groupName} />
      ))}
    </Menu>
  );
};
