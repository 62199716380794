import { FC } from 'react';
import { ArrayInput, AutocompleteInput, ReferenceInput, SelectInput, TabbedForm, TextInput } from 'ra-ui-materialui';
import { ArrayInputIterator } from '../../inputs/array-input-iterator';
import { SortingDirection } from '../../variables';
import { tokenStandards } from './constants';
import { NumberInput } from '../../inputs/number-input';

export const TokensTab: FC = (props) => {
  return (
    <TabbedForm.Tab label="tokens" {...props}>
      <ArrayInput label="Tokens" source="tokens">
        <ArrayInputIterator>
          <ReferenceInput
            label="Token platform"
            source="tokenPlatformId"
            reference="token-platforms"
            resource="token-platforms"
            sort={{ field: 'id', order: SortingDirection.ASC }}
          >
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
          <TextInput multiline={true} source="address" spellCheck={false} label="Token Addr (starts with 0x for ETH)" />
          <SelectInput
            label="Token Standard"
            source="standard"
            choices={Object.entries(tokenStandards).flatMap(([platform, names]) =>
              names.map((name) => ({
                id: name,
                name: `${name} (${platform})`,
              }))
            )}
          />
          <NumberInput source="decimals" label="Decimals" />
        </ArrayInputIterator>
      </ArrayInput>
    </TabbedForm.Tab>
  );
};
