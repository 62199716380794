import { useEffect, useState } from 'react';
import { DashboardTable } from '../../dashboard/table';
import { getDashboardGamification } from '../../api-service';
import { LoaderWrapper } from '../../dashboard/loader-wrapper';
import styled from 'styled-components';
import { Loading } from 'ra-ui-materialui';

const TableWrapper = styled.div`
  margin: 30px 0 0 10px;
  & > table {
    width: 80%;
  }
`;

export const GamificationDashboardList = () => {
  const [gameficationStats, setGameficationStats] = useState<Record<string, string>>();

  useEffect(() => {
    getDashboardGamification().then(setGameficationStats);
  }, []);

  if (!gameficationStats) {
    return <Loading />;
  }

  return (
    <LoaderWrapper>
      {gameficationStats && (
        <TableWrapper>
          <DashboardTable>
            <tbody>
              {Object.entries(gameficationStats).map(([title, values]) => (
                <tr key={'table-row_' + title}>
                  <td>{title}</td>
                  <td>{values}</td>
                </tr>
              ))}
            </tbody>
          </DashboardTable>
        </TableWrapper>
      )}
    </LoaderWrapper>
  );
};
