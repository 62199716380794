import {
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditButton,
  List,
  NumberInput,
  SimpleForm,
  TextField,
} from 'react-admin';

export const PoolList = (props) => {
  return (
    <List perPage={50} {...props}>
      <Datagrid>
        <TextField source="ton" label="Ton Pool" />
        <TextField source="point" label="Crystal Pool" />
        <DateField source="week_start" label="Week Start" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export const PoolEdit = (props) => (
  <Edit {...props} redirect={(basePath) => basePath}>
    <SimpleForm>
      <NumberInput source="ton" label="Ton Pool" />
      <NumberInput source="point" label="Crystal Pool" />
      <DateInput source="week_start" label="Week Start" />
    </SimpleForm>
  </Edit>
);

export const PoolCreate = (props) => (
  <Create {...props} redirect={(basePath) => basePath}>
    <SimpleForm>
      <NumberInput source="ton" label="Ton Pool" />
      <NumberInput source="point" label="Crystal Pool" />
      <DateInput source="week_start" label="Week Start" />
    </SimpleForm>
  </Create>
);
