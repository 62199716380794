export interface TMAQuizRecord {
  id: string;
  ask: string;
  redirect_url: string;
  created_at: string;
  updated_at: string;
  answers: Array<{
    title: string;
    is_correct: boolean;
  }>;
}

export enum TaskType {
  DAILY = 'daily',
  JOIN_CHAT = 'join-chat',
  UNIQUE = 'unique',
  MILESTONE_REFERRAL_5 = 'milestone-referral-5',
  MILESTONE_POINTS_10000 = 'milestone-points-10000',
  MILESTONE_POINTS_25000 = 'milestone-points-25000',
  CONNECT = 'connect',
  DAILY_REFERRAL = 'daily-referral',
  PUBLICATION_STORY = 'publish-story',
  QUIZ = 'quiz',
  RAFFLE_QUEST = 'quest',
}

export enum TaskGroup {
  MAIN = 'main',
  DAILY = 'daily',
  CRYPTORANK = 'cryptorank',
}
