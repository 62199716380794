import { FC } from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  Edit,
  TabbedForm,
  TextInput,
  SelectInput,
  Create,
  BooleanInput,
  Filter,
  ReferenceInput,
  FunctionField,
  Toolbar,
  BulkDeleteWithUndoButton,
  useRecordContext,
  useResourceContext,
} from 'react-admin';
import { OpenInNew } from '@mui/icons-material';
import { EnabledField } from '../../fields/enabled-field';
import numeral from 'numeral';
import { formatDistanceToNow } from 'date-fns';
import { FRONTEND_URL, SortingDirection } from '../../variables';
import { coinTypes } from '../../variables/coin-types';
import { AdminSaveButton } from '../../buttons/save-btn';
import { AdminDeleteButton } from '../../buttons/delete-btn';
import { getIsDisabledByRole } from '../../utils/get-disabled-by-role';
import { SummaryTab } from './summary-tab';
import { TokensTab } from './tokens-tab';
import { OtherTab } from './other-tab';
import { LocalizationTab } from './localization-tab';
import { IcoRatesTab } from './ico-rates-tab';
import { AthTab } from './ath-tab';
import { CrowdsalesTab } from './crowdsales-tab';
import { TeamTab } from './team-tab';
import { VestingTab } from './vesting-tab';
import { LinksTab } from './links-tab';
import { AppResourceNames } from '../../variables/app-resource-names';
import { AutocompleteInput } from '../../inputs/autocomplete-input';

const CoinSaveButton: FC<any> = ({ invalid, coinType, ...rest }) => {
  const disabled = coinType === 'fiat';
  return <AdminSaveButton {...rest} disabled={disabled} />;
};

const CoinFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Name or Symbol" source="q" alwaysOn={true} />
    <TextInput label="Symbol (strict)" source="symbol" alwaysOn={true} />
    <SelectInput source="type" choices={coinTypes} />
    <ReferenceInput
      label="Coin Category"
      source="categoryId"
      reference="coin-category"
      perPage={100}
      sort={{ field: 'name', order: SortingDirection.ASC }}
    >
      <SelectInput optionText="name" optionValue="id" />
    </ReferenceInput>
    <ReferenceInput
      label="Ecosystem"
      source="ecosystemId"
      reference={AppResourceNames.ECOSYSTEMS}
      perPage={100}
      sort={{ field: 'name', order: SortingDirection.ASC }}
    >
      <SelectInput optionText="name" optionValue="id" />
    </ReferenceInput>
    <ReferenceInput
      label="Tag"
      source="tagId"
      reference={AppResourceNames.COIN_TAG}
      perPage={100}
      sort={{ field: 'name', order: SortingDirection.ASC }}
    >
      <AutocompleteInput optionText="name" optionValue="id" />
    </ReferenceInput>
    <BooleanInput defaultValue={true} label="Coingecko Updates" source="updateSupplyFromExternalAPI" />
    <BooleanInput defaultValue={true} label="ICO" source="isIco" />
    <BooleanInput defaultValue={true} label="Sponsored ICO" source="isSponsored" />
    <BooleanInput defaultValue={true} label="Without image" source="withoutImage" />
    <BooleanInput defaultValue={true} label="Has notification" source="hasNotification" />
    <BooleanInput defaultValue={true} label="Inactive" source="inactive" />
    <BooleanInput defaultValue={true} label="Show trading view" source="showTradingView" />
    <BooleanInput defaultValue={true} label="Has no decimals" source="hasNoDecimals" />
  </Filter>
);

export const CoinList = (props) => (
  <List {...props} sort={{ field: 'id', order: SortingDirection.ASC }} perPage={50} filters={<CoinFilter />}>
    <Datagrid bulkActionButtons={<BulkDeleteWithUndoButton {...props} disabled={getIsDisabledByRole()} />}>
      <EnabledField source="enabled" label="On" />
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="symbol" label="Symbol" />
      <FunctionField
        label="MarketCap"
        source="marketCap"
        render={(data) =>
          +data.priceUSD && +data.availableSupply
            ? '$' + numeral(data.priceUSD * data.availableSupply).format('0,0a')
            : null
        }
      />
      <FunctionField
        label="Price Updated"
        source="priceCalculatedAt"
        display="block"
        style={{ minWidth: 100 }}
        render={(data) =>
          data.priceCalculatedAt
            ? formatDistanceToNow(new Date(data.priceCalculatedAt), { addSuffix: true })
            : 'Not traded'
        }
      />
      <FunctionField
        label="Volume"
        source="volume24h"
        render={(data) =>
          +data.volume24h && +data.priceUSD ? '$' + numeral(data.volume24h * data.priceUSD).format('0,0a') : null
        }
      />
      <DateField source="createdAt" label="Created At" showTime={true} locales="ru" />
      <TextField source="updatedByUserName" label="Last chg by" sortable={false} />
      <EditButton />
      <AdminDeleteButton />
    </Datagrid>
  </List>
);

const CoinTitle = () => {
  const record = useRecordContext();
  const pageName = useResourceContext();

  if (!record) {
    return null;
  }

  const pageNames = {
    coins: 'Coin',
    ico: 'ICO',
  };

  return (
    <span>
      {pageNames[pageName]} "{record.name} #{record.id}" &nbsp;
      <a
        rel="nofollow noopener noreferrer"
        target="_blank"
        href={`${FRONTEND_URL}/${record.isIco ? 'ico' : 'price'}/${record.key}`}
      >
        <OpenInNew />
      </a>
      {record.cmcCoin && (
        <a
          rel="nofollow noopener noreferrer"
          target="_blank"
          href={`https://coinmarketcap.com/currencies/${record.cmcCoin.slug}`}
          style={{
            verticalAlign: 'super',
            fontSize: '10px',
            textDecoration: 'none',
            border: '2px solid',
            borderRadius: '2px',
            display: 'inline-block',
            padding: '2px',
            lineHeight: '1',
            marginLeft: '8px',
          }}
        >
          CMC
        </a>
      )}
      {record.coinGeckoCoinId && (
        <a
          rel="nofollow noopener noreferrer"
          target="_blank"
          href={`https://www.coingecko.com/en/coins/${record.coinGeckoCoinId}`}
          style={{
            verticalAlign: 'super',
            fontSize: '10px',
            textDecoration: 'none',
            border: '2px solid',
            borderRadius: '2px',
            display: 'inline-block',
            padding: '2px',
            lineHeight: '1',
            marginLeft: '8px',
          }}
        >
          CGK
        </a>
      )}
    </span>
  );
};

const PostCreateToolbar = (props) => {
  const record = useRecordContext();
  return (
    <Toolbar {...props} sx={{ justifyContent: 'space-between' }}>
      <CoinSaveButton coinType={record?.type} disabled={props.pristine} />
      <AdminDeleteButton />
    </Toolbar>
  );
};

const redirect = (basePath, id, data) => `${data.isIco ? 'ico' : 'coins'}/${id}`;

const CoinForm = (props: { isEdit?: boolean }) => {
  const record = useRecordContext();

  return (
    <TabbedForm toolbar={<PostCreateToolbar />}>
      <SummaryTab isEdit={props.isEdit} />
      <TokensTab />
      <AthTab />
      <CrowdsalesTab />
      {typeof record?.id === 'number' && <VestingTab />}
      <IcoRatesTab />
      <OtherTab />
      <LocalizationTab />
      <TeamTab />
      <LinksTab />
    </TabbedForm>
  );
};

export const CoinEdit = () => {
  return (
    <Edit title={<CoinTitle />} redirect={false}>
      <CoinForm isEdit={true} />
    </Edit>
  );
};

export const CoinCreate = () => {
  return (
    <Create redirect={redirect}>
      <CoinForm />
    </Create>
  );
};
