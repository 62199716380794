import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { TextInput, TextInputProps } from 'ra-ui-materialui';

export const TrimOnBlurInput: FC<TextInputProps> = (props) => {
  const { setValue } = useFormContext();

  const onBlur = (e) => {
    setValue(props.source, e.target.value.trim());
  };

  return <TextInput {...props} onBlur={onBlur} />;
};
