import { FC } from 'react';
import {
  ArrayInput,
  AutocompleteArrayInput,
  AutocompleteInput,
  ReferenceArrayInput,
  ReferenceInput,
  TabbedForm,
} from 'ra-ui-materialui';
import { SortingDirection } from '../../variables';
import { ArrayInputIterator } from '../../inputs/array-input-iterator';
import { NumberInput } from '../../inputs/number-input';

export const TeamTab: FC<any> = (props) => {
  const label = 'team';

  if (props.hidden) {
    return <TabbedForm.Tab label={label} {...props} />;
  }

  return (
    <TabbedForm.Tab label={label} {...props}>
      <ArrayInput label="Select Person" source="team">
        <ArrayInputIterator>
          <ReferenceInput label="Persons" source="person_id" reference="persons">
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
          <ReferenceArrayInput
            label="Jobs"
            source="job_ids"
            resource="job_ids"
            reference="persons-jobs"
            perPage={300}
            sort={{ field: 'name', order: SortingDirection.ASC }}
          >
            <AutocompleteArrayInput optionText="name" />
          </ReferenceArrayInput>
          <NumberInput label="Priority" source="priority" step={1} min={1} />
        </ArrayInputIterator>
      </ArrayInput>
    </TabbedForm.Tab>
  );
};
