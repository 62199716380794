import { useState } from 'react';
import { SelectInput, DateTimeInput, NumberInput, useRecordContext, required } from 'react-admin';
import { getRewardFormByType } from './reward-form-by-type';
import { RewardsType, defaultDisplayTypes, defaultRewardTypes } from './variables';

export const LotteryForm = (props) => {
  const record = useRecordContext();
  const defaultRewardType = record?.rewardType || defaultRewardTypes[0].id;
  const [rewardType, setRewardType] = useState(defaultRewardType);

  const questType = record && Object.values(record).length ? record.questType : props.questType;

  return (
    <>
      <DateTimeInput label="Start time" source="startTime" />
      <div>
        <DateTimeInput label="End time" source="endTime" />
      </div>
      <div>
        <NumberInput source="priority" label="Priority" min={1} />
      </div>
      <div>
        <SelectInput
          source="rewardType"
          choices={defaultRewardTypes}
          defaultValue={defaultRewardType}
          onChange={(event) => setRewardType(event.target.value)}
        />
      </div>
      <hr />
      <div>{getRewardFormByType(rewardType as RewardsType, questType)}</div>
      <hr />
      <SelectInput
        helperText="This controller defines what will be displayed in the table of winners"
        source="questData.winnerDisplay"
        choices={defaultDisplayTypes}
        defaultValue={defaultDisplayTypes[0].id}
        validate={required()}
      />
    </>
  );
};
