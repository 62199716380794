export enum VestingIntervals {
  DAY = '1d',
  WEEK = '1w',
  MONTH = '1m',
}

export const VestingIntervalsMap = {
  [VestingIntervals.DAY]: '1 day',
  [VestingIntervals.WEEK]: '1 week',
  [VestingIntervals.MONTH]: '1 month',
};
