import { AppResourceNames } from '../variables/app-resource-names';
import {
  AccountBalance,
  AttachMoney,
  BusinessRounded,
  Euro,
  Label,
  LibraryBooks,
  Link,
  MoneyRounded,
  PaymentSharp,
  People,
  PeopleOutlineRounded,
  Search,
  Share,
  SportsMma,
  TableChart,
  Title,
  Toc,
  TrendingUp,
  ViewCarousel,
  YouTube,
  Downloading,
  Translate,
  PhoneAndroid,
  AddReaction,
  EmojiEvents,
  Task,
  AccountTree,
  Wallet,
  ContactSupport,
  Public,
  FormatListBulleted,
  PermDataSetting,
} from '@mui/icons-material';

import { SecuredResourceProps } from './types';
import { UserRole } from '../variables/user-role';
import { CoinCreate, CoinEdit, CoinList } from '../resources/coins';
import { IcoList } from '../resources/ico';
import { CoinCategoryList, CoinCategoryEdit, CoinCategoryCreate } from '../resources/coin-category';
import { CoinTagList, CoinTagEdit, CoinTagCreate } from '../resources/coin-tag';
import { FundCategoriesList, FundCategoriesEdit, FundCategoriesCreate } from '../resources/fund-categories';
import { ExchangeList, ExchangeEdit, ExchangeCreate } from '../resources/exchanges/exchanges';
import { FundsList, FundEdit, FundCreate } from '../resources/funds';
import { MenuGroups } from '../variables/menu-groups';
import { DexscreenerList, DexscreenerEdit, DexscreenerCreate } from '../resources/exchanges/dexscreener-exchanges';
import { IdoPlatformsList, IdoPlatformsEdit, IdoPlatformCreate } from '../resources/ido-platforms';
import { TickerList, TickerEdit } from '../resources/tickers';
import { TokenPlatformsList, TokenPlatformEdit, TokenPlatformCreate } from '../resources/token-platforms';
import { AdminLogList, AdminLogShow } from '../resources/admin-log';
import { BannerList, BannerEdit, BannerCreate } from '../resources/banners';
import { CmcCoinList, CmcCoinShow } from '../resources/cmc-coins';
import { CoinMappingList, CoinMappingEdit, CoinMappingCreate } from '../resources/coin-mappings';
import { WriteOffsList, WriteOffEdit, WriteOffCreate } from '../resources/gamification/write-offs';
import { MobileLocalizationList, MobileLocalizationEdit } from '../resources/mobile-app-localization';
import { SeoTemplateList, SeoTemplateEdit, SeoTemplateCreate } from '../resources/seo-templates';
import { ManagerStatistics } from '../resources/users/manager-statistics';
import { UserList, UserEdit } from '../resources/users/users';
import { BattleList, BattleEdit, BattleCreate } from '../resources/battle';
import { NewsLogoList } from '../resources/content-center/news-logo';
import { NewsSourceList, NewsSourceEdit, NewsSourceCreate } from '../resources/content-center/news-source';
import { NewsCategoryList, NewsCategoryEdit, NewsCategoryCreate } from '../resources/content-center/news-tag';
import { SponsoredNewsList, SponsoredNewsEdit, SponsoredNewsCreate } from '../resources/content-center/sponsored-news';
import { GamificationDashboardList } from '../resources/gamification/dashboard';
import { MissionsList, MissionEdit, MissionCreate } from '../resources/gamification/missions';
import { OnChainTasksList, OnChainTasksEdit, OnChainTasksCreate } from '../resources/gamification/on-chain-task';
import { TasksList, TasksEdit, TasksCreate } from '../resources/gamification/tasks';
import {
  CoinTrendingSettingsList,
  CoinTrendingSettingsEdit,
  CoinTrendingSettingsCreate,
} from '../resources/trending-projects/coin-trending-settings';
import {
  SponsoredFundingRoundList,
  SponsoredFundingRoundEdit,
  SponsoredFundingRoundCreate,
} from '../resources/trending-projects/sponsored-funding-round';
import {
  SponsoredTokenSaleList,
  SponsoredTokenSaleEdit,
  SponsoredTokenSaleFormCreate,
} from '../resources/trending-projects/sponsored-token-sale';
import { ArticleTagsList, ArticleTagsEdit, ArticleTagsCreate } from '../resources/content-center/article-tags';
import { ArticlesList, ArticlesEdit, ArticlesCreate } from '../resources/content-center/articles';
import {
  ArticleAuthorsList,
  ArticleAuthorsEdit,
  ArticleAuthorsCreate,
} from '../resources/content-center/articles-authors';
import { CategoriesList, CategoriesEdit, CategoriesCreate } from '../resources/content-center/categories';
import { SubcategoriesList, SubcategoriesEdit, SubcategoriesCreate } from '../resources/content-center/subcategories';
import { YoutubeVideosList, YoutubeVideosEdit, YoutubeVideosCreate } from '../resources/content-center/youtube-videos';
import {
  SponsoredFundList,
  SponsoredFundEdit,
  SponsoredFundCreate,
} from '../resources/trending-projects/sponsored-fund';
import { PersonCategoriesList, PersonCategoriesEdit, PersonCategoriesCreate } from '../resources/persons/categories';
import { PersonJobsList, PersonJobsEdit, PersonJobsCreate } from '../resources/persons/jobs';
import { PersonsList, PersonsEdit, PersonsCreate } from '../resources/persons/persons';
import { ImageHubEdit, ImageHubCreate } from '../resources/image-hub/create-edit-form';
import { ImageHubList } from '../resources/image-hub/list';
import { MobileStoriesCreate, MobileStoriesEdit, MobileStoriesList } from '../resources/mobile-app-stories';
import { MobileDownload } from '../resources/mobile-add-download';
import {
  DropHuntingActivitiesCreate,
  DropHuntingActivitiesEdit,
  DropHuntingActivitiesList,
} from '../resources/drop-hunting/activities';
import {
  DropHuntingActivityTypesCreate,
  DropHuntingActivityTypesEdit,
  DropHuntingActivityTypesList,
} from '../resources/drop-hunting/activity-types';
import {
  DropHuntingRewardsCreate,
  DropHuntingRewardsEdit,
  DropHuntingRewardsList,
} from '../resources/drop-hunting/rewards';
import { LinksCreate, LinksEdit, LinksList } from '../resources/links';
import { EcosystemsList, EcosystemsEdit, EcosystemsCreate } from '../resources/ecosystems';
import { LiFiDashboard } from '../resources/li-fi-dashboard';
import {
  PartnershipContactsList,
  PartnershipContactEdit,
  PartnershipContactCreate,
} from '../resources/partnership-contacts';
import { EcosystemTagsCreate, EcosystemTagsEdit, EcosystemTagsList } from '../resources/ecosystems/ecosystem-tag';
import { ApiPlansList, ApiPlansEdit, ApiPlansCreate } from '../resources/api-plans';
import { TMATaskCreate, TMATaskEdit, TMATaskListList } from '../resources/tma/tasks';
import { PoolCreate, PoolEdit, PoolList } from '../resources/tma/pool';
import { TMAQuizCreate, TMAQuizEdit, TMAQuizList } from '../resources/tma/quiz';
import { QuizConfigCreate, QuizConfigEdit, QuizConfigListList } from '../resources/tma/quiz-config';

export const resourceRoutes: Record<AppResourceNames, SecuredResourceProps> = {
  coins: {
    name: AppResourceNames.COINS,
    list: CoinList,
    edit: CoinEdit,
    create: CoinCreate,
    options: { allowTo: [UserRole.MANAGER] },
    icon: AttachMoney,
  },
  ico: {
    name: AppResourceNames.ICO,
    list: IcoList,
    edit: CoinEdit,
    create: CoinCreate,
    options: { label: 'ICO', allowTo: [UserRole.MANAGER] },
    icon: Euro,
  },
  'coin-category': {
    name: AppResourceNames.COIN_CATEGORY,
    list: CoinCategoryList,
    edit: CoinCategoryEdit,
    create: CoinCategoryCreate,
    options: { label: 'Coin Categories', allowTo: [UserRole.MANAGER] },
    icon: Label,
  },
  'coin-tag': {
    name: AppResourceNames.COIN_TAG,
    list: CoinTagList,
    edit: CoinTagEdit,
    create: CoinTagCreate,
    options: { label: 'Coin Tags', allowTo: [UserRole.MANAGER] },
    icon: Label,
  },
  'fund-categories': {
    name: AppResourceNames.FUND_CATEGORIES,
    list: FundCategoriesList,
    edit: FundCategoriesEdit,
    create: FundCategoriesCreate,
    options: { label: 'Fund Categories', allowTo: [UserRole.MANAGER] },
    icon: Label,
  },
  funds: {
    name: AppResourceNames.FUNDS,
    list: FundsList,
    edit: FundEdit,
    create: FundCreate,
    options: { allowTo: [UserRole.MANAGER] },
    icon: Label,
  },
  exchanges: {
    name: AppResourceNames.EXCHANGES,
    list: ExchangeList,
    edit: ExchangeEdit,
    create: ExchangeCreate,
    options: { group: MenuGroups.EXCHANGES, label: 'Exchanges', allowTo: [UserRole.MANAGER] },
    icon: AccountBalance,
  },
  'dexscreener-exchanges': {
    name: AppResourceNames.DEXSCREENER_EXCHANGES,
    list: DexscreenerList,
    edit: DexscreenerEdit,
    create: DexscreenerCreate,
    options: { group: MenuGroups.EXCHANGES, label: 'DEX Screener', allowTo: [UserRole.MANAGER] },
    icon: Search,
  },
  'ido-platforms': {
    name: AppResourceNames.IDO_PLATFORMS,
    list: IdoPlatformsList,
    edit: IdoPlatformsEdit,
    create: IdoPlatformCreate,
    options: { label: 'Launchpads', allowTo: [UserRole.MANAGER] },
    icon: AccountBalance,
  },
  'token-platforms': {
    name: AppResourceNames.TOKEN_PLATFORMS,
    list: TokenPlatformsList,
    edit: TokenPlatformEdit,
    create: TokenPlatformCreate,
    options: { label: 'Chains', allowTo: [UserRole.MANAGER] },
    icon: PaymentSharp,
  },
  tickers: {
    name: AppResourceNames.TICKERS,
    list: TickerList,
    edit: TickerEdit,
    options: { allowTo: [UserRole.MANAGER] },
    icon: Toc,
  },
  'seo-templates': {
    name: AppResourceNames.SEO_TEMPLATES,
    list: SeoTemplateList,
    edit: SeoTemplateEdit,
    create: SeoTemplateCreate,
    options: { label: 'SEO Templates', allowTo: [UserRole.SEO] },
    icon: Title,
  },
  links: {
    name: AppResourceNames.LINKS,
    list: LinksList,
    edit: LinksEdit,
    create: LinksCreate,
    options: { allowTo: [UserRole.MANAGER] },
    icon: Link,
  },
  'coin-mappings': {
    name: AppResourceNames.COIN_MAPPINGS,
    list: CoinMappingList,
    edit: CoinMappingEdit,
    create: CoinMappingCreate,
    options: { label: 'Coin Mapping', allowTo: [UserRole.MANAGER] },
    icon: Share,
  },
  users: {
    name: AppResourceNames.USERS,
    list: UserList,
    edit: UserEdit,
    options: { group: MenuGroups.USERS, label: 'Users list', allowTo: [UserRole.OBSERVER] },
    icon: People,
  },
  managers: {
    name: AppResourceNames.MANAGERS,
    list: ManagerStatistics,
    options: {
      group: MenuGroups.USERS,
      label: 'Employees',
      allowTo: [UserRole.SUPER_MANAGER, UserRole.OBSERVER],
    },
    icon: PeopleOutlineRounded,
  },
  'admin-log': {
    name: AppResourceNames.ADMIN_LOG,
    list: AdminLogList,
    show: AdminLogShow,
    options: { label: 'Admin Log' },
    icon: Toc,
  },
  countries: {
    name: AppResourceNames.COUNTRIES,
    options: { allowTo: [UserRole.MANAGER] },
  },
  'coingecko-coins': {
    name: AppResourceNames.COINGECKO_COINS,
    options: { allowTo: [UserRole.MANAGER] },
  },
  'coinmarketcap-coins': {
    name: AppResourceNames.COINMARKETCAP_COINS,
    list: CmcCoinList,
    show: CmcCoinShow,
    options: { label: 'CoinMarketCap Coins', allowTo: [UserRole.MANAGER] },
  },
  'image-hub': {
    name: AppResourceNames.IMAGE_HUB,
    list: ImageHubList,
    edit: ImageHubEdit,
    create: ImageHubCreate,
    options: { label: 'Image Hub', allowTo: [UserRole.MANAGER, UserRole.ACCOUNT_MANAGER, UserRole.AUTHOR] },
  },
  'exchange-features': {
    name: AppResourceNames.EXCHANGE_FEATURES,
    options: { allowTo: [UserRole.MANAGER] },
  },
  banners: {
    name: AppResourceNames.BANNERS,
    list: BannerList,
    edit: BannerEdit,
    create: BannerCreate,
    options: { label: 'Banners', allowTo: [UserRole.MANAGER, UserRole.ACCOUNT_MANAGER] },
    icon: ViewCarousel,
  },
  'mobile-app-stories': {
    name: AppResourceNames.MOBILE_APP_STORIES,
    list: MobileStoriesList,
    edit: MobileStoriesEdit,
    create: MobileStoriesCreate,
    options: {
      label: 'App Stories',
      group: MenuGroups.MOBILE_APP,
      allowTo: [UserRole.ADMIN, UserRole.SUPER_MANAGER, UserRole.OBSERVER],
    },
    icon: PhoneAndroid,
  },
  'mobile-app-localization': {
    name: AppResourceNames.MOBILE_LOCALIZATION,
    list: MobileLocalizationList,
    edit: MobileLocalizationEdit,
    options: {
      label: 'App Localization',
      group: MenuGroups.MOBILE_APP,
      allowTo: [UserRole.MANAGER, UserRole.ACCOUNT_MANAGER, UserRole.OBSERVER],
    },
    icon: Translate,
  },
  'mobile-app-download': {
    name: AppResourceNames.MOBILE_APP_DOWNLOAD,
    list: MobileDownload,
    options: {
      label: 'App Download',
      group: MenuGroups.MOBILE_APP,
      allowTo: [UserRole.MANAGER, UserRole.OBSERVER],
    },
    icon: Downloading,
  },
  'write-offs': {
    name: AppResourceNames.WRITE_OFFS,
    list: WriteOffsList,
    edit: WriteOffEdit,
    create: WriteOffCreate,
    options: {
      group: MenuGroups.GAMIFICATION,
      label: 'Rewards',
      allowTo: [UserRole.MANAGER, UserRole.ACCOUNT_MANAGER, UserRole.OBSERVER, UserRole.SUPER_MANAGER],
    },
  },
  tasks: {
    name: AppResourceNames.TASKS,
    list: TasksList,
    edit: TasksEdit,
    create: TasksCreate,
    options: {
      group: MenuGroups.GAMIFICATION,
      allowTo: [UserRole.MANAGER, UserRole.ACCOUNT_MANAGER, UserRole.OBSERVER, UserRole.SUPER_MANAGER],
    },
  },
  'on-chain-task': {
    name: AppResourceNames.ON_CHAIN_TASK,
    list: OnChainTasksList,
    edit: OnChainTasksEdit,
    create: OnChainTasksCreate,
    options: {
      group: MenuGroups.GAMIFICATION,
      label: 'On-Chain Tasks',
      allowTo: [UserRole.SUPER_MANAGER, UserRole.ADMIN],
    },
    icon: Link,
  },
  missions: {
    name: AppResourceNames.MISSIONS,
    list: MissionsList,
    edit: MissionEdit,
    create: MissionCreate,
    options: {
      group: MenuGroups.GAMIFICATION,
      allowTo: [UserRole.MANAGER, UserRole.ACCOUNT_MANAGER, UserRole.OBSERVER, UserRole.SUPER_MANAGER],
    },
  },
  'gamification-dashboard': {
    name: AppResourceNames.GAMIFICATION_DASHBOARD,
    list: GamificationDashboardList,
    options: {
      group: MenuGroups.GAMIFICATION,
      label: 'Dashboard',
      allowTo: [UserRole.MANAGER, UserRole.ACCOUNT_MANAGER, UserRole.OBSERVER, UserRole.SUPER_MANAGER],
    },
    icon: TableChart,
  },
  'news-source': {
    name: AppResourceNames.NEWS_SOURCE,
    list: NewsSourceList,
    edit: NewsSourceEdit,
    create: NewsSourceCreate,
    options: {
      group: MenuGroups.CONTENT_CENTER,
      label: 'News Sources',
      allowTo: [UserRole.ADMIN, UserRole.OBSERVER, UserRole.ACCOUNT_MANAGER, UserRole.SUPER_MANAGER, UserRole.AUTHOR],
    },
  },
  'news-tag': {
    name: AppResourceNames.NEWS_TAG,
    list: NewsCategoryList,
    edit: NewsCategoryEdit,
    create: NewsCategoryCreate,
    options: {
      group: MenuGroups.CONTENT_CENTER,
      label: 'News Tags',
      allowTo: [UserRole.ADMIN, UserRole.OBSERVER, UserRole.ACCOUNT_MANAGER, UserRole.SUPER_MANAGER, UserRole.AUTHOR],
    },
  },
  'news-category': {
    name: AppResourceNames.NEWS_CATEGORY,
    options: { label: 'News Category', allowTo: [UserRole.MANAGER] },
  },
  battle: {
    name: AppResourceNames.BATTLE,
    list: BattleList,
    edit: BattleEdit,
    create: BattleCreate,
    options: { label: 'Portfolio Battle', allowTo: [UserRole.ADMIN] },
    icon: SportsMma,
  },
  'coin-trending-settings': {
    name: AppResourceNames.COIN_TRENDING_SETTINGS,
    list: CoinTrendingSettingsList,
    edit: CoinTrendingSettingsEdit,
    create: CoinTrendingSettingsCreate,
    options: {
      group: MenuGroups.TRENDING_PROJECTS,
      label: 'Trending Coins',
      allowTo: [UserRole.ADMIN, UserRole.OBSERVER],
    },
    icon: TrendingUp,
  },
  'sponsored-token-sale': {
    name: AppResourceNames.SPONSORED_TOKEN_SALE,
    list: SponsoredTokenSaleList,
    edit: SponsoredTokenSaleEdit,
    create: SponsoredTokenSaleFormCreate,
    options: {
      group: MenuGroups.TRENDING_PROJECTS,
      label: 'Token Sales',
      allowTo: [UserRole.ADMIN, UserRole.OBSERVER],
    },
    icon: AttachMoney,
  },
  'sponsored-funding-round': {
    name: AppResourceNames.SPONSORED_FUNDING_ROUND,
    list: SponsoredFundingRoundList,
    edit: SponsoredFundingRoundEdit,
    create: SponsoredFundingRoundCreate,
    options: {
      group: MenuGroups.TRENDING_PROJECTS,
      label: 'Funding Rounds',
      allowTo: [UserRole.ADMIN, UserRole.OBSERVER],
    },
    icon: BusinessRounded,
  },
  news: {
    name: AppResourceNames.SPONSORED_NEWS,
    list: SponsoredNewsList,
    edit: SponsoredNewsEdit,
    create: SponsoredNewsCreate,
    options: {
      group: MenuGroups.CONTENT_CENTER,
      label: 'Sponsored News',
      allowTo: [UserRole.ADMIN, UserRole.OBSERVER, UserRole.ACCOUNT_MANAGER, UserRole.SUPER_MANAGER, UserRole.AUTHOR],
    },
    icon: LibraryBooks,
  },
  'news-plug': {
    name: AppResourceNames.NEWS_LOGO,
    list: NewsLogoList,
    options: {
      label: 'News Logo',
      group: MenuGroups.CONTENT_CENTER,
      allowTo: [UserRole.ADMIN, UserRole.OBSERVER, UserRole.ACCOUNT_MANAGER, UserRole.SUPER_MANAGER, UserRole.AUTHOR],
    },
  },
  'sponsored-funds': {
    name: AppResourceNames.SPONSORED_FUNDS,
    list: SponsoredFundList,
    edit: SponsoredFundEdit,
    create: SponsoredFundCreate,
    options: {
      group: MenuGroups.TRENDING_PROJECTS,
      label: 'Trending Funds',
      allowTo: [UserRole.ADMIN, UserRole.OBSERVER],
    },
    icon: MoneyRounded,
  },
  'youtube-videos': {
    name: AppResourceNames.YOUTUBE_VIDEOS,
    list: YoutubeVideosList,
    edit: YoutubeVideosEdit,
    create: YoutubeVideosCreate,
    options: {
      group: MenuGroups.CONTENT_CENTER,
      label: 'Media',
      allowTo: [UserRole.ADMIN, UserRole.OBSERVER, UserRole.ACCOUNT_MANAGER, UserRole.SUPER_MANAGER, UserRole.AUTHOR],
    },
    icon: YouTube,
  },
  'articles-authors': {
    name: AppResourceNames.ARTICLE_AUTHORS,
    list: ArticleAuthorsList,
    edit: ArticleAuthorsEdit,
    create: ArticleAuthorsCreate,
    options: {
      group: MenuGroups.CONTENT_CENTER,
      label: 'Authors',
      allowTo: [UserRole.ADMIN, UserRole.OBSERVER, UserRole.ACCOUNT_MANAGER, UserRole.SUPER_MANAGER, UserRole.AUTHOR],
    },
    icon: LibraryBooks,
  },
  articles: {
    name: AppResourceNames.ARTICLES,
    list: ArticlesList,
    edit: ArticlesEdit,
    create: ArticlesCreate,
    options: {
      group: MenuGroups.CONTENT_CENTER,
      label: 'Articles',
      allowTo: [UserRole.ADMIN, UserRole.OBSERVER, UserRole.ACCOUNT_MANAGER, UserRole.SUPER_MANAGER, UserRole.AUTHOR],
    },
    icon: LibraryBooks,
  },
  'content-tags': {
    name: AppResourceNames.CONTENT_TAGS,
    list: ArticleTagsList,
    edit: ArticleTagsEdit,
    create: ArticleTagsCreate,
    options: {
      group: MenuGroups.CONTENT_CENTER,
      label: 'Content Tags',
      allowTo: [UserRole.ADMIN, UserRole.OBSERVER, UserRole.ACCOUNT_MANAGER, UserRole.SUPER_MANAGER, UserRole.AUTHOR],
    },
    icon: LibraryBooks,
  },
  'articles-categories': {
    name: AppResourceNames.ARTICLE_CATEGORIES,
    list: CategoriesList,
    edit: CategoriesEdit,
    create: CategoriesCreate,
    options: {
      group: MenuGroups.CONTENT_CENTER,
      label: 'Categories',
      allowTo: [UserRole.ADMIN, UserRole.OBSERVER, UserRole.ACCOUNT_MANAGER, UserRole.SUPER_MANAGER, UserRole.AUTHOR],
    },
    icon: LibraryBooks,
  },
  'articles-subcategories': {
    name: AppResourceNames.ARTICLE_SUBCATEGORIES,
    list: SubcategoriesList,
    edit: SubcategoriesEdit,
    create: SubcategoriesCreate,
    options: {
      group: MenuGroups.CONTENT_CENTER,
      label: 'Subcategories',
      allowTo: [UserRole.ADMIN, UserRole.OBSERVER, UserRole.ACCOUNT_MANAGER, UserRole.SUPER_MANAGER, UserRole.AUTHOR],
    },
    icon: LibraryBooks,
  },
  persons: {
    name: AppResourceNames.PESONS,
    list: PersonsList,
    edit: PersonsEdit,
    create: PersonsCreate,
    options: {
      group: MenuGroups.PERSONS,
      label: 'Persons',
      allowTo: [UserRole.MANAGER, UserRole.SUPER_MANAGER, UserRole.ADMIN, UserRole.DEVELOPER, UserRole.OBSERVER],
    },
  },
  'persons-jobs': {
    name: AppResourceNames.PESONS_JOBS,
    list: PersonJobsList,
    edit: PersonJobsEdit,
    create: PersonJobsCreate,
    options: {
      group: MenuGroups.PERSONS,
      label: 'Jobs',
      allowTo: [UserRole.MANAGER, UserRole.SUPER_MANAGER, UserRole.ADMIN, UserRole.DEVELOPER, UserRole.OBSERVER],
    },
  },
  'persons-categories': {
    name: AppResourceNames.PERSONS_CATEGORIES,
    list: PersonCategoriesList,
    edit: PersonCategoriesEdit,
    create: PersonCategoriesCreate,
    options: {
      group: MenuGroups.PERSONS,
      label: 'Categories',
      allowTo: [UserRole.MANAGER, UserRole.SUPER_MANAGER, UserRole.ADMIN, UserRole.DEVELOPER, UserRole.OBSERVER],
    },
  },
  'drop-hunting-activities': {
    name: AppResourceNames.DROP_HUNTING_ACTIVITIES,
    list: DropHuntingActivitiesList,
    edit: DropHuntingActivitiesEdit,
    create: DropHuntingActivitiesCreate,
    options: {
      group: MenuGroups.DROP_HUNTING,
      label: 'Activities',
      allowTo: [UserRole.MANAGER, UserRole.SUPER_MANAGER, UserRole.ADMIN, UserRole.DEVELOPER, UserRole.OBSERVER],
    },
    icon: AddReaction,
  },
  'drop-hunting-task-types': {
    name: AppResourceNames.DROP_HUNTING_TASK_TYPES,
    list: DropHuntingActivityTypesList,
    edit: DropHuntingActivityTypesEdit,
    create: DropHuntingActivityTypesCreate,
    options: {
      group: MenuGroups.DROP_HUNTING,
      label: 'Activity Type',
      allowTo: [UserRole.MANAGER, UserRole.SUPER_MANAGER, UserRole.ADMIN, UserRole.DEVELOPER, UserRole.OBSERVER],
    },
    icon: Task,
  },
  'drop-hunting-rewards': {
    name: AppResourceNames.DROP_HUNTING_REWARDS,
    list: DropHuntingRewardsList,
    edit: DropHuntingRewardsEdit,
    create: DropHuntingRewardsCreate,
    options: {
      group: MenuGroups.DROP_HUNTING,
      label: 'Reward Type',
      allowTo: [UserRole.MANAGER, UserRole.SUPER_MANAGER, UserRole.ADMIN, UserRole.DEVELOPER, UserRole.OBSERVER],
    },
    icon: EmojiEvents,
  },
  ecosystems: {
    name: AppResourceNames.ECOSYSTEMS,
    list: EcosystemsList,
    edit: EcosystemsEdit,
    create: EcosystemsCreate,
    options: {
      group: MenuGroups.ECOSYSTEMS,
      label: 'Ecosystems',
      allowTo: [UserRole.MANAGER, UserRole.SUPER_MANAGER, UserRole.ADMIN, UserRole.DEVELOPER, UserRole.OBSERVER],
    },
    icon: AccountTree,
  },
  'ecosystem-tags': {
    name: AppResourceNames.ECOSYSTEM_TAGS,
    list: EcosystemTagsList,
    edit: EcosystemTagsEdit,
    create: EcosystemTagsCreate,
    options: {
      group: MenuGroups.ECOSYSTEMS,
      label: 'Ecosystem tags',
      allowTo: [UserRole.MANAGER, UserRole.SUPER_MANAGER, UserRole.ADMIN, UserRole.DEVELOPER, UserRole.OBSERVER],
    },
  },
  'li-fi-dashboard': {
    name: AppResourceNames.LI_FI_DASHBOARD,
    list: LiFiDashboard,
    options: {
      label: 'li-fi-dashboard',
      allowTo: [UserRole.ADMIN, UserRole.DEVELOPER],
    },
    icon: Wallet,
  },
  'company-contacts': {
    name: AppResourceNames.PARTNERSHIP_CONTACTS,
    list: PartnershipContactsList,
    edit: PartnershipContactEdit,
    create: PartnershipContactCreate,
    options: {
      label: 'Partnership Contacts',
      allowTo: [UserRole.ADMIN],
    },
    icon: ContactSupport,
  },
  'api-tariffs': {
    name: AppResourceNames.API_TARIFFS,
    list: ApiPlansList,
    edit: ApiPlansEdit,
    create: ApiPlansCreate,
    options: {
      label: 'API Plans',
      allowTo: [UserRole.ADMIN],
    },
    icon: Public,
  },
  'tma/task': {
    name: AppResourceNames.TMA_TASKS,
    list: TMATaskListList,
    edit: TMATaskEdit,
    create: TMATaskCreate,
    options: {
      group: MenuGroups.TMA,
      label: 'Tasks',
      allowTo: [UserRole.ADMIN],
    },
    icon: FormatListBulleted,
  },
  'tma/pool': {
    name: AppResourceNames.TMA_POOL,
    list: PoolList,
    edit: PoolEdit,
    create: PoolCreate,
    options: {
      group: MenuGroups.TMA,
      label: 'Pool',
      allowTo: [UserRole.ADMIN],
    },
    icon: EmojiEvents,
  },
  'tma/quiz': {
    name: AppResourceNames.TMA_QUIZ,
    list: TMAQuizList,
    edit: TMAQuizEdit,
    create: TMAQuizCreate,
    options: {
      group: MenuGroups.TMA,
      label: 'Quiz',
      allowTo: [UserRole.ADMIN],
    },
    icon: EmojiEvents,
  },
  'tma/quiz/configuration': {
    name: AppResourceNames.TMA_QUIZ_CONFIG,
    list: QuizConfigListList,
    edit: QuizConfigEdit,
    create: QuizConfigCreate,
    options: {
      group: MenuGroups.TMA,
      label: 'Quiz Config',
      allowTo: [UserRole.ADMIN],
    },
    icon: PermDataSetting,
  },
};
