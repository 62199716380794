import { TextInput } from 'ra-ui-materialui';
import { maxLength, required } from 'react-admin';
import { getRole } from '../../utils/get-role';
import { UserRole } from '../../variables/user-role';
import { ImageWithPreview } from './components';
import { ReferenceArrayInput, AutocompleteArrayInput } from 'react-admin';
import { SortingDirection } from '../../variables';
import { Box, Typography } from '@mui/material';

export const WalletBanner = (props) => {
  const isAdmin = getRole() === UserRole.ADMIN;

  return (
    <>
      <ReferenceArrayInput
        source="coinIds"
        reference="coins"
        perPage={20}
        sort={{ field: 'id', order: SortingDirection.ASC }}
      >
        <AutocompleteArrayInput optionText="fullName" label="Coins" fullWidth={true} />
      </ReferenceArrayInput>

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <div>
          <ReferenceArrayInput
            source="tokenPlatformsIds"
            reference="token-platforms"
            perPage={20}
            sort={{ field: 'id', order: SortingDirection.ASC }}
          >
            <AutocompleteArrayInput optionText="name" label="Blockchains" fullWidth={true} />
          </ReferenceArrayInput>
          <ReferenceArrayInput
            source="coinTagsIds"
            reference="coin-tag"
            perPage={20}
            sort={{ field: 'id', order: SortingDirection.ASC }}
          >
            <AutocompleteArrayInput optionText="name" label="Tags" fullWidth={true} />
          </ReferenceArrayInput>
        </div>
        <Typography sx={{ maxWidth: 400 }}>
          If specified banner will be displayed only for tokens with selected tags or blockchains
        </Typography>
      </Box>

      <TextInput source="title" fullWidth={true} validate={[required(), maxLength(30)]} />
      <TextInput source="url" label="Link" helperText="URL with https://" fullWidth={true} validate={[required()]} />
      <TextInput source="refUrl" label="Ref Link" helperText="URL with https://" fullWidth={true} disabled={!isAdmin} />
      <ImageWithPreview />
    </>
  );
};
