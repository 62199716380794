import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  TextInput,
  TopToolbar,
  ListButton,
  RefreshButton,
  FunctionField,
  BooleanField,
  DateField,
  ArrayInput,
  FormDataConsumer,
  BooleanInput,
  Create,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  FormTab,
  TabbedForm,
  required,
  useRecordContext,
} from 'react-admin';
import { NumberInput } from '../../inputs/number-input';
import { SortingDirection } from '../../variables';
import { DefaultToolBar } from '../../toolbars/default-toolbar';
import { FormType } from '../../variables';
import { RichTextInput } from 'ra-input-rich-text';
import { getRole } from '../../utils/get-role';
import { UserRole } from '../../variables/user-role';
import { AdminCreateBtn } from '../../buttons/create-btn';
import { AdminDeleteButton } from '../../buttons/delete-btn';
import { RichTextInputCustomToolbar } from '../../toolbars/rich-text-input-toolbar';
import { ArrayInputIterator } from '../../inputs/array-input-iterator';

export const getEnglishTitle = (record) => {
  if (!record) {
    return null;
  }

  const englishTranslation = record.translations.find(({ language }) => language === 'en');
  return englishTranslation?.title || record.translations.find(({ language }) => language === 'ru')?.title;
};

const EditActions = () => {
  return (
    <TopToolbar>
      <AdminCreateBtn />
      <ListButton />
      <RefreshButton />
    </TopToolbar>
  );
};

const MissionTitle = () => {
  const record = useRecordContext();
  return <span>Mission '{getEnglishTitle(record)}'</span>;
};

const Form = (props) => {
  return (
    <TabbedForm {...props} redirect={(basePath) => basePath} toolbar={<DefaultToolBar />}>
      <FormTab label="summary">
        <BooleanInput source="isActive" label="Active" />
        <BooleanInput source="isReusable" label="Reusable" />
        <BooleanInput source="shouldBeShown" />
        <BooleanInput source="hasSpecificCheck" helperText="Задания, которые нельзя сделать без программистов" />
        <h5>Select Task (only one):</h5>
        <ReferenceArrayInput
          source="tasksIds"
          reference="tasks"
          perPage={300}
          sort={{ field: 'name_translations.en', order: SortingDirection.ASC }}
        >
          <AutocompleteArrayInput optionText="name_translations.en" fullWidth />
        </ReferenceArrayInput>
        <NumberInput source="priority" helperText="The higher the value, the lower the position in the list" />
        <NumberInput source="reward" required />

        <FormDataConsumer>
          {({ formData }) => (
            <ArrayInput
              source="translations"
              defaultValue={props.type === FormType.CREATE ? [{ language: 'en' }, { language: 'ru' }] : undefined}
            >
              <ArrayInputIterator
                disableAdd
                disableRemove
                disableReordering
                getItemLabel={(index) => formData.translations[index]?.language}
              >
                <TextInput source="title" label="Title" required />
                <RichTextInput
                  source="description"
                  label="Description"
                  toolbar={<RichTextInputCustomToolbar />}
                  validate={required()}
                  fullWidth={true}
                />
              </ArrayInputIterator>
            </ArrayInput>
          )}
        </FormDataConsumer>
      </FormTab>
    </TabbedForm>
  );
};

export const MissionCreate = (props) => (
  <Create {...props}>
    <Form {...props} type={FormType.CREATE} />
  </Create>
);

export const MissionEdit = (props) => (
  <Edit actions={<EditActions />} title={<MissionTitle />} {...props}>
    <Form {...props} type={FormType.EDIT} />
  </Edit>
);

export const MissionsList = (props) => {
  const isDisabled = getRole() === UserRole.ACCOUNT_MANAGER;

  return (
    <List {...props} sort={{ field: 'createdAt', order: SortingDirection.ASC }} perPage={50}>
      <Datagrid bulkActionButtons={false}>
        <FunctionField label="Name" render={(record) => <span>{getEnglishTitle(record)}</span>} />
        <DateField source="createdAt" showTime locales="ru" />
        <BooleanField source="isActive" />
        <TextField source="reward" />
        <TextField source="usersCount" label="Users" />
        <EditButton disabled={isDisabled} />
        <AdminDeleteButton disabled={isDisabled} />
      </Datagrid>
    </List>
  );
};
