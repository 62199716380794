import React from 'react';
import { Create, Edit, FunctionField, ImageField, ImageInput, SimpleForm, TextInput, required } from 'react-admin';
import { FormType, imageInputAccept } from '../../variables';

const Form = (props) => (
  <SimpleForm {...props}>
    <TextInput
      label="Image Name"
      source="image_name"
      helperText="Опозновательное имя изображение. Должно быть уникальным. Максимум 128 символов"
      required
    />
    <TextInput
      label="Description"
      source="description"
      helperText="Описание картинки, любые метаданные. Максимум 256 символов"
    />

    <FunctionField
      label="Uploaded image"
      render={(record) => record.image_url && <img src={record.image_url} alt="Logo" style={{ maxHeight: '400px' }} />}
    />

    <ImageInput source="image" label="Image" accept={imageInputAccept} validate={props.type === 'edit' ? [] : required()}>
      <ImageField title="Image" />
    </ImageInput>
  </SimpleForm>
);

export const ImageHubEdit = (props) => (
  <Edit {...props}>
    <Form {...props} type={FormType.EDIT} />
  </Edit>
);

export const ImageHubCreate = (props) => (
  <Create {...props}>
    <Form {...props} type={FormType.CREATE} />
  </Create>
);
