import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DashboardTable } from '../table';
import { ApiStats, Periods, ProjectStats } from './types';
import { DashboardCards, titles } from './cards';
import { CardContent } from '@mui/material';
import { UserRole } from '../../variables/user-role';
import { getRole } from '../../utils/get-role';
import { checkUserRoleAvailability } from '../../utils/check-user-role-availability';
import { getApiPlans, getDashboard } from '../../api-service';
import { LoaderWrapper } from '../loader-wrapper';

const StyledCardContent = styled(CardContent)`
  position: relative;
  min-height: 500px;
  display: grid;
  grid-gap: 30px;
`;

const FlexContainer = styled.div`
  display: flex;
  gap: 15px;
`;

const StyledSubtitle = styled.p`
  margin: 0 0 10px;
`;

const allowTo = [UserRole.ADMIN, UserRole.OBSERVER];

export const ProjectStatistics = () => {
  const userRole = getRole();

  const [projectStats, setProjectStats] = useState<ProjectStats>();
  const [apiStats, setApiStats] = useState<ApiStats>();
  const isAllowed = userRole && checkUserRoleAvailability(userRole, allowTo);

  useEffect(() => {
    if (!isAllowed) {
      return;
    }

    getDashboard().then(setProjectStats);
    getApiPlans().then(setApiStats);
  }, [isAllowed]);

  if (!isAllowed) {
    return null;
  }

  const apiTariffs = apiStats?.tariffs.sort(
    ({ pricingAnnually: priceA }, { pricingAnnually: priceB }) => priceA - priceB
  );

  return (
    <StyledCardContent>
      <LoaderWrapper>{projectStats && <DashboardCards data={projectStats} />}</LoaderWrapper>

      <LoaderWrapper>
        {apiStats && apiTariffs && (
          <div>
            <FlexContainer>
              <StyledSubtitle>Free API users: {apiStats.freeCount}</StyledSubtitle>
              <StyledSubtitle>Paid API users: {apiStats.paidCount}</StyledSubtitle>
            </FlexContainer>
            <DashboardTable>
              <thead>
                <tr>
                  <th>Plan name</th>
                  {Object.values(Periods).map((period, index) => (
                    <th key={'table-head_' + index}>{titles[period]}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {apiTariffs.map(({ histCount, name }) => (
                  <tr key={'table-row_' + name}>
                    <td>{name}</td>

                    {Object.values(Periods).map((period, index) => (
                      <td key={`${period}_${index}`}>{histCount[period]}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </DashboardTable>
          </div>
        )}
      </LoaderWrapper>

      <LoaderWrapper>
        {projectStats && (
          <div>
            <DashboardTable>
              <thead>
                <tr>
                  <th></th>
                  {Object.values(titles).map((title, index) => (
                    <th key={'table-head_' + index}>{title}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {Object.entries(projectStats).map(([title, periods]) => (
                  <tr key={'table-row_' + title}>
                    <td>{title}</td>

                    {Object.values(periods).map((period, index) => (
                      <td key={`${period}_${index}`}>{period}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </DashboardTable>
          </div>
        )}
      </LoaderWrapper>
    </StyledCardContent>
  );
};
