import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  TextInput,
  Create,
  TopToolbar,
  CreateButton,
  ListButton,
  RefreshButton,
  Filter,
  SimpleForm,
  ImageField,
  ImageInput,
  FunctionField,
  BooleanInput,
  BulkDeleteWithUndoButton,
  useRecordContext,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from 'react-admin';
import { getEnvs } from '../env-lib';
import { imageInputAccept, SortingDirection } from '../variables';
import { AdminDeleteButton } from '../buttons/delete-btn';
import { DefaultToolBar } from '../toolbars/default-toolbar';
import { getIsDisabledByRole } from '../utils/get-disabled-by-role';
import { TrimOnBlurInput } from '../inputs/trim-on-blur-input';
import { helperTexts } from '../variables/helper-texts';

const imagesBasePath = getEnvs().REACT_APP_IMAGES_BASE_URL + '/coins/';

const Form = (
  <SimpleForm toolbar={<DefaultToolBar />}>
    <TrimOnBlurInput source="name" />
    <TextInput source="key" label="Short name (Website slug)" helperText={helperTexts.keyInputValidation} />
    <div style={{ maxWidth: '450px' }}>
      <ReferenceArrayInput
        source="tvlBlockchainIds"
        reference="tvl-blockchains"
        sort={{ field: 'name', order: SortingDirection.ASC }}
        isRequired={true}
      >
        <AutocompleteArrayInput fullWidth optionText="name" label="Slug DefiLama (TVL)" />
      </ReferenceArrayInput>
    </div>
    <TextInput source="explorerUrl" label="Explorer URL" />
    <TextInput source="transactionsExplorerUrl" label="Transactions Explorer URL" />
    <TextInput source="network" label="Internal Network Symbol" />
    <BooleanInput source="enabled" />
    <FunctionField
      label="Image"
      render={(record) =>
        record?.imageName ? (
          <img src={imagesBasePath + record.imageName} alt="image" style={{ maxHeight: '170px' }} />
        ) : null
      }
    />
    <ImageInput source="image" label="Upload image" accept={imageInputAccept}>
      <ImageField source="src" title="Image" />
    </ImageInput>
    <p>Maximum size is 1MB</p>
  </SimpleForm>
);

export const TokenPlatformCreate = (props) => <Create {...props}>{Form}</Create>;

const EditActions = () => {
  return (
    <TopToolbar>
      <CreateButton />
      <ListButton />
      <RefreshButton />
    </TopToolbar>
  );
};

const TokenPlatformTitle = () => {
  const record = useRecordContext();
  return <span>Token Platform '{record?.name}'</span>;
};

export const TokenPlatformEdit = (props) => (
  <Edit actions={<EditActions />} title={<TokenPlatformTitle />} {...props}>
    {Form}
  </Edit>
);

const TokenPlatformsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Name" source="q" alwaysOn />
  </Filter>
);

export const TokenPlatformsList = (props) => (
  <List {...props} sort={{ field: 'id', order: SortingDirection.ASC }} perPage={50} filters={<TokenPlatformsFilter />}>
    <Datagrid bulkActionButtons={<BulkDeleteWithUndoButton {...props} disabled={getIsDisabledByRole()} />}>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="explorerUrl" label="Explorer URL" />
      <EditButton />
      <AdminDeleteButton />
    </Datagrid>
  </List>
);
