import { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { CardContent, CardHeader, CircularProgress, TextField } from '@mui/material';
import { addDays, format } from 'date-fns';
import { UserRole } from '../../variables/user-role';
import { DashboardTable } from '../../dashboard/table';
import { getManagerStatistics } from '../../api-service';

const StyledCardContent = styled(CardContent)`
  position: relative;
  min-height: 350px;
  overflow-x: auto;
  max-width: 70vw;
`;

const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: -100px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.3);
`;

const StyledTbody = styled.tbody`
  border: 3px solid;
`;

const currentDate = format(new Date(), 'yyyy-MM-dd');
const startDate = format(addDays(new Date(), -30), 'yyyy-MM-dd');

type StatisticsData = {
  fullName: string;
  email: string;
  role: UserRole;
  createCoin: number;
  updateCoin: number;
  lastActionAt: string | null;
  updateTicker: number;
  createFund: number;
  updateFund: number;
  updateExchange: number;
  createIdoPlatform: number;
  updateIdoPlatform: number;
  createTokenPlatform: number;
  updateTokenPlatform: number;
};

const initialDate = { from: startDate, to: currentDate };

export const ManagerStatistics = () => {
  const [stats, setStats] = useState<StatisticsData[] | null>();
  const [fetching, setFetching] = useState(true);
  const [date, setDate] = useState(initialDate);
  const zeroDate = '2017-01-01';

  const fetchData = () => {
    setFetching(true);
    getManagerStatistics(date).then((data) => {
      setStats(data.sort((a, b) => b.createCoin + b.updateCoin - (a.createCoin + a.updateCoin)));
      setFetching(false);
    });
  };

  useEffect(() => {
    setFetching(true);

    getManagerStatistics(initialDate).then((data) => {
      setStats(data.sort((a, b) => b.createCoin + b.updateCoin - (a.createCoin + a.updateCoin)));
      setFetching(false);
    });
  }, []);

  const onChangeDate = (type: 'from' | 'to') => (e: ChangeEvent<HTMLInputElement>) => {
    const dateValue = e.target.value;

    if (type === 'from') {
      setDate(({ to }) => ({ from: dateValue || zeroDate, to }));
      return;
    }

    const from = new Date(dateValue).getTime() < new Date(date.from).getTime() ? dateValue : date.from;
    setDate({ from, to: dateValue || currentDate });
  };

  return (
    <>
      <CardHeader title="Employee statistics" style={{ marginTop: 10 }} />
      <StyledCardContent>
        <TextField
          type="date"
          onKeyDown={(e) => e.preventDefault()}
          onChange={onChangeDate('from')}
          onBlur={fetchData}
          style={{ marginBottom: 10 }}
          value={date.from}
          InputProps={{ inputProps: { max: date.to } }}
        />{' '}
        <TextField
          type="date"
          onKeyDown={(e) => e.preventDefault()}
          onChange={onChangeDate('to')}
          onBlur={fetchData}
          style={{ marginBottom: 10 }}
          value={date.to}
          InputProps={{ inputProps: { max: currentDate } }}
        />
        {fetching && (
          <LoaderWrapper>
            <CircularProgress size={100} />
          </LoaderWrapper>
        )}
        {stats?.length ? (
          <DashboardTable>
            <thead>
              <tr>
                <th>Full name</th>
                <th>Email</th>
                <th>Role</th>
                <th>Create Coins</th>
                <th>Updated Coins</th>
                <th>Last Action At</th>
                <th>Updated Tickers</th>
                <th>New Funds Create</th>
                <th>Funds updated</th>
                <th>Exchanges Updated</th>
                <th>New launchpads</th>
                <th>Launchpads updated</th>
                <th>New blockchains</th>
                <th>Blockchains updated</th>
              </tr>
            </thead>
            <StyledTbody>
              {stats.map(
                ({
                  fullName,
                  email,
                  role,
                  createCoin,
                  updateCoin,
                  lastActionAt,
                  updateTicker,
                  createFund,
                  updateFund,
                  updateExchange,
                  createIdoPlatform,
                  updateIdoPlatform,
                  createTokenPlatform,
                  updateTokenPlatform,
                }) => (
                  <tr key={email}>
                    <td>{fullName}</td>
                    <td>{email}</td>
                    <td>{role}</td>
                    <td>{createCoin}</td>
                    <td>{updateCoin}</td>
                    <td>{lastActionAt ? format(new Date(lastActionAt), 'dd-MM-yyyy, HH:mm') : 'N/A'}</td>
                    <td>{updateTicker}</td>
                    <td>{createFund}</td>
                    <td>{updateFund}</td>
                    <td>{updateExchange}</td>
                    <td>{createIdoPlatform}</td>
                    <td>{updateIdoPlatform}</td>
                    <td>{createTokenPlatform}</td>
                    <td>{updateTokenPlatform}</td>
                  </tr>
                )
              )}
            </StyledTbody>
          </DashboardTable>
        ) : null}
      </StyledCardContent>
    </>
  );
};
