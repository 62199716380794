import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  Create,
  TopToolbar,
  ListButton,
  RefreshButton,
  TranslatableInputs,
  SelectInput,
  TabbedForm,
  FormTab,
  TextInput,
  useRecordContext,
  required,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import { SortingDirection } from '../../variables';
import { useState } from 'react';
import { TaskType, TaskTypesList } from './variables';
import { AdminDeleteButton } from '../../buttons/delete-btn';
import { AdminCreateBtn } from '../../buttons/create-btn';
import { DefaultToolBar } from '../../toolbars/default-toolbar';
import { FormType } from '../../variables';
import { getRole } from '../../utils/get-role';
import { UserRole } from '../../variables/user-role';

const IdFieldByType = ({ type }) => {
  const isDisabled = type === FormType.EDIT;

  switch (type) {
    case TaskType.DISCORD_JOIN_SERVER:
    case TaskType.TELEGRAM_JOIN_CHANNEL:
    case TaskType.TWITTER_FOLLOW_USER:
    case TaskType.TWITTER_LIKE_TWEET:
    case TaskType.TWITTER_RETWEET_TWEET:
      return <TextInput source="data.link" label="Social Network URL" disabled={isDisabled} validate={required()} />;
    default:
      return null;
  }
};

const Form = (props) => {
  const record = useRecordContext();
  const [selectedType, setSelectedType] = useState(record?.type ?? TaskType.PROFILE_ADD_WALLET);
  const handleTypeChange = (event) => setSelectedType(event.target.value);

  return (
    <TabbedForm {...props} toolbar={<DefaultToolBar />}>
      <FormTab label="summary">
        <SelectInput
          source="type"
          label="Task Type"
          choices={TaskTypesList}
          defaultValue={TaskTypesList[0].id}
          onChange={handleTypeChange}
          validate={required()}
        />
        <IdFieldByType type={selectedType} />
        <TranslatableInputs locales={['en', 'ru']} defaultLocale="en">
          <TextInput source="name_translations" validate={required()} />
          <RichTextInput source="description_translations" fullWidth={true} />
        </TranslatableInputs>
      </FormTab>
    </TabbedForm>
  );
};

export const TasksCreate = (props) => (
  <Create {...props}>
    <Form {...props} type={FormType.CREATE} />
  </Create>
);

const EditActions = () => {
  return (
    <TopToolbar>
      <AdminCreateBtn />
      <ListButton />
      <RefreshButton />
    </TopToolbar>
  );
};

export const TasksEdit = (props) => (
  <Edit actions={<EditActions />} title={<span>Tasks</span>} {...props} mutationMode="pessimistic">
    <Form {...props} type={FormType.EDIT} />
  </Edit>
);

export const TasksList = (props) => {
  const isDisabled = getRole() === UserRole.ACCOUNT_MANAGER;

  return (
    <List
      {...props}
      sort={{ field: 'name_translations.en', order: SortingDirection.DESC }}
      perPage={50}
      exporter={false}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" label="ID" />
        <TextField source="type" label="Type" />
        <TextField source="name_translations.en" label="Name EN" />
        <TextField source="created_at" />
        <TextField source="usersCount" label="Users" />
        <EditButton disabled={isDisabled} />
        <AdminDeleteButton disabled={isDisabled} />
      </Datagrid>
    </List>
  );
};
