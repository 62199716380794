import { FC } from 'react';
import { AutocompleteArrayInput, ReferenceArrayInput, TabbedForm } from 'react-admin';
import { SortingDirection } from '../../variables';

export const LinksTab: FC<any> = (props) => {
  const label = 'links';

  if (props.hidden) {
    return <TabbedForm.Tab label={label} {...props} />;
  }

  return (
    <TabbedForm.Tab label={label} {...props}>
      <ReferenceArrayInput source="link_ids" reference="links" sort={{ field: 'id', order: SortingDirection.DESC }}>
        <AutocompleteArrayInput filterToQuery={(q) => ({ q_value: q })} optionText="value" />
      </ReferenceArrayInput>
    </TabbedForm.Tab>
  );
};
