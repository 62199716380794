import styled from 'styled-components';
import { ArrayInput } from 'react-admin';

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 40px;
  margin-bottom: 10px;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RowContainer = styled.div<{ withBorder?: boolean }>`
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 10px;
  border: ${(p) => p.withBorder && '1px solid lightgreen'};
  & > div {
    margin-top: 0;
  }
`;

export const StyledAllocationForm = styled.form`
  padding: 10px 15px;
  margin: 10px 0;
  border: 1px solid #ecebeb;
  min-width: 765px;
`;

export const InlineRow = styled.div`
  display: flex;
  width: 100% !important;
  & > div {
    margin-right: 1rem;
  }
`;

export const CoinLocalization = styled.div`
  width: 100% !important;
  & .MuiTypography-root {
    display: none;
  }
`;

export const FundingRoundsArrayInput = styled(ArrayInput)`
  && > div > ul > li > section {
    display: grid;
    grid-column-gap: 16px;

    > :nth-child(3) {
      grid-row: 1/9;
      grid-column: 3/4;
      width: fit-content;
    }

    > :nth-child(6) {
      grid-row: 1/2;
      grid-column: 2/3;
      width: fit-content;
    }
    > :nth-child(7) {
      grid-row: 2/3;
      grid-column: 2/3;
      width: fit-content;
    }
    > :nth-child(8) {
      grid-row: 3/4;
      grid-column: 2/3;
      width: fit-content;
    }
    > :nth-child(9) {
      grid-row: 4/5;
      grid-column: 2/3;
      position: relative;
      span {
        position: absolute;
        width: 260px;
      }
    }
    > :nth-child(10) {
      grid-row: 5/11;
      grid-column: 2/3;
      width: fit-content;
    }
  }
`;

export const CrowdsalesArrayInput = styled(ArrayInput)`
  && > div > ul > li > section {
    display: grid;
    grid-column-gap: 16px;

    > :nth-child(12) {
      grid-row: 1/2;
      grid-column: 2/3;
      width: fit-content;
    }
    > :nth-child(13) {
      grid-row: 2/3;
      grid-column: 2/3;
      width: fit-content;
    }
    > :nth-child(14) {
      grid-row: 3/4;
      grid-column: 2/3;
      width: fit-content;
    }
    > :nth-child(15) {
      grid-row: 4/11;
      grid-column: 2/3;
    }
  }
`;

export const Divider = styled.hr`
  width: 100%;
`;
