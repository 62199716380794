export const tradingViewExchanges = [
  { id: 'BINANCE', name: 'BINANCE' },
  { id: 'BYBIT', name: 'BYBIT' },
  { id: 'OKX', name: 'OKX' },
  { id: 'COINBASE', name: 'COINBASE' },
  { id: 'KUCOIN', name: 'KUCOIN' },
  { id: 'HTX', name: 'HTX' },
  { id: 'GATEIO', name: 'GATEIO' },
  { id: 'KRAKEN', name: 'KRAKEN' },
  { id: 'MEXC', name: 'MEXC' },
  { id: 'BITGET', name: 'BITGET' },
  { id: 'WHITEBIT', name: 'WHITEBIT' },
  { id: 'BITMART', name: 'BITMART' },
  { id: 'BINGX', name: 'BINGX' },
  { id: 'GEMINI', name: 'GEMINI' },
  { id: 'POLONIEX', name: 'POLONIEX' },
  { id: 'BITFINEX', name: 'BITFINEX' },
  { id: 'BTSE', name: 'BTSE' },
  { id: 'PHEMEX', name: 'PHEMEX' },
  { id: 'BITRUE', name: 'BITRUE' },
  { id: 'BINANCEUS', name: 'BINANCEUS' },
  { id: 'OKCOIN', name: 'OKCOIN' },
  { id: 'UPBIT', name: 'UPBIT' },
];
